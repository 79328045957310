import * as React from "react";

type Props = {
  widthStyle?: string;
  heightStyle?: string;
};

export const ClientIcon = (props: Props) => (
  <svg
    width={props.widthStyle || "36px"}
    height={props.heightStyle || "37.5px"}
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>client-boxed</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="translate(-584.000000, -285.000000)" fill="#EFB51B">
        <g id="client-boxed" transform="translate(584.000000, 285.346555)">
          <g id="Group-2">
            <rect id="Rectangle" fillOpacity="0.08" x="0" y="0" width="24" height="24" rx="4"></rect>
            <g id="Icons/Animals/Dog/Active" transform="translate(5.500000, 5.500000)" fillRule="nonzero">
              <g id="Icons/Cat/Active">
                <g id="Cat-Inactive">
                  <path
                    d="M6.66666667,8.33333333 C8.89166667,8.33333333 13.3333333,9.45 13.3333333,11.6666667 L13.3333333,11.6666667 L13.3333333,13.3333333 L0,13.3333333 L0,11.6666667 C0,9.45 4.44166667,8.33333333 6.66666667,8.33333333 Z M6.66666667,0 C8.50833333,0 10,1.49166667 10,3.33333333 C10,5.175 8.50833333,6.66666667 6.66666667,6.66666667 C4.825,6.66666667 3.33333333,5.175 3.33333333,3.33333333 C3.33333333,1.49166667 4.825,0 6.66666667,0 Z"
                    id="Combined-Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
