import styled from "styled-components/macro";

export const ImportPatientContainer = styled.div`
  text-align: center;
  height: 100%;
  div {
    padding: 32px;
  }

  a {
    font-weight: bold;
    text-decoration: none;
    color: ${({ theme }) => theme.accentColor};
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;

    text-transform: uppercase;
  }
  .separator::before,
  .separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #e5e5e5;
  }
  .separator::before {
    margin-right: 1em;
  }
  .separator::after {
    margin-left: 1em;
  }
`;

export const CopyDiv = styled.div`
  span {
    font-weight: bold;
  }
`;
