import * as Yup from "yup";

const phoneRegExp = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const getFormSchema = (linkedClientKey: string | undefined | null) => {
  const baseValidationSchema = {
    patientName: Yup.string().required("Required").max(512, "Only use 512 characters"),
    patientSpeciesType: Yup.string().required("Required"),
    patientSpecies: Yup.string().required("Required"),
    patientSex: Yup.string().required("Required").typeError("Required"),
    patientWeight: Yup.number().typeError("Invalid").required("Required").positive("Required"),
    patientBirthdate: Yup.date().required("Required"),
  };

  if (linkedClientKey) {
    return Yup.object().shape(baseValidationSchema);
  }
  return Yup.object().shape({
    ...baseValidationSchema,
    clientGivenName: Yup.string().required("Required").max(64, "Only use 64 characters"),
    clientFamilyName: Yup.string().required("Required").max(64, "Only use 64 characters"),
    clientPhoneNumber: Yup.string().required("Required")
      .matches(phoneRegExp, "Invalid"),
    clientEmailAddress: Yup.string().required("Required")
      .email("Invalid")
      .max(255, "Only use 255 characters"),
  });
};

export default getFormSchema;
