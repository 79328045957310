import commonMessages from "../../../../common/messages";
export default {
  header: "Flagged Issues",
  copy: "To avoid delays, please explain to the pharmacist why you’re prescribing off label.",
  reasonHelpText: "A detailed reason is required to authorize with conflicts",
  expectedLabel: "Expected",
  selectedLabel: "Selected",
  reasonLabel: "Reason",
  reasonLabelWeight: "Reason for overriding the weight",
  reasonLabelSpecies: "Reason for overriding the species",
  reasonLabelDirections: "Reason for overriding the directions",
  reasonLabelWeightAndSpecies: "Reason for overriding the weight and species",
  reasonLabelWeightAndDirections: "Reason for overriding the directions and weight",
  reasonLabelSpeciesAndDirections: "Reason for overriding the directions and species",
  reasonLabelWeightAndSpeciesAndDirections: "Reason for overriding the directions, weight, and species",
  otherEssentialCopyReasonLabel: "Medical Rationale",
  otherEssentialCopyReasonHelpText: "If you selected other reason, please write details to authorize with conflicts.",
  flaggedLabels: {
    species: commonMessages.species,
    weight: commonMessages.weight,
    directionsKeywords: "Directions Keywords",
    clientAge: "Client Age",
    clientId: "Client ID",
    essentialCopy: "Compound Product",
    essentialCopyReason: "Reason for prescribing compound",
    authorizingVetLicenseInvalid: "Veterinarian License doesn't exist or inactive",
    authorizingVetLicenseExpiring: "Veterinarian License Expiring Soon",
    authorizingVetLicenseExpired: "Veterinarian License Expired",
    authorizingVetDeaLicenseInvalid: "DEA license does not exist or is inactive",
    authorizingVetDeaLicenseExpiring: "DEA license is within 30 days of expiring",
    authorizingVetDeaLicenseExpired: "DEA license has expired",
  },
  tooltip: {
    essentialCopy: "Compound product selected is very similar to a commercially available product. For Pharmacy to fulfill this request, please provide a reason for prescribing compound option.",
    authorizingVetLicenseExpiring: "The expiration date on file for this Veterinarian's License is within the next 30 days. Please update the expiration date as soon as the license is renewed.",
    authorizingVetLicenseExpired: "The expiration date on file for this Veterinarian's License has passed. Please update the expiration date in order to authorize prescriptions.",
    authorizingVetDeaLicenseExpiring: "DEA License on file is expiring within 30 days. Please update as soon as possible.",
    authorizingVetDeaLicenseExpired: "DEA License on file is expired.",
    clientHasNoIdentification: "The client has no identification information on file.",
    essentialCopyReasons: {
      DOSE_NOT_ACHIEVABLE: "Please elaborate why the specific dose is not achievable with the commercial product.",
      EASE_OF_ADMINISTRATION: "Please elaborate on the dosing concerns that would prevent the patient from using the commercially available product(s).",
      ADVERSE_REACTION_TO_COMMERCIAL: "Please elaborate on the reaction experienced by the patient while using the commercially available product(s) and what ingredient (if known).",
      PALATABILITY: "Please elaborate on tolerability concerns with the commercially available product(s).",
      POSITIVE_THERAPEUTIC_RESPONSE_TO_COMPOUND: "Please elaborate on the therapeutic response not seen with the commercially available product(s).",
      BACK_ORDER: "Please elaborate on the commercial product that is currently on backorder and why the compound product is needed.",
      PATIENT_CANNOT_SAFELY_PILLED: "Please elaborate why the patient cannot safely be pilled with the approved commercial product.",
    }
  },
  placeholders: {
    essentialCopyReasons: {
      DOSE_NOT_ACHIEVABLE: "Example: Patient requires dose that would require a fraction of the approved tablet, and tablet is not scored to accomplish this fractionated dose.",
      EASE_OF_ADMINISTRATION: "Example: Patient would require too many tablets or too much volume of the approved product.",
      ADVERSE_REACTION_TO_COMMERCIAL: "Example:  Vetmedin tablets are a pork-based artificial beef flavor chewable tablet. Oscar is allergic to pork and therefore can not take the commercial tablets.",
      PALATABILITY: "Example:  The commercial product, Ayradia, contains the base ingredient, Metronidazole, which is known to be much more bitter than Metronidazole (from Benzoate). Pet will take compounded Metronidazole (from Benzoate) suspension but not Metronidazole base (Ayradia).",
      POSITIVE_THERAPEUTIC_RESPONSE_TO_COMPOUND: "Example:  Methimazole tablets did not produce consistent thyroid control for Carrot, requiring multiple dose adjustments. ",
      BACK_ORDER: "Example:  Vetmedin tablets are currently on backorder.   In order to avoid a lapse in treatment, a compounded product is needed.",
      PATIENT_CANNOT_SAFELY_PILLED: "Example:  Buster is very aggressive and will bite owner when trying to give the commercial product. Buster tolerates the compounded preparation.",
    }
  }
};
