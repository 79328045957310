import * as React from "react";

type Props = {
  className?: string;
};
/* tslint:disable:max-line-length */
const CovetrusLogo = (props: Props) => (
  <svg
    viewBox="0 0 385.91 76.41"
    style={{
      minHeight: "auto",
      maxHeight: 36,
      maxWidth: 180,
      height: "auto",
      width: "100%",
    }}
    className={props.className}
  >
    <path
      fill="#021660"
      d="M202.54,304.62a19.29,19.29,0,0,1,19.69-19.54c8.69,0,15.37,4.8,18.29,11.89l-8.84,3.55a9.67,9.67,0,0,0-9.32-6.47,10.51,10.51,0,0,0,.07,21,9.88,9.88,0,0,0,9.32-6.54l9,3.68c-3.2,7-9.46,12-18.43,12A19.36,19.36,0,0,1,202.54,304.62Z"
      transform="translate(-202.54 -266.79)"
    />
    <path
      fill="#021660"
      d="M244.11,304.62a19.86,19.86,0,1,1,19.82,19.55A19.44,19.44,0,0,1,244.11,304.62Zm30.18-.07A10.29,10.29,0,1,0,264,315.06,10.36,10.36,0,0,0,274.29,304.55Z"
      transform="translate(-202.54 -266.79)"
    />
    <path
      fill="#021660"
      d="M283.47,285.84h10.16l9.25,25.32,9.32-25.32h9.94L307.4,323.4h-9.53Z"
      transform="translate(-202.54 -266.79)"
    />
    <path
      fill="#021660"
      d="M322.8,304.69a19.38,19.38,0,0,1,19.68-19.61c11.27,0,19.4,8.14,19.4,19.26V308H332.05c1.25,4.87,5.21,7.93,10.78,7.93a10.25,10.25,0,0,0,9.32-5l7.86,4.59c-3.76,5.29-9.05,8.7-17.32,8.7C330.59,324.17,322.8,315.61,322.8,304.69Zm9.52-4.24h20c-1.25-4.66-4.86-7.23-9.87-7.23A10.45,10.45,0,0,0,332.32,300.45Z"
      transform="translate(-202.54 -266.79)"
    />
    <path
      fill="#021660"
      d="M364.39,285.84h6.95v-8.13l9.46-5.57v13.7h9.88v8.49H380.8v15.44c0,4,1.67,5.35,4.1,5.35a4.9,4.9,0,0,0,3.48-1.39l4.38,7.52a14.88,14.88,0,0,1-9,2.85c-7.86,0-12.45-3.76-12.45-14V294.33h-6.95Z"
      transform="translate(-202.54 -266.79)"
    />
    <path
      fill="#021660"
      d="M399.05,285.84h9.46v5.78c1.81-4.45,5.56-6.47,10.36-6.47a14,14,0,0,1,6.33,1.46l-3.48,9.46a8.74,8.74,0,0,0-5-1.32c-4.93,0-8.13,2.5-8.13,8.83V323.4h-9.53Z"
      transform="translate(-202.54 -266.79)"
    />
    <path
      fill="#021660"
      d="M428.33,308.38V285.84h9.81v21.22c0,5.28,2.5,8.55,7.65,8.55,4.52,0,7.86-3.27,7.86-9.11V285.84l9.73-.06v37.55l-9.59.07v-4.73a13,13,0,0,1-10.85,5.5C433.68,324.17,428.33,318.25,428.33,308.38Z"
      transform="translate(-202.54 -266.79)"
    />
    <path
      fill="#021660"
      d="M469.37,314.85l9.25-3.62c1.66,3.34,4.65,5.08,8.62,5.08s6-1.88,6-4.11c0-2.71-4-3.33-8.83-4.31-6.33-1.32-13.36-3.27-13.36-11.2,0-6.32,6.12-11.82,15.37-11.75,7.93,0,12.66,3,16.14,8l-8.76,3.55a8,8,0,0,0-7.38-4c-3.75,0-5.56,1.53-5.56,3.62,0,2.36,3.06,3,8.56,4.17,6.25,1.25,13.63,3.41,13.63,11.48,0,5.84-5.22,12.45-16.14,12.38C478,324.17,472.36,320.83,469.37,314.85Z"
      transform="translate(-202.54 -266.79)"
    />
    <polygon fill="#021660" points="352.06 38.21 318.21 19.1 318.21 57.31 352.06 38.21" />
    <polygon fill="#27bdbe" points="352.06 38.21 318.21 57.31 352.06 76.41 385.91 57.31 352.06 38.21" />
    <polygon fill="#ed3030" points="352.06 0 318.21 19.1 352.06 38.21 385.91 19.1 352.06 0" />
  </svg>
);

export default CovetrusLogo;
