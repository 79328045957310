export default {
  header: "Link Patient & Client",
  description: (pimsType: string) =>
    `You’ll only have to do this once so we can accurately fulfill this prescription. You can also update patient information in Rx Management from ${
      pimsType || "your PIMS"
    }.`,
  comparisonButton: "import as new",
  comparisonNoMatchText: "Search for a client below to get started.",
  searchHelpText: "Search by client name",
  searchNoMatchTypeText: "clients",
  searchNoMatchButtonText: "import client",
  searchHeaderText: "Rx Management Client Search",
  searchNoResultsText: (pimsType: string) =>
    `Search something above to look for a client record in Rx Management to link with your ${
      pimsType || "PIMS"
    } record.`,
  searchErrorText: "Unable to complete search."
};
