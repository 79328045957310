import React from "react";
import { CheckmarkCircledIcon } from "../../assets/CheckmarkCircledIcon";
import { UserCheckmarkIcon } from "../../assets/UserCheckmarkIcon";
import { CloseButton, SuccessCardWrapper } from "./styles";
import messages from "./messages";
import { Props } from "./types";
import { Button } from "../StyledComponents/Button/Button";

export const RxSuccess = (props: Props) => {
  const { isApproval, isRenewal, isFromDecline, hasProcessedByAnotherUser, isFromRemovePrescription } = props;

  if (hasProcessedByAnotherUser) {
    return <SuccessCardWrapper>
      <UserCheckmarkIcon width={"80"} height={"80"} />
      <h1>{messages.pendingRequestProcessed}</h1>
      <p>{messages.pendingRequestProcessedMessage}</p>
      <CloseButton onClick={props.handleClose} btnStyle="secondary">{messages.close}</CloseButton>
      <Button onClick={props.backToList}>{messages.backToList}</Button>
    </SuccessCardWrapper>
  }
  else {
    return <SuccessCardWrapper>
      <CheckmarkCircledIcon width={"80"} height={"80"} />
      <h1>{messages.success}</h1>
      <p>{messages.makeSuccessMessage(isApproval, isRenewal, isFromDecline, isFromRemovePrescription)}</p>
      <CloseButton onClick={props.handleClose} btnStyle="secondary">{messages.close}</CloseButton>
      {(isApproval || isRenewal || isFromDecline || isFromRemovePrescription) ? <Button onClick={props.backToList}>{messages.backToList}</Button> : <></>}
    </SuccessCardWrapper>
  }
};
