import * as r from "ramda";
import * as React from "react";
import { BirdIcon } from "../../assets/BirdIcon";
import { CatIcon } from "../../assets/CatIcon";
import { DogIcon } from "../../assets/DogIcon";
import { HorseIcon } from "../../assets/HorseIcon";
import { OtherSpeciesIcon } from "../../assets/OtherSpeciesIcon";
import { ReptileIcon } from "../../assets/ReptileIcon";
import { SmallAnimalIcon } from "../../assets/SmallAnimalIcon";
import { Thumbnail } from "./styles";
import { Props, Species } from "./types";

function randId() {
  return Math.random().toString(20).substr(2, 6);
}

export const SpeciesPicker = (props: Props) => {
  const upperCaseSpecies = r.toUpper(props.species);
  switch (upperCaseSpecies) {
    case Species.DOG:
      return props.useThumbnail ? (
        <Thumbnail>
          <DogIcon id={randId()} />
        </Thumbnail>
      ) : (
        <DogIcon id={randId()} />
      );
    case Species.CAT:
      return props.useThumbnail ? (
        <Thumbnail>
          <CatIcon id={randId()} />
        </Thumbnail>
      ) : (
        <CatIcon id={randId()} />
      );
    case Species.HORSE:
      return props.useThumbnail ? (
        <Thumbnail>
          <HorseIcon id={randId()} />
        </Thumbnail>
      ) : (
        <HorseIcon id={randId()} />
      );
    case Species.BIRD:
      return props.useThumbnail ? (
        <Thumbnail>
          <BirdIcon id={randId()} />
        </Thumbnail>
      ) : (
        <BirdIcon id={randId()} />
      );
    case Species.REPTILE:
      return props.useThumbnail ? (
        <Thumbnail>
          <ReptileIcon id={randId()} />
        </Thumbnail>
      ) : (
        <ReptileIcon id={randId()} />
      );
    case Species.SMALL_MAMMAL:
      return props.useThumbnail ? (
        <Thumbnail>
          <SmallAnimalIcon id={randId()} />
        </Thumbnail>
      ) : (
        <SmallAnimalIcon id={randId()} />
      );
    default:
      return props.useThumbnail ? (
        <Thumbnail>
          <OtherSpeciesIcon id={randId()} />
        </Thumbnail>
      ) : (
        <OtherSpeciesIcon id={randId()} />
      );
  }
};

export const isValidSpeciesType = (species: string) => {
  switch (species.toUpperCase()) {
    case Species.DOG:
      return true;
    case Species.CAT:
      return true;
    case Species.HORSE:
      return true;
    case Species.RABBIT:
      return true;
    case Species.BIRD:
      return true;
    case Species.REPTILE:
      return true;
    case Species.SMALL_MAMMAL:
      return true;
    case Species.OTHER:
      return true;
    default:
      return false;
  }
};

export const mapSpecies = (species: string) => {
  switch (species.toUpperCase()) {
    case "CANINE":
      return Species.DOG;
    case "FELINE":
      return Species.CAT;
    case "EQUINE":
      return Species.HORSE;
    default:
      return species; //if can't find a mapping return as it is
  }
};