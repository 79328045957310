import React, { useEffect, useState } from "react";
import { FilterRowDiv, FilterHeader, FilterFlexColDiv, WeightCheckboxDiv, CheckBoxEmptyDiv } from "./styles";
import * as r from "ramda";
import DropdownSelector from "../DropdownSelector";
import messages from "./messages";
import { CheckboxFilled } from "../../assets/CheckboxFilled";
import { FilterProps, SpeciesFilterProps, WeightFilterProps } from "./types";
import { GlobalStateContext } from "../../context/state";
import { getDisplayPimsName } from "../PimsLogo/getDisplayPimsName";
import Tooltip from "../Tooltip";
import { exists } from "../../util";
import { useRetrieveStrengthRangeQuery } from "../../data/gql/apiTypes";

// Protect against a nonsense species coming over from the claim check
const speciesPresentInOptsOrNull = (speciesOptions: { value: string; label: string }[], patientSpecies: string) => {
  const justSpeciesValues = r.map((species) => species.value, speciesOptions);
  return r.includes(patientSpecies, justSpeciesValues) ? patientSpecies : null;
};

const Species = (props: SpeciesFilterProps) => {
  const { speciesProps, searchProps } = props;
  const { patientSpecies, disabled } = speciesProps;
  const {
    setLastSearched,
    setSearchString,
    setSpeciesFilter,
    speciesFilter,
    setPageOptions,
    searchString,
    pageOptions,
    speciesOptions
  } = searchProps;
  const maybeUpperCaseSpecies = r.toUpper(patientSpecies || "");
  return (
    <DropdownSelector disabled={disabled}
      id={"SpeciesSelect"}
      inputLabel={messages.species}
      opts={r.map(
        (species) => ({
          value: species.value,
          label: species.label,
        }),
        speciesOptions,
      )}
      value={
        r.toUpper(speciesFilter) ||
        speciesPresentInOptsOrNull(speciesOptions, maybeUpperCaseSpecies) ||
        speciesOptions[0].value
      }
      onChange={(e: React.BaseSyntheticEvent) => {
        const upperCaseSpecies = r.toUpper(e.target.value);
        setSearchString("");
        setLastSearched(searchString);
        setSpeciesFilter(upperCaseSpecies);
        setPageOptions({ pageNum: 1, pageSize: pageOptions.pageSize });
      }}
    />
  );
};

const PatientWeightCheckbox = (props: WeightFilterProps) => {
  const { weightCheckboxProps, searchProps } = props;
  const {
    isPatientWeightChecked,
    setIsPatientWeightChecked,
    patientWeight,
    setWeightFilter } = weightCheckboxProps;

  const {
    setLastSearched,
    setSpeciesFilter,
    speciesFilter,
    setPageOptions,
    searchString,
    pageOptions,
    weightFilter
  } = searchProps;

  const context = React.useContext(GlobalStateContext);
  const pimsType = context.state.session.pimsType || "";
  const patientWeightCheckLabel = messages.patientWeightCheckLabel(patientWeight, getDisplayPimsName(pimsType));

  return (
    <WeightCheckboxDiv patientWeight={patientWeight}>
      <label htmlFor={"patientWeight"}>
        {patientWeight && isPatientWeightChecked ? (
          <CheckboxFilled />
        ) : (
          <CheckBoxEmptyDiv patientWeight={patientWeight} />
        )}
        <input
          id={"patientWeight"}
          type={"checkbox"}
          checked={isPatientWeightChecked}
          onChange={(e: React.BaseSyntheticEvent) => {
            setIsPatientWeightChecked(e.target.checked);
            setLastSearched(searchString);
            setSpeciesFilter(speciesFilter);
            setWeightFilter(weightFilter);
            setPageOptions({ pageNum: 1, pageSize: pageOptions.pageSize });
          }}
          disabled={!patientWeight}
        />
        {patientWeight ? (
          patientWeightCheckLabel
        ) : (
          <Tooltip
            message={messages.disabledFilter(getDisplayPimsName(pimsType))}
            position={"bottom"}
            children={patientWeightCheckLabel}
          />
        )}
      </label>
    </WeightCheckboxDiv>
  );
};

const getMinStrenghRange = (range: string[], maxStrength: string) => {
  if (exists(range) && maxStrength) {
    return range.filter((x) => parseFloat(x) <= parseFloat(maxStrength)) as [];
  } else {
    return range;
  }
};

const getMaxStrenghRange = (range: string[], minStrength: string) => {
  if (exists(range) && minStrength) {
    return range.filter((x) => parseFloat(x) >= parseFloat(minStrength)) as [];
  } else {
    return range;
  }
};

const CompoundProductStrenghRangeInput = (props: FilterProps, isPatientWeightChecked: boolean) => {
  const { searchProps, setMinStrengthFilter, setMaxStrengthFilter, speciesProps, patientWeight } = props;

  const { setLastSearched, setPageOptions, searchString, pageOptions, minStrengthFilter, maxStrengthFilter } =
    searchProps;

  const searchCriteria = {
    query: "",
    filter: {
      isCompound: true,
      categoryCode: null,
      treatmentCategoryCode: null,
      speciesCode: r.toUpper(speciesProps.patientSpecies || ""),
      brandCode: null,
      primaryManufacturerCode: null,
      catalogCategoryCode: null,
      baseProductStatus: null,
      familyCode: props.familyCode,
      weight: isPatientWeightChecked ? r.replace(/[^\d.-]/g, "", patientWeight || "") : "",
    },
  };

  const strengthRangeQuery = useRetrieveStrengthRangeQuery({
    variables: { criteria: searchCriteria },
    skip: !exists(searchCriteria),
  });

  if (strengthRangeQuery.loading || strengthRangeQuery.error) return <></>;

  const strengthRange = strengthRangeQuery.data?.retrieveStrengthRange;

  return (
    <>
      <DropdownSelector
        id={"MinStrengthSelect"}
        inputLabel={messages.minStrength}
        opts={r.map(
          (minStrength) => ({
            value: minStrength,
            label: minStrength,
          }),
          getMinStrenghRange(strengthRange || [], maxStrengthFilter || ""),
        )}
        value={minStrengthFilter}
        onChange={(e: React.BaseSyntheticEvent) => {
          setMinStrengthFilter(e.target.value || "");
          setLastSearched(searchString);
          setPageOptions({ pageNum: 1, pageSize: pageOptions.pageSize });
        }}
        addEmptyOption={true}
      />
      <DropdownSelector
        id={"MaxStrengthSelect"}
        inputLabel={messages.maxStrength}
        opts={r.map(
          (maxStrength) => ({
            value: maxStrength,
            label: maxStrength,
          }),
          getMaxStrenghRange(strengthRange || [], minStrengthFilter || ""),
        )}
        value={maxStrengthFilter}
        onChange={(e: React.BaseSyntheticEvent) => {
          setMaxStrengthFilter(e.target.value || "");
          setLastSearched(searchString);
          setPageOptions({ pageNum: 1, pageSize: pageOptions.pageSize });
        }}
        addEmptyOption={true}
      />
    </>
  );
};

export const Filters = (props: FilterProps) => {
  const { displayType, speciesProps, patientWeight, setWeightFilter, searchProps, displayStrengthFilter } = props;
  const [isPatientWeightChecked, setIsPatientWeightChecked] = useState(true);

  useEffect(() => {
    isPatientWeightChecked && patientWeight ? setWeightFilter(patientWeight) : setWeightFilter("");
  }, [isPatientWeightChecked, patientWeight, setWeightFilter]);

  return (
    <>
      {displayType === "column" ? (
        <>
          <FilterHeader isNoMargin={true}>{messages.filters}</FilterHeader>
          <FilterFlexColDiv>
            <Species {...{ speciesProps, searchProps, }} />
            <PatientWeightCheckbox
              weightCheckboxProps={{
                isPatientWeightChecked,
                setIsPatientWeightChecked,
                patientWeight: patientWeight || "",
                setWeightFilter
              }}
              {...{
                searchProps,
              }}
            />
          </FilterFlexColDiv>
          <FilterFlexColDiv>
            <>
              {displayStrengthFilter ? (
                <CompoundProductStrenghRangeInput
                  {...{...props, isPatientWeightChecked}}
                ></CompoundProductStrenghRangeInput>
              ) : (
                <></>
              )}{" "}
            </>
          </FilterFlexColDiv>
        </>
      ) : (
        <>
          <FilterHeader>{messages.filters}</FilterHeader>
          <FilterRowDiv>
            <Species {...{ speciesProps, searchProps }} />
          </FilterRowDiv>
        </>
      )}
    </>
  );
};
