export default {
  close: "Close",
  success: "Success!",
  backToList: "Back to List",
  pendingRequestProcessed: "Pending Request Processed",
  pendingRequestProcessedMessage: "This pending request has been successfully processed by another user, you cannot make updates at this point.",
  makeSuccessMessage: (isApproval: boolean, isRenewal: boolean, isFromDecline: boolean, isFromRemovePrescription: boolean) => (
    `The ${isApproval ? 'approval' : isRenewal ? 'renewal' : 'script'} 
      ${isFromDecline ? 'has been declined and' : isFromRemovePrescription ? 'has been archived, removed and' : 'was'} 
      sent to Covetrus Prescription Management`
  )
};
