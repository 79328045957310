import styled from "styled-components/macro";
import { Card } from "../StyledComponents/Card/Card";
import { Button } from "../StyledComponents/Button/Button";

export const SuccessCard = styled(Card)`
  width: 464px;
  text-align: center;
  margin: 32px auto;
  padding: 32px;
  color: ${({ theme }) => theme.primaryTextColor};
  > svg,
  h1,
  p {
    margin-bottom: 32px;
  }
  > p {
    line-height: 24px;
    padding: 0 16px;
  }
`;

export const SuccessCardWrapper = styled(SuccessCard)`
margin-top: 80px;

button {
  margin: 10px;
  padding: 9px;
}
`; 

export const BoldSpan = styled.span`
  font-weight: bold;
`;

export const CloseButton = styled(Button)`
  width: 128px;
`;
