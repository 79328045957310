import { pims } from "./components/PimsLogo/PimsLogo";
import { logout } from "./PrivateRoute/utils";

/**
 * Smooth scroll behavior in the Browser. Defaults to top of the page (0px)
 * if there is no argument provided for `scrollTo`.
 */
export const smoothScroll = (scrollTo?: number) =>
  window.scrollTo({
    top: scrollTo || 0,
    behavior: "smooth",
  });

export const clearContextKey = () => {
  sessionStorage.removeItem("ContextKey");
}

export const clearSession = () => {
  sessionStorage.clear();
}

// Close/Cancel flow helpers
export const getCloseFunction = (pimsType: string | null, claimCheckKey: string | null): (() => void) => {
  // if we are in an iframe set to our parent url, otherwise null.
  const parentUrl = document.referrer && document.referrer !== document.location.origin + '/' ? document.referrer : null;
  
  if (
    pimsType?.toLowerCase() === pims.eVetShort.toLowerCase() ||
    pimsType?.toLowerCase() === pims.eVetLong.toLowerCase()
  ) {
    return () => {
      logout(claimCheckKey).finally(() => {
        clearSession();
        if (parentUrl) {
          window.parent.postMessage({ step: "login", action: "close" }, parentUrl);
        }
      });
    };
  }
  if (pimsType?.toLowerCase() === pims.avimark.toLowerCase()) {
    return () => {
      window.close();
    };
  }
  //All 3rd party PIMS will be using iframe
  return () => {
    logout(claimCheckKey).finally(() => {
      clearSession();
      if (parentUrl) {
        window.parent.postMessage({ step: "login", action: "close" }, parentUrl);
      }
      window.close();
    });
  };
};

// logout and redirect to login
export const logoutAndRedirect = (claimCheckKey: string | null) => {
  return () => {
    logout(claimCheckKey).finally(() => {
      clearSession();
      window.location.reload();
    });
  };
};
