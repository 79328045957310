import * as React from "react";

type Props = {
  className?: string;
};

export const ChevronDown = (props: Props) => (
  <svg
    width="24px"
    height="16px"
    viewBox="0 0 24 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>chevron-down</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="translate(-808.000000, -228.000000)" fill="#4D5051" fillRule="nonzero">
        <polygon
          id="chevron-down"
          transform="translate(820.000000, 236.083277) scale(1, -1) translate(-820.000000, -236.083277) "
          points="810.82 243.493277 820 234.333277 829.18 243.493277 832 240.673277 820 228.673277 808 240.673277"
        ></polygon>
      </g>
    </g>
  </svg>
);

export const ChevronUp = (props: Props) => (
  <svg
    width="24px"
    height="16px"
    viewBox="0 0 24 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>chevron-down</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="rotate(180, 12,8)translate(-808.000000, -228.000000)" fill="#4D5051" fillRule="nonzero">
        <polygon
          id="chevron-up"
          transform="translate(820.000000, 236.083277) scale(1, -1) translate(-820.000000, -236.083277) "
          points="810.82 243.493277 820 234.333277 829.18 243.493277 832 240.673277 820 228.673277 808 240.673277"
        ></polygon>
      </g>
    </g>
  </svg>
);

export const ChevronRight = (props: Props) => (
  <svg
    width="24px"
    height="16px"
    viewBox="0 0 24 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>chevron-right</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="rotate(-90, 12,8)translate(-808.000000, -228.000000)" fill="#4D5051" fillRule="nonzero">
        <polygon
          id="chevron-up"
          transform="translate(820.000000, 236.083277) scale(1, -1) translate(-820.000000, -236.083277) "
          points="810.82 243.493277 820 234.333277 829.18 243.493277 832 240.673277 820 228.673277 808 240.673277"
        ></polygon>
      </g>
    </g>
  </svg>
)

export const PageLeft = (props: Props) => (
  <svg
    width="16px"
    height="8px"
    viewBox="0 0 40 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>page-left</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="rotate(90, 12,8)translate(-808.000000, -228.000000)" fill="#4D5051" fillRule="nonzero">
        <polygon
          id="chevron-left"
          transform="translate(820.000000, 236.083277) scale(1, -1) translate(-820.000000, -236.083277) "
          points="810.82 243.493277 820 234.333277 829.18 243.493277 832 240.673277 820 228.673277 808 240.673277"
        ></polygon>
      </g>
    </g>
  </svg>
);

export const PageRight = (props: Props) => (
  <svg
    width="16px"
    height="8px"
    viewBox="0 0 40 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>page-right</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="rotate(270, 12,8)translate(-808.000000, -228.000000)" fill="#4D5051" fillRule="nonzero">
        <polygon
          id="chevron-right"
          transform="translate(820.000000, 236.083277) scale(1, -1) translate(-820.000000, -236.083277) "
          points="810.82 243.493277 820 234.333277 829.18 243.493277 832 240.673277 820 228.673277 808 240.673277"
        ></polygon>
      </g>
    </g>
  </svg>
);
