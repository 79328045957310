import * as React from "react";
import { Props, InputOption } from "./types";
import { InputWrapper, InputErrorDiv, SpacerErrorDiv } from "./styles";
import { InputLabel } from "../StyledComponents/InputLabel/InputLabel";
import { Input } from "../StyledComponents/Input/Input";
import { AlertCircleIcon } from "../../assets/AlertCircleIcon";
import { exists } from "../../util";
import * as r from "ramda";
import Tooltip from "../Tooltip";

const DropdownSelector = <Query, QueryVars>(props: Props<Query, QueryVars>) => {
  const [inputOptionsHasLength, setInputOptionsHasLength] = React.useState(false);
  const { loading, data, error } =
    exists(props.useOptionsGetter) && props.queryVars != null
      ? props.useOptionsGetter(props.queryVars)
      : { loading: false, data: null, error: null };

  const queryOptions = !loading && !error && exists(data) && exists(props.getOptions) ? props.getOptions(data) : null;
  if (queryOptions != null && props.onOptsLoaded) {
    props.onOptsLoaded(queryOptions);
  }

  const inputOptions = props.opts
    ? props.mergeOpts
      ? [...props.opts, ...(queryOptions || [])]
      : queryOptions || props.opts
    : queryOptions || [];

  React.useEffect(() => {
    setInputOptionsHasLength(r.length(inputOptions) > 0);
  }, [inputOptions]);

  const select = (
    <>
      <InputLabel htmlFor={props.id} label={props.inputLabel} required={props.required} errors={props.errors} />
      <Input
        id={props.id}
        name={props.id}
        as="select"
        fullWidth
        disabled={props.disabled || loading}
        onChange={props.onChange}
        errors={props.errors}
        value={props.value}
        defaultValue={props.defaultValue}
      >
        <option hidden value={""}>
          {loading ? "Loading Options..." : ""}
        </option>
        {props.addEmptyOption? <option value=''></option> : <></>}
        {r.map(
          (option: InputOption) => (
            <option key={`${option.label}-${option.value}`} value={option.value}>
              {option.label}
            </option>
          ),
          inputOptions,
        )}
      </Input>
    </>
  );

  return (
    <InputWrapper noSpacer={props.noSpacer}>
      {inputOptionsHasLength || loading || !props.tooltip?.message ? (
        select
      ) : (
        <Tooltip
          message={props.tooltip?.message || ""}
          position={"bottom"}
          children={select}
          customStyles={props.tooltip?.customStyles}
        />
      )}

      {props.helperBtn ? props.helperBtn : null}
      {props.helpFooter ? props.helpFooter : null}
      {props.errors ? (
        <InputErrorDiv>
          {props.errors}
          <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
        </InputErrorDiv>
      ) : (
        !props.noSpacer && (
          <SpacerErrorDiv />
        )
      )}
    </InputWrapper>
  );
};

export default DropdownSelector;
