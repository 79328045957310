import * as React from "react";
import { Props, WorkflowStatuses } from "./types";
import DropdownSelector from "../../../DropdownSelector";
import messages from "../../../FinishScriptForm/messages";
import * as r from "ramda";

const WorkflowStatus = (props: Props) => {
  const { errors, value, onChange, required, defaultValue, disabled } = props;
  return (
    <DropdownSelector
      id="workflowStatus"
      inputLabel={messages.workflowStatusLabel}
      required={required}
      errors={errors}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
      opts={r.map(
        (status) => ({
            value: status,
            label: WorkflowStatuses[status as keyof typeof WorkflowStatuses],
          }),
        Object.keys(WorkflowStatuses),
      )}
      noSpacer
    />
  );
};

export default WorkflowStatus;
