import * as React from "react";
import VRxProLogoImage from "../../assets/VRxProLogo.png";
import { Props } from "./types";

const VRxProLogoImageStyles = {
  width: "auto",
  height: "56px",
  marginLeft: "-35px",
  marginBottom: "-15px",
  marginTop: "-9px"
};

export const VRxPro = {
  VRxPro: "VRxPro",
};

const VRxProLogo = (props: Props) => {
    return <img src={VRxProLogoImage} alt="VRxPro Logo" style={VRxProLogoImageStyles} />;
};

export default VRxProLogo;
