import * as React from "react";

type Props = {
  className?: string;
};

export const CloseGreyIcon = (props: Props) => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={props.className}
  >
    <title>Close</title>
    <g id="🛒-Product-Linking" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="800px-Screens-/-Link-Drug-/-Drug-Info"
        transform="translate(-701.000000, -130.000000)"
        fill="#B5B5B6"
        fillRule="nonzero"
      >
        <g id="Product-Info" transform="translate(48.000000, 93.000000)">
          <g id="Icons/Material/X/Gray" transform="translate(648.000000, 32.000000)">
            <polygon
              id="Shape"
              points="19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12"
            ></polygon>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
