export default {
  header: "Link Product",
  description: "You’ll only have to do this once so we can accurately fulfill this prescription.",
  noMatchHeader: "This patient isn’t linked.",
  noMatchText: "You will be able to link them in the next step.",
  weightSyncFailedMessage: (pimsName: string) =>  `Patient weight was not updated because ${pimsName || 'PIMS'} has no weight for this patient.`,
  updateWeightSuccessful: "Successfully updated patient weight.",
  updateWeightFailure: "Failed to update patient weight.",
  searchProductsFailure: "Error searching products.",
  fetchLinkedPatientQueryFailure: "Error retrieving linked patient details.",
  fetchLinkedClientQueryFailure: "Error retrieving linked client details."
};
