import * as React from "react";
import ModalWrapper from "../StyledComponents/Modal/Modal";
import { RxIcon } from "../../assets/RxIcon";
import {
  TitleText,
  DescriptionText,
  HeaderContainer,
  HeaderText,
  ProductPricingTable,
  TableHead,
  TableCell,
  TableDataRow,
  TooltipContainer,
} from "./styles";
import { Props, Messages } from "./types";
import { makeMessages } from "./messages";
import Tooltip from "../../components/Tooltip";
import { InfoCircled } from "../../assets/InfoCircled";

const tableName = "product-pricing-table";
const makeTableHeader = ({ tableInfo }: Messages) => {
  return tableInfo.header.map((el: string, i: number) => {
    return <TableHead key={`${tableName}-head-${i}`}>
      {el}
      {
        tableInfo.tooltip[i] && (
          <TooltipContainer title={tableInfo.tooltip[i]}>
            <Tooltip
              message={tableInfo.tooltip[i]}
              position="top"
            >
              <InfoCircled />
            </Tooltip>
          </TooltipContainer>
        )
      }
    </TableHead>;
  })
}

const makeTableData = ({ tableInfo }: Messages) =>
  tableInfo.data.map((el: string, i: number) => (
    <TableCell title={el} key={`${tableName}-data-${i}`}>
      {el}
    </TableCell>
  ));
const makeTable = (messages: Messages) => (
  <ProductPricingTable cellSpacing={0}>
    <thead>
      <tr>{makeTableHeader(messages)}</tr>
    </thead>
    <tbody>
      <TableDataRow>{makeTableData(messages)}</TableDataRow>
    </tbody>
  </ProductPricingTable>
);

const getText = (message: { title: string; description: string | null }) => (
  <>
    <TitleText>{message.title}</TitleText>
    <DescriptionText>{message.description}</DescriptionText>
  </>
);

export default (props: Props) => {
  const messages = makeMessages(props.product);
  const { header, description, activeIngredients, productInfo, directions, specialPrecautions } = messages;

  return (
    <ModalWrapper onClose={() => props.onClose()} cancellable={true}>
      <>
        <HeaderContainer>
          <RxIcon />
          <HeaderText>
            <h1>{header.title}</h1>
          </HeaderText>
        </HeaderContainer>
        <TitleText>{description.title}</TitleText>
        {/* Some product descriptions contain HTML so render it as such */}
        <DescriptionText dangerouslySetInnerHTML={{ __html: description.description || "" }} />
        <TitleText>{messages.tableInfo.title}</TitleText>
        {makeTable(messages)}
        {getText(activeIngredients)}
        <TitleText>{productInfo.title}</TitleText>
        {/* Some product information contains HTML so render it as such */}
        <DescriptionText dangerouslySetInnerHTML={{ __html: productInfo.description || "" }} />
        {getText(directions)}
        {getText(specialPrecautions)}
      </>
    </ModalWrapper>
  );
};
