import * as H from "history";
import * as r from "ramda";
import * as React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { ArrowIcon } from "../../assets/ArrowIcons";
import CovetrusLogo from "../../assets/CovetrusLogo";
import { HelpIcon } from "../../assets/HelpIcon";
import { betaFeedbackLink } from "../../config/frontend";
import { flowType } from "../../context/actions";
import { GlobalStateContext } from "../../context/state";
import { FlowType } from "../../context/types";
import { exists, handleLink, isValidNonNilGuid } from "../../util";
import { BackButton, FeedbackButton, HeaderContainer } from "./styles";

// back button render helpers
const buildShowBackButtonMap = (flow: FlowType, claimCheckKey?: string, prescriptionGuid?: string) =>
  new Map([
    ["/", false],
    ["login", false],
    ["patient-linking", false],
    ["product-linking", flow === flowType.linked && exists(claimCheckKey)],
    ["product-search", flow === flowType.unlinked && exists(claimCheckKey)],
    ["review-rx", exists(claimCheckKey)],
    ["client-linking", true],
    ["patient-import", true],
    ["pending-rx", true],
    ["product-search", flow === flowType.pending && exists(claimCheckKey) && exists(prescriptionGuid)],
    ["pending-rx-list", false]
  ]);

const getShowBack = (pathname: string, backBtnMap: Map<string, boolean>): boolean => {
  return backBtnMap.get(pathname) || false;
};

type Props = {
  showHelp: boolean;
  showBetaFeedback: boolean;
  location: H.Location;
};

const handleGoBack = (
  history: H.History,
  customGoTo: { is: boolean; path: string },
  returnTo: string,
  maybeClaimCheckGuid?: string,
  sessionStateFlow?: FlowType,
) => () => {
  if (sessionStateFlow === flowType.pending) {
    history?.location?.pathname.includes("pending-rx/")? history.replace(`/pending-rx-list/${maybeClaimCheckGuid}`) : history.goBack();
  }
  else {
    customGoTo.is && maybeClaimCheckGuid ? history.push(customGoTo.path) : history.push(returnTo);
  }
};

const PimsRxHeader = (props: Props) => {
  const [customGoTo, setCustomGoTo] = React.useState({ is: false, path: "" });
  const context = React.useContext(GlobalStateContext);
  const history = useHistory();
  const sessionStateFlow = context.state.session.flow;
  const pathnameParts = r.split("/", props.location.pathname);
  const pathWithoutSlashesOrParams = pathnameParts[1];

  const indexOfValidGuid = r.indexOf(true, r.map(isValidNonNilGuid, pathnameParts));
  const maybeClaimCheckGuid = pathnameParts[indexOfValidGuid];
  const maybePrescriptionGuid = pathnameParts[indexOfValidGuid + 1];
  // If flow is unlinked and we are on review-rx, we want the user to be able to go back to product-search
  React.useEffect(() => {
    if (sessionStateFlow === flowType.unlinked && pathWithoutSlashesOrParams === "review-rx" && maybeClaimCheckGuid) {
      setCustomGoTo({ is: true, path: `/product-search/${maybeClaimCheckGuid}` });
    }
  }, [sessionStateFlow, pathWithoutSlashesOrParams, maybeClaimCheckGuid]);

  // previousPaths persists (only) one step back in history, in this form [previousPath, currentPath]
  const [previousPaths, setPreviousPaths] = useState([props.location.pathname]);
  if (previousPaths.length >= 2 && previousPaths[1] !== props.location.pathname) {
    let newPaths = r.append(props.location.pathname, r.drop(1, previousPaths));
    setPreviousPaths(newPaths);
  }
  const showBackButtonMap = buildShowBackButtonMap(sessionStateFlow, maybeClaimCheckGuid, maybePrescriptionGuid);
  return (
    <HeaderContainer>
      {props.location.pathname && getShowBack(pathWithoutSlashesOrParams, showBackButtonMap) ? (
        <BackButton btnStyle="secondary" onClick={handleGoBack(history, customGoTo, previousPaths[0], maybeClaimCheckGuid, sessionStateFlow)}>
          <ArrowIcon width="18px" direction="left" />
          {"BACK"}
        </BackButton>
      ) : (
        <></>
      )}
      <CovetrusLogo className={"covetrus-logo"} />
      {props.showBetaFeedback ? (
        <FeedbackButton btnStyle="secondary" onClick={handleLink(context.state.session.pimsType, betaFeedbackLink)}>
          {"feedback"}
        </FeedbackButton>
      ) : (
        <></>
      )}
      {props.showHelp ? (
        <Link
          to={{
            pathname: `/faq`,
            state: { background: props.location },
          }}
        >
          <HelpIcon className={"help-icon"} />
        </Link>
      ) : (
        <></>
      )}
    </HeaderContainer>
  );
};

export default PimsRxHeader;
