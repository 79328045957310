import * as r from "ramda";
import { pims } from "./PimsLogo";

export const getDisplayPimsName = (pimsType: string, is?: { long: boolean }) => {
  const [lowerEvetShort, lowerEvetLong, lowerAvimark] = r.map(r.toLower, r.values(pims));
  const lowerPimsType = r.toLower(pimsType);
  const comparePimsTypeWith = (sessionPims: string) => r.equals(lowerPimsType, sessionPims);
  const isEvetAndIsLong = r.or(comparePimsTypeWith(lowerEvetLong), comparePimsTypeWith(lowerEvetShort)) && is?.long;
  const isEvet = r.or(comparePimsTypeWith(lowerEvetLong), comparePimsTypeWith(lowerEvetShort));
  const isAvimark = comparePimsTypeWith(lowerAvimark);

  if (isEvetAndIsLong) {
    return "Pulse";
  }
  if (isEvet) {
    return "Pulse";
  }
  if (isAvimark) {
    return "AVImark";
  }
  return "the practice management system";
};
