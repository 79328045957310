import * as H from "history";
import React from "react";
import { useHistory, useParams } from "react-router";
import {
  useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery,
} from "../../data/gql/apiTypes";
import Loading from "../../components/StyledComponents/Loading/Loading";
import RxSuccess from "../../components/RxSuccess";
import { WarningCard } from "../../components/WarningCard/WarningCard";
import { useRemoveSessionExceptVetAndListTypeFilterEffect } from "../../hooks/sessionFlow";
import { getCloseFunction } from "../../domUtils";
import { ContainerProps } from "../../PrivateRoute/types";

export default (props: ContainerProps) => {
  const history = useHistory();
  const hasProcessedByAnotherUser = history.location.state === "HAS_PROCESS_BY_ANOTHER_USER" ? true : false;

  const { claimCheckKey, prescriptionGuid } = useParams<{ claimCheckKey?: string, prescriptionGuid?: string }>();

  const { loading, data, error } = useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery({
    variables: { claimCheckKey: claimCheckKey ?? "", pimsPrescriptionKey: prescriptionGuid || null },
    skip: !claimCheckKey || hasProcessedByAnotherUser
  });

  // Clean up session
  useRemoveSessionExceptVetAndListTypeFilterEffect();

  const getBackToListFunction = (history: H.History<any>): () => void => {
    return () => {
      history.push(`/pending-rx-list/${claimCheckKey}`);
    };
  };

  if (hasProcessedByAnotherUser){
    return <RxSuccess
            isApproval={false}
            isRenewal={false}
            isFromDecline={false}
            hasProcessedByAnotherUser={hasProcessedByAnotherUser}
            handleClose={getCloseFunction(null, claimCheckKey ?? null)}
            backToList={getBackToListFunction(history)}
            isFromRemovePrescription={false}
            />
  }

  // At this point, all the useHooks have been called, so now it's ok to branch.
  if (!claimCheckKey) {
    return <WarningCard reason={"No Claim Check GUID present on URL"} />;
  }

  if (loading) {
    return <Loading fullPage />;
  }

  if (error) {
    return <WarningCard reason={"Error Encountered while retrieving Claim Check"} />;
  }

  const maybePimsType = data?.retrieveRxByGuidOrClaimCheckGuid?.pimsType ?? null;
  const isApproval = !!data?.retrieveRxByGuidOrClaimCheckGuid?.approvalKey;
  const isRenewal = !!data?.retrieveRxByGuidOrClaimCheckGuid?.renewalKey;
  const isFromDecline = history.location.state === "DECLINE APPROVALS" || history.location.state === "DECLINE RENEWALS";
  const isFromRemovePrescription = history.location.state === "REMOVE" ? true : false;

  return (
    <RxSuccess
      isApproval={isApproval}
      isRenewal={isRenewal}
      isFromDecline={isFromDecline}
      hasProcessedByAnotherUser={hasProcessedByAnotherUser}
      handleClose={getCloseFunction(maybePimsType, claimCheckKey)}
      backToList={getBackToListFunction(history)}
      isFromRemovePrescription={isFromRemovePrescription}
    />
  );
};
