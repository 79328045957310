import * as r from "ramda";
import { getDisplayPimsName } from "../PimsLogo/getDisplayPimsName";
import { pims } from "../PimsLogo/PimsLogo";

export default {
  header: "Covetrus Prescription Management FAQ",
  makeFaqItems: (pimsType: string | null) => {
    const isAvimark = pimsType ? r.toLower(pimsType) === r.toLower(pims.avimark) : false;
    const nameOfPims = pimsType ? getDisplayPimsName(pimsType, { long: true }) : "";
    const fallBackPimsString = "the PIMS";
    const base = [
      {
        text: "What does this integration do?",
        subItems: [
          {
            text: `The Covetrus Prescription Management integration allows you to create proactive prescriptions for your patients in the online pharmacy directly from 
            ${
              isAvimark
                ? `${nameOfPims || fallBackPimsString}! You can do this from two locations.`
                : `${nameOfPims || fallBackPimsString}!`
            }`,
          },
        ],
      },
      {
        text: "What happens after I submit the prescription?",
        subItems: [
          {
            text: `When you submit the prescription, the client will receive a notification that the product has been recommended, it will appear in their recommended items when they log into storefront, and a record will be written to the
              ${isAvimark ? "medical" : "prescription"}
             history of the patient to indicate that the RX was written${
               isAvimark ? "." : " (this may take a few minutes after submission to appear)."
             }
             If the customer purchases the product, there will be an additional writeback to the 
             ${isAvimark ? "medical" : "prescription"}
               history.`,
          },
        ],
      },
    ];

    if (!isAvimark) {
      return [
        base[0],
        {
          text: "Why do I sometimes need to link my patients?",
          subItems: [
            {
              text: `Linking the ${
                nameOfPims || "PIMS"
              } version of the patient to the Online Pharmacy patient allows the system in future prescriptions to identify the online version without requiring anyone to manually select them.  You will only ever need to link each patient once!`,
            },
          ],
        },
        base[1],
      ];
    }
    return [
      base[0],
      {
        text: "Why do I need to log in with every prescription?",
        subItems: [
          {
            text:
              "This is a requirement currently to ensure the security and legality of the prescriptions which are created.",
          },
          {
            text: "We are actively working on improving this experience, so stay tuned!",
          },
        ],
      },
      {
        text: "Why do I sometimes need to link my product or patients?",
        subItems: [
          {
            text: `Linking the ${
              nameOfPims || "PIMS"
            } version of your product or patient allows the system in future prescriptions to identify the online version without requiring anyone to manually select them in the future. You will only ever need to link each patient, client, or product once!`,
          },
        ],
      },
      base[1],
    ];
  },
  bottomText: "For any questions, please don’t hesitate to reach out to your Covetrus account manager!",
};
