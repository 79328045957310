import { Formik, FormikProps } from "formik";
import * as H from "history";
import * as r from "ramda";
import * as React from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { FormError } from "../../components/FormError/FormError";
import { Button } from "../../components/StyledComponents/Button/Button";
import { Input } from "../../components/StyledComponents/Input/Input";
import { InputLabel } from "../../components/StyledComponents/InputLabel/InputLabel";
import Loading from "../../components/StyledComponents/Loading/Loading";
import { privacyPolicyLink, termsOfServiceLink, practiceEnrollmentsLink } from "../../config/frontend";
import { handleLink, isValidNonNilGuid } from "../../util";
import { FormControlsContainer, FormTitle, LinkButton, LoginButton, TermsContainer, UsernameInput } from "./styles";
import { LocationState, LoginFormValues, Props } from "./types";
import { anyValuesEmpty, FormSchema, getPimsTypeFromLocation, submitForm } from "./utils";

const terms = (pimsType: string | null) => (
  <span>
    {`I agree with the `}
    <LinkButton type="button" onClick={handleLink(pimsType, termsOfServiceLink)}>
      Terms of Service
    </LinkButton>
    {`, `}
    <LinkButton type="button" onClick={handleLink(pimsType, privacyPolicyLink)}>
      Privacy Policy
    </LinkButton>
    {` and `}
    <LinkButton type="button" onClick={handleLink(pimsType, practiceEnrollmentsLink)}>
      Practice Enrollments
    </LinkButton>
    .
  </span>
);

export const LoginForm = (props: Props) => {
  const [credentialError, setCredentialError] = React.useState(false);
  const [continueOnSuccess, setContinueOnSuccess] = React.useState(false);
  const [claimCheckKeyFromLocation, setClaimCheckGuidFromLocation] = React.useState<string | null>(null);
  const location = useLocation<LocationState>();
  const history = useHistory<H.History>();
  const {
    location: { state: historyState },
  } = history as any;

  const fromLocation = r.pathOr(historyState?.from?.location?.pathname || "", ["state", "from", "pathname"], location);
  const fromLocationParts = r.split("/", (fromLocation || historyState?.from?.location.pathname) ?? "");
  // NOTE: `indexOf` returns the first occurrence. We may have more than one valid guid on
  // the from location but the claimCheckKey will always be the first
  const indexOfValidGuid = r.indexOf(true, r.map(isValidNonNilGuid, fromLocationParts));
  const maybeClaimCheckGuid = fromLocationParts[indexOfValidGuid] || "";

  const pimsType = getPimsTypeFromLocation(location);

  React.useEffect(() => {
    if (maybeClaimCheckGuid && isValidNonNilGuid(maybeClaimCheckGuid)) {
      setClaimCheckGuidFromLocation(maybeClaimCheckGuid);
    }
  }, [claimCheckKeyFromLocation, setClaimCheckGuidFromLocation, maybeClaimCheckGuid]);

  return (
    <Formik<LoginFormValues>
      initialValues={{ password: "", username: "" }}
      validationSchema={FormSchema}
      onSubmit={(vals, helpers) => {
        if (credentialError) {
          setCredentialError(false);
        }
        submitForm(vals, helpers, props, setCredentialError, setContinueOnSuccess, history, maybeClaimCheckGuid, pimsType);
      }}
    >
      {(formikBag: FormikProps<LoginFormValues>) => {
        const {
          handleChange,
          handleSubmit,
          values: { username, password },
          isSubmitting,
        } = formikBag;

        if (isSubmitting) {
          return <Loading />;
        }
        if (continueOnSuccess) {
          return <Redirect to={{ pathname: fromLocation || `product-search/${claimCheckKeyFromLocation}` }} />;
        }

        return (
          <form onSubmit={handleSubmit}>
            <FormTitle>Sign in to Covetrus Rx Management</FormTitle>
            {credentialError && <FormError />}
            <InputLabel htmlFor={"username"} label={"Username"} />
            <UsernameInput id={"username"} name={"username"} type={"text"} onChange={handleChange} fullWidth />
            <InputLabel htmlFor={"password"} label={"Password"} />
            <Input id={"password"} name={"password"} type={"password"} onChange={handleChange} fullWidth />
            <TermsContainer>{terms(pimsType)}</TermsContainer>
            <FormControlsContainer>
              <Button onClick={props.handleClose} type={"button"} btnStyle={"secondary"}>
                Cancel
              </Button>
              <LoginButton type={"submit"} btnStyle={"primary"} disabled={anyValuesEmpty([username, password])}>
                Login
              </LoginButton>
            </FormControlsContainer>
          </form>
        );
      }}
    </Formik>
  );
};
