import * as H from "history";
import * as r from "ramda";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { addToast } from "../../actions/toast";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import commonMessages from "../../common/messages";
import CollapsableCard from "../../components/CollapsableCard/index";
import { DescriptionComponent } from "../../components/DescriptionComponent";
import PimsLogo from "../../components/PimsLogo";
import SearchGrid from "../../components/SearchGrid";
// need to revisit (isValidSpecies or isValidSpeciesType)
import { SpeciesPicker, isValidSpeciesType } from "../../components/Species/Species";
import { Container } from "../../components/StyledComponents/Container/Container";
import Loading from "../../components/StyledComponents/Loading/Loading";
import { WarningCard } from "../../components/WarningCard/WarningCard";
import {
  useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery,
  useRetrieveLinkedClientDetailsQuery,
  useRetrieveLinkedPatientDetailsQuery,
  useUpdateRxWithUnlinkedProductMutation,
  useAutocompleteSearchGPMProductFamiliesQuery,
  useProductDetailsByKeyQuery,
  SearchGPMProductFamiliesQuery,
  useSearchGPMProductsQuery,
  useGetBaseProductQuery,
  SearchGPMProductsQuery
} from "../../data/gql/apiTypes";
import { exists, nullMap } from "../../util";
import { getGridHeaders, getCompoundProductGridHeaders, getResultsProp, getAutoCompleteResultsProp } from "./productUtils";
import { fetchClientName, fetchCollapsedText, fetchPatientWeight } from "../ReviewRx/utils";
import messages from "./messages";
import Modal from "../../components/ProductDetailsModal";
import { generateToastID } from "../../components/Toast/Toast";
import UpdatePatientWeightModal from "../../components/UpdatePatientWeightModal";
import { InfoCircled } from "../../assets/InfoCircled";
import { PencilIcon } from "../../assets/PencilIcon";
import Tooltip from "../../components/Tooltip";
import { getDisplayPimsName } from "../../components/PimsLogo/getDisplayPimsName";
import { ContainerProps } from "../../PrivateRoute/types";
import { useDispatchErrors } from "../../hooks/useDispatchErrors";

export type SearchFamilyProductResult = SearchGPMProductFamiliesQuery["searchProductFamilies"][0];
export type SearchGPMProductResult = SearchGPMProductsQuery["searchProducts"][0];

const ProductUnlinked = (props: ContainerProps) => {
  const [collapsed, setCollapsed] = useState(true);
  const { prescriptionGuid, claimCheckKey } = useParams<{ claimCheckKey?: string, prescriptionGuid: string }>();
  const history = useHistory();
  const location = useLocation<H.History>();
  const dispatch = useDispatch();
  const [searchStep, setSearchStep] = useState<"pre" | "automated" | "user">("pre");
  const [isCompoundProduct, setIsCompoundProduct]= useState<boolean | null>(null);
  const [searchCriteria, setSearchCriteria] = useState( {
    query: "",
    filter: {
      isCompound: isCompoundProduct,
      categoryCode: "",
      treatmentCategoryCode: "",
      speciesCode: "",
      brandCode: "",
      primaryManufacturerCode: "",
      catalogCategoryCode: "",
      baseProductStatus: "",
      familyCode: "",
      weight: ""
    }
  });
  const [selectedProduct, setSelectedProduct] = useState<SearchGPMProductResult | null>(null);
  const [productInfoDialog, setProductInfoDialog] = useState<SearchGPMProductResult | null>(null);
  const [
    updateRxWithUnlinkedProduct,
    { loading: unlinkedProductMutationLoading },
  ] = useUpdateRxWithUnlinkedProductMutation();
  const [autoCompleteSearchCriteria, setAutoCompleteSearchCriteria] = useState(
    {
      query: "",
      filter: {
        isCompound: isCompoundProduct,
        categoryCode: "",
        treatmentCategoryCode: "",
        speciesCode: "",
        brandCode: "",
        primaryManufacturerCode: "",
        catalogCategoryCode: "",
        baseProductStatus: "",
        useFamilyCode: false,
        weight: ""
      }
    }
  );
  const [showUpdatePatientWeightModal, setShowUpdatePatientWeightModal] = useState(false);

  const getPatientWeightRightValue = () => {
    if (gpmPatient && patient && patient.currentWeight && (!exists(patient.currentWeight) || patient.currentWeight !== '0')) {
      return (
        <div style={{display: 'flex', justifyContent: 'space-between', flexFlow: 'row'}} title={gpmWeightWithUnits || '' }>
          {gpmWeightWithUnits}
          <div style={{marginRight: 20}}>
            <div style={{position: 'absolute', whiteSpace: 'normal', flexDirection: 'row'}}>
              <div style={{cursor: 'pointer'}} onClick={() => setShowUpdatePatientWeightModal(true)}>
                <PencilIcon />
              </div>     
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{display: 'flex', justifyContent: 'space-between', flexFlow: 'row'}} title={gpmWeightWithUnits || '' }>
        <div style={{fontWeight: 'bold'}}>
          {gpmWeightWithUnits}
        </div>
        <div style={{marginRight: 40}}>
          <div style={{position: 'absolute', whiteSpace: 'normal', flexDirection: 'row'}}>
            <div style={{cursor: 'pointer', marginRight: 10}} onClick={() => setShowUpdatePatientWeightModal(true)}>
              <PencilIcon />
            </div>
            <Tooltip message={messages.weightSyncFailedMessage(getDisplayPimsName(maybePimsType || ""))} position="right">
              <InfoCircled/>
            </Tooltip>            
          </div>
        </div>
      </div>
    );
  };

  const { loading, error, data } = useRetrieveRxDetailsWithGuidORClaimCheckGuidQuery({
    variables: { claimCheckKey: claimCheckKey || "", pimsPrescriptionKey: prescriptionGuid || null },
    fetchPolicy: "cache-first",
  });

  const qResult = useSearchGPMProductsQuery({
    variables: { criteria: searchCriteria,
                 claimCheckKey: claimCheckKey || "" },
    skip: !exists(searchCriteria.query),
  });  

  const aqResult = useAutocompleteSearchGPMProductFamiliesQuery({
    variables: { criteria: autoCompleteSearchCriteria},
    skip: !exists(autoCompleteSearchCriteria.query),
  });

  const gpmPatientQuery = useRetrieveLinkedPatientDetailsQuery({
    variables: { key: data?.retrieveRxByGuidOrClaimCheckGuid?.linkedPatientKey || "" },
    skip: !exists(data?.retrieveRxByGuidOrClaimCheckGuid?.linkedPatientKey),
  });

  const gpmClientQuery = useRetrieveLinkedClientDetailsQuery({
    variables: {
      guid:
        data?.retrieveRxByGuidOrClaimCheckGuid?.linkedClientKey || gpmPatientQuery?.data?.getPatient?.clientKey || "",
    },
    skip:
      !exists(data?.retrieveRxByGuidOrClaimCheckGuid?.linkedClientKey) &&
      !exists(gpmPatientQuery?.data?.getPatient?.clientKey),
  });

  const baseProductKey = (props.claimCheck?.eventType === "pendingRxEvent" || data?.retrieveRxByGuidOrClaimCheckGuid?.approvalKey != null || data?.retrieveRxByGuidOrClaimCheckGuid?.renewalKey != null) ? data?.retrieveRxByGuidOrClaimCheckGuid?.baseProductKey : null;
  const productKey = (props.claimCheck?.eventType === "pendingRxEvent" || data?.retrieveRxByGuidOrClaimCheckGuid?.approvalKey != null || data?.retrieveRxByGuidOrClaimCheckGuid?.renewalKey != null) ? data?.retrieveRxByGuidOrClaimCheckGuid?.requestedProductKey : data?.retrieveRxByGuidOrClaimCheckGuid?.unlinkedProductKey

  const gpmBaseProductQuery = useGetBaseProductQuery({
    variables: {
      guid: baseProductKey || ""
      },
    skip: !exists(baseProductKey)
  });

  const gpmProductQuery = useProductDetailsByKeyQuery({
    variables: { baseProductKey: baseProductKey || "", productKey: productKey || "", claimCheckKey: claimCheckKey || "" },
    fetchPolicy: "cache-and-network",
    skip: !exists(productKey) || !exists(baseProductKey)    
  });

  useDispatchErrors([
    { error: qResult.error, message: messages.searchProductsFailure },
    { error: aqResult.error, message: messages.searchProductsFailure },
    { error: gpmPatientQuery.error, message: messages.fetchLinkedPatientQueryFailure },
    { error: gpmClientQuery.error, message: messages.fetchLinkedClientQueryFailure },
    { error: gpmBaseProductQuery.error, message: messages.fetchBaseProductQueryFailure },
    { error: gpmProductQuery.error, message: messages.fetchProductDetailsQueryFailure }
  ]);

  useEffect(() => {
    if (!gpmBaseProductQuery.loading && gpmBaseProductQuery.data?.getBaseProduct) {
      setIsCompoundProduct(gpmBaseProductQuery?.data?.getBaseProduct?.isCompound);
    }
  }, [setIsCompoundProduct, gpmBaseProductQuery]);

  if (error) {
    console.error(error);
    return <WarningCard reason={"Unknown error."} />;
  }

  const maybePimsType = props.claimCheck?.pims || data?.retrieveRxByGuidOrClaimCheckGuid?.pimsType || "";

  // set session. Flow (Linked or Unlinked) and PimsType

  if (!claimCheckKey || claimCheckKey === "null") {
    return <WarningCard reason={"No Claim Check GUID present on URL"} />;
  }
  if (loading || unlinkedProductMutationLoading) {
    return <Loading fullPage />;
  }
  if (exists(selectedProduct)) {
    if (props.claimCheck?.eventType === "pendingRxEvent" || data?.retrieveRxByGuidOrClaimCheckGuid?.approvalKey != null || data?.retrieveRxByGuidOrClaimCheckGuid?.renewalKey != null) {
      return <Redirect push to={`/review-rx/${claimCheckKey}/${prescriptionGuid}`} />;
    } 
    else {
      return <Redirect push to={`/review-rx/${claimCheckKey}`} />;
    }
  }

  if (gpmPatientQuery.loading || gpmClientQuery.loading || gpmProductQuery.loading || gpmBaseProductQuery.loading) {
    return <Loading fullPage />;
  }
  if (!props.claimCheck) {
    return <WarningCard reason={"Error fetching claim check"} />;
  }
  
  if ((props.claimCheck?.eventType === "pendingRxEvent" || data?.retrieveRxByGuidOrClaimCheckGuid?.approvalKey != null || data?.retrieveRxByGuidOrClaimCheckGuid?.renewalKey != null)  && !prescriptionGuid) {
    return <WarningCard reason={"No Prescription GUID present on URL"} />;
  }

  if (data?.retrieveRxByGuidOrClaimCheckGuid?.rxHasBeenProcessed) {
    history.push({pathname: `/rx-success/${claimCheckKey}/${prescriptionGuid}`, state: "HAS_PROCESS_BY_ANOTHER_USER"});
  }

  const client = props.claimCheck?.payload.client;
  const patient = props.claimCheck?.payload.patient;
  const gpmPatient = gpmPatientQuery?.data?.getPatient;
  const gpmClient = gpmClientQuery?.data?.retrieveClientByGuid;
  const gpmProductDetail = gpmProductQuery?.data?.getProduct;
  const gpmBaseProductDetail = gpmBaseProductQuery?.data;
 
  const pimsWeightWithUnits = fetchPatientWeight(patient?.currentWeight, patient?.currentWeightUnit);
  const gpmWeightWithUnits = fetchPatientWeight(gpmPatient?.weight, gpmPatient?.weightUnitCode);
  const collapsedText = fetchCollapsedText(gpmWeightWithUnits, gpmClient?.givenName, gpmClient?.familyName);
  const pimsClientName = fetchClientName(null, client?.firstName, client?.lastName);
  const gpmClientName = fetchClientName(gpmPatient?.clientName || null, gpmClient?.givenName, gpmClient?.familyName);
  const showGpmValues = gpmPatient !== undefined || gpmClient !== undefined;
  const patientSpeciesString = isValidSpeciesType(gpmPatient?.speciesCode || "")
      ? gpmPatient?.speciesCode
      : ( isValidSpeciesType(patient?.speciesDescription || "") ? patient?.speciesDescription : "" );

  const initialSearchString = (props.claimCheck?.eventType === "pendingRxEvent" || data?.retrieveRxByGuidOrClaimCheckGuid?.approvalKey != null || data?.retrieveRxByGuidOrClaimCheckGuid?.renewalKey != null)  ? (gpmBaseProductDetail?.getBaseProduct?.familyCode.name || "") : "";
  const disableSearch = (props.claimCheck?.eventType === "pendingRxEvent" || data?.retrieveRxByGuidOrClaimCheckGuid?.approvalKey != null || data?.retrieveRxByGuidOrClaimCheckGuid?.renewalKey != null)  ? true : false;
  const loggedInUsername = props.user?.username;
     
  const patientProps = {
    headerIcon: (
      <SpeciesPicker species={gpmPatient?.speciesCode || patient?.speciesDescription || ""} useThumbnail={false} />
    ),
    headerText: patient?.name || gpmPatient?.name  || "Unnamed Patient",
    collapsed,
    setCollapsed,
    collapsedText,
    comparison: {
      noMatchText: messages.noMatchText,
      noMatchHeader: messages.noMatchHeader,
      leftColumnIcon: <PimsLogo pimsType={maybePimsType} />,
      cvetMatchFound: showGpmValues,
      linkSelectionMade: false,
      showPimsData: exists(patient),
      continue: () => {},
      comparisonGrid: [
        { label: commonMessages.name, leftValue: patient?.name, rightValue: gpmPatient?.name },
        { label: commonMessages.client, leftValue: pimsClientName, rightValue: gpmClientName },
        { label: commonMessages.email, leftValue: client?.email, rightValue: gpmClient?.emailAddress },
        { label: commonMessages.phone, leftValue: client?.phoneNumber, rightValue: gpmClient?.phoneNumber },
        { label: commonMessages.species, leftValue: patient?.speciesDescription, rightValue: gpmPatient?.speciesCode.toLowerCase().replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function(key, p1) { return key.toUpperCase(); }) },
        { label: commonMessages.weight, leftValue: pimsWeightWithUnits, rightValue: getPatientWeightRightValue() },
        { label: commonMessages.sex, leftValue: patient?.gender, rightValue: gpmPatient?.sexCode },
        { label: commonMessages.dob, leftValue: patient?.dateOfBirth, rightValue: gpmPatient?.dateOfBirth },
      ],
    },
  };

  const isNormalizedStrength = (products: SearchGPMProductResult[]) => {
    return (
      r.all(({ normalizedStrengthWithUOM }) => !normalizedStrengthWithUOM?.includes("null null"), products)
    );
  }

  const gridHeaders = isCompoundProduct && isNormalizedStrength(qResult.data?.searchProducts || []) ? getCompoundProductGridHeaders(location, (p) => setProductInfoDialog(p)) : getGridHeaders(location, (p) => setProductInfoDialog(p));
  
  const productInfoModal = () => {
    if (productInfoDialog != null)
      return <Modal baseProductKey={productInfoDialog.baseProductKey} productKey={productInfoDialog.key} claimCheckKey={claimCheckKey} onClose={() => setProductInfoDialog(null)}/>
    return null;
  }

  const updatePatientWeightModal = () => {
    if (showUpdatePatientWeightModal && gpmPatient)
      return <UpdatePatientWeightModal 
        patient={gpmPatient} 
        onClose={() => setShowUpdatePatientWeightModal(false)}
        onFinish={() => {
          dispatch(
            addToast({
              message: messages.updateWeightSuccessful,
              id: generateToastID(),
              kind: "success",
            }),
          );
          setShowUpdatePatientWeightModal(false);
          gpmPatientQuery.refetch();
        }}
        onError={(err) => {
          dispatch(
            addToast({
              message: messages.updateWeightFailure,
              id: generateToastID(),
              kind: "error",
            }),
          );
        }}
      />;
    return null;
  };
    
  if (gpmBaseProductDetail && gpmPatient) {
    if (searchStep === "pre") {
      setSearchCriteria({
        query: gpmBaseProductDetail?.getBaseProduct?.familyCode.name || "",
        filter: {
          isCompound: isCompoundProduct,
          categoryCode: "",
          treatmentCategoryCode: "",
          speciesCode: gpmPatient.speciesCode,
          brandCode: "",
          primaryManufacturerCode: "",
          catalogCategoryCode: "",
          baseProductStatus: "",
          familyCode: gpmBaseProductDetail?.getBaseProduct?.familyCode?.familyCode || "",
          weight: gpmPatient.weight || "",
        },
      });
      setSearchStep("automated");
    }
  }

  return (
    <Container>
      {updatePatientWeightModal()}
      {productInfoModal()}
      <DescriptionComponent {...messages} pimsType={maybePimsType} username={loggedInUsername || ""} claimCheckKey={claimCheckKey} />
      <CollapsableCard {...patientProps} />
      <SearchGrid<SearchGPMProductResult>
        gridSchema={gridHeaders}
        isCompoundProduct={(isCompoundProduct && isNormalizedStrength(qResult.data?.searchProducts || [])) || false}
        setIsCompoundProduct={setIsCompoundProduct}
        searchResults={getResultsProp(qResult, searchStep)}
        commitSearch={(newCriteria: any) => {
          setSearchCriteria(newCriteria);
          setSearchStep("user");
        }}
        initialSearchString={initialSearchString}
        initialSearchBaseProduct={gpmBaseProductDetail?.getBaseProduct?.baseProductKey}
        disableSearch={disableSearch}
        autocomplete={!disableSearch}
        preventSearchWhenPressingEnterKeyForAutocomplete={!disableSearch}
        autocompleteSuggestions={getAutoCompleteResultsProp(aqResult, searchStep)}
        commitAutoCompleteSearch={(newCriteria: any) => {
          setAutoCompleteSearchCriteria(newCriteria);
        }}
        selectResult={(product) => {
          updateRxWithUnlinkedProduct({ variables: {baseProductKey: product.baseProductKey, productKey: product.key, claimCheckKey: claimCheckKey, pimsPrescriptionKey: prescriptionGuid || null} })
            .then(() => {
              setSelectedProduct(product);
              setCollapsed(false);
            })
            .catch((e) => {
              dispatch(
                addToast({
                  message: messages.updateRxWithUnlinkedProductFailure,
                  id: generateToastID(),
                  kind: "error"
                })
              );
            });
        }}
        guidRowSelected={selectedProduct && nullMap(r.prop("key"), selectedProduct)}
        filters={{
          speciesDisabled: !!(
            data?.retrieveRxByGuidOrClaimCheckGuid?.approvalKey || data?.retrieveRxByGuidOrClaimCheckGuid?.renewalKey
          ),
          displayType: "column",
          patientWeight: gpmWeightWithUnits,
          patientSpecies: patientSpeciesString,
          familyCode: gpmProductDetail?.familyCode || gpmBaseProductDetail?.getBaseProduct?.familyCode.familyCode
        }}
      />
    </Container>
  );
};

export default ProductUnlinked;
