import * as H from "history";
import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import PimsFaqPicker from "../components/FaqModal/PimsFaqPicker";
import HiddenBuildNumber from "../components/HiddenBuildNumber/index";
import { ImportPatientModal } from "../components/ImportPatientModal/ImportPatientModal";
import UserNotSetUpWarning from "../components/UserNotSetUp";
import NoPermissionsWarning from "../components/NoPermissions";
import ForbiddenWarning from "../components/Forbidden";
import PimsRxHeader from "../components/PimsRxHeader/PimsRxHeader";
import ClientLinking from "../containers/ClientLinking";
import Login from "../containers/Login";
import PatientImport from "../containers/PatientImport/index";
import { PatientLinking } from "../containers/PatientLinking";
import { ProductLinking } from "../containers/ProductLinking";
import ProductUnlinked from "../containers/ProductUnlinked/index";
import ReviewRx from "../containers/ReviewRx";
import PendingRx from "../containers/PendingRx";
import RxSuccess from "../containers/RxSuccess";
import PendingRxList from "../containers/PendingRxList";
import ToastNotificationListener from "../containers/ToastNotification/ToastNotificationListener";
import PrivateRoute from "../PrivateRoute";
import GlobalStyle from "./global-styles";
import { AppContainer } from "./styles";
import { useAuth0 } from "@auth0/auth0-react";
import { clearContextKey } from "../domUtils";

require(`../scripts/pendoSnippet`);

function App() {
  const location = useLocation<H.History & { background?: H.Location<H.History> | undefined }>();
  const background = location.state && location.state.background;
  const { user, isLoading: isAuth0Loading, isAuthenticated, loginWithRedirect } = useAuth0();
  const pimsType = new URLSearchParams(location.search).get("pt");
  const isPulseIntegration = pimsType?.includes("evet");

  useEffect(() => {
    if (!isAuth0Loading && (!user || !isAuthenticated) && isPulseIntegration) {
      loginWithRedirect({
        authorizationParams: { connection: "CovetrusUsersDB" },
        appState: {
          returnTo: window.location.pathname + window.location.search,
        },
      });
    }
  }, [isAuth0Loading, user, isAuthenticated]);
  
  useEffect(() => {
    if (!isPulseIntegration) return;
    window.addEventListener("pagehide", clearContextKey);
    return () => window.removeEventListener("pagehide", clearContextKey);
  }, []);

  if (!isAuth0Loading && (!user || !isAuthenticated) && isPulseIntegration) return <></>;

  return (
    <>
      <GlobalStyle />
      <ToastNotificationListener />
      <AppContainer>
        <PimsRxHeader showHelp showBetaFeedback={false} location={location} />
        <Switch location={background || location}>
          <Route path={"/login"}><Login /></Route>
          <PrivateRoute path={["/patient-linking/:claimCheckKey", "/patient-linking"]} children={<PatientLinking />} />
          <PrivateRoute path={["/product-linking/:claimCheckKey", "/product-linking"]} children={<ProductLinking />} />
          <PrivateRoute path={["/product-search/:claimCheckKey/:prescriptionGuid?", "/product-search"]} children={<ProductUnlinked />} />
          <PrivateRoute path={["/review-rx/:claimCheckKey/:prescriptionGuid?", "/review-rx"]} children={<ReviewRx />} />
          <PrivateRoute path={["/pending-rx/:claimCheckKey/:workflowType/:pendingKey", "/pending-rx"]} children={<PendingRx />} />
          <PrivateRoute path={["/client-linking/:claimCheckKey", "/client-linking"]} children={<ClientLinking />} />
          <PrivateRoute path={["/patient-import/:claimCheckKey", "/patient-import"]} children={<PatientImport />} />
          <PrivateRoute path={["/pending-rx-list/:claimCheckKey", "/pending-rx-list"]} children={<PendingRxList />} />
          <PrivateRoute path={"/rx-success/:claimCheckKey/:prescriptionGuid?"} children={<RxSuccess />} />
          <Route path={"/user-not-set-up"} children={<UserNotSetUpWarning />}></Route>
          <Route path={"/no-permissions"} children={<NoPermissionsWarning />}></Route>
          <Route path={"/forbidden"} children={<ForbiddenWarning />}></Route>
        </Switch>

        {background && (
          <Switch>
            <Route path={"/patient-choose-flow/:claimCheckKey"}>
              <ImportPatientModal returnTo={background.pathname} />
            </Route>
            <Route path={"/faq"}>
              <PimsFaqPicker returnTo={background.pathname} />
            </Route>
          </Switch>
        )}
        <HiddenBuildNumber />
      </AppContainer>
    </>
  );
}

export default App;
