import React from "react";

export const InfoCircled = () => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title></title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="translate(-696.000000, -347.000000)" fill="#4D5051" fillRule="nonzero">
        <path
          fill="#4D5051"
          d="M706.8,353.514196 L709.2,353.514196 L709.2,355.914196 L706.8,355.914196 L706.8,353.514196 Z M706.8,358.314196 L709.2,358.314196 L709.2,365.514196 L706.8,365.514196 L706.8,358.314196 Z M708,347.514196 C701.376,347.514196 696,352.890196 696,359.514196 C696,366.138196 701.376,371.514196 708,371.514196 C714.624,371.514196 720,366.138196 720,359.514196 C720,352.890196 714.624,347.514196 708,347.514196 Z M708,369.114196 C702.708,369.114196 698.4,364.806196 698.4,359.514196 C698.4,354.222196 702.708,349.914196 708,349.914196 C713.292,349.914196 717.6,354.222196 717.6,359.514196 C717.6,364.806196 713.292,369.114196 708,369.114196 Z"
          id="info-circled"
        ></path>
      </g>
    </g>
  </svg>
);

export const InfoCircledThin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M12.35 2C18.066 2 22.7 6.634 22.7 12.35c0 5.716-4.634 10.35-10.35 10.35C6.634 22.7 2 18.066 2 12.35 2 6.634 6.634 2 12.35 2zm0 1.5c-4.887 0-8.85 3.963-8.85 8.85 0 4.888 3.962 8.85 8.85 8.85s8.85-3.962 8.85-8.85c0-4.887-3.963-8.85-8.85-8.85zm0 6.7c.245 0 .45.176.492.41l.008.09v6.946c0 .277-.224.5-.5.5-.245 0-.45-.176-.492-.41l-.008-.09V10.7c0-.276.224-.5.5-.5zm0-3.646c.506 0 .917.41.917.916 0 .507-.41.917-.917.917-.506 0-.916-.41-.916-.917 0-.506.41-.916.916-.916z"
      />
    </g>
  </svg>
);
