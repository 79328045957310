import { FormikHandlers, FormikState } from "formik";
import { FinishScriptFormVals } from "../../ScriptForm/types";
import { InitialAOTCvalues, InitialOTCvalues } from "../../OTCScriptForm/types";

export type Props = {
  values?: FinishScriptFormVals | InitialOTCvalues | InitialAOTCvalues;
  comparisonGrid: FlaggedGridValues[];
  handleChange: FormikHandlers["handleChange"];
  errors: FormikState<FinishScriptFormVals | InitialOTCvalues>["errors"];
};

export enum SmartScribeErrorType {
  ERROR = 'ERROR',
  WARNING = 'WARNING'
}
export interface FlaggedGridValues {
  label: string;
  expected?: string | null;
  selected?: string | null;
  toolTip?: string;
  errorType?: SmartScribeErrorType;
  required?: boolean;
}

export interface FlaggedIssues {
  weight?: {
    weightMin: number;
    weightMax: number;
    selectedWeight: number;
    weightUnits?: string;
    required: boolean;
  };
  species?: {
    expected?: string;
    selected?: string;
    required: boolean;
  };
  keywords?: {
    expected?: string;
    selected?: string;
    required?: boolean;
  };
  controlledProduct?: {
    expected?: string;
    selected?: string;
    required?: boolean;
  },
  productIsEssentialCopy?: {
    label?: string;
    expected?: string;
    selected?: string;
    tooltip?: string;
    errorType?: SmartScribeErrorType;
  },
  authorizingVetLicense?: {
    label?: string;
    expected?: string;
    selected?: string;
    tooltip?: string;
    errorType?: SmartScribeErrorType;
    required?: boolean;
  },
  authorizingVetDeaLicense?: {
    label?: string;
    expected?: string;
    selected?: string;
    tooltip?: string;
    errorType?: SmartScribeErrorType;
    required?: boolean;
  },
  clientIdentification?: {
    label?: string;
    expected?: string;
    selected?: string;
    tooltip?: string;
    errorType?: SmartScribeErrorType;
    required?: boolean;
  }
}
