import styled from "styled-components/macro";
import { Card } from "../../components/StyledComponents/Card/Card";

export const ImportCard = styled(Card)`
  display: flex;
  flex-flow: column;
  text-align: start;

  margin-top: 20px;
  height: 100%;
  padding: 15px;
  max-width: 464px;

  div {
    padding: 15px 20px 15px 20px;
  }

  div.error {
    padding: initial;
    margin: 20px;

    h2 {
      padding-bottom: 0px;
    }
  }

  h2 {
    padding-bottom: 16px;
    color: ${({ theme }) => theme.primaryTextColor};
  }

  button {
    float: right;
    padding: 10px;
    padding-right: 15px;
    padding-left: 15px;
  }
`;

export const DetailsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    padding: 0px;
    h3 {
      color: #021660;
      align-self: center;
      padding-left: 10px;
      font-size: 20px;
    }
  }
`;

export const InputDiv = styled.div`
  display: block;

  div {
    padding: 10px 0px 5px 0px;
  }

  input {
    font-family: arial;
  }
`;

export const InputInlineDiv = styled.div<{ halfWidth: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  div,
  input#clientFirstName,
  input#clientLastName,
  select#patientSpecies,
  select#patientSex,
  select#patientSexAltered,
  input#patientWeight {
    width: ${(props) => (props.halfWidth ? "47%" : "31%")};
  }
`;

export const InputErrorDiv = styled.div`
  display: block;
  color: ${({ theme }) => theme.errorDarkColor};
  font-size: 12px;

  svg {
    float: right;
  }
`;

export const InputWarningDiv = styled.div`
  display: block;
  color: ${({ theme }) => theme.warningToastColor};
  font-size: 12px;

  svg {
    float: right;
  }
`;


export const CopyText = styled.p`
  span {
    color: #d8193b;
    font-weight: bold;
  }
`;

export const DivNoPadding = styled.div`
  && {
    padding: 0;
  }
`;

export const modalStyleProps = {
  widthStyle: "200px",
  heightStyle: "200px",
  xTopStyle: "9999px",
  xRightStyle: "9999px",
};
