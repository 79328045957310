import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { addToast } from "../../actions/toast";
import { RxIcon } from "../../assets/RxIcon";
import CollapsableCard from "../../components/CollapsableCard/index";
import { DescriptionComponent } from "../../components/DescriptionComponent";
import { SpeciesPicker } from "../../components/Species/Species";
import { Container } from "../../components/StyledComponents/Container/Container";
import Loading from "../../components/StyledComponents/Loading/Loading";
import { WarningCard } from "../../components/WarningCard/WarningCard";
import { generateToastID } from "../../components/Toast/Toast";
import {
  useRemoveRenewalMutation,
  useDeclineApprovalMutation,
  useGetPracticeApprovalQuery,
  useGetPracticeRenewalQuery,
  useRetrieveLinkedClientDetailsQuery,
  useRetrieveLinkedPatientDetailsQuery,
  useCreateDraftRxWithUnlinkedProductMutation,
  useRetrievePracticeUserWithClaimCheckQuery,
  useGetBaseProductQuery
} from "../../data/gql/apiTypes";
import { exists } from "../../util";
import { DELEGATION_TYPE_CODE, PRACTICE_USER_TYPE_CODE } from "../../common/enum";
import messages from "./messages";
import { fetchClientName, fetchCollapsedText, fetchPatientWeight } from "./utils";
import commonMessages from "../../common/messages";
import { ProductCollapseCardValues, WorkFlowType } from "../../components/CollapsableCard/types";
import { PencilIcon } from "../../assets/PencilIcon";
import UpdatePatientWeightModal from "../../components/UpdatePatientWeightModal";
import toastMessages from "../../components/Toast/messages";
import DeclineModal from "../../components/DeclineModal";
import RemoveRenewalModal from "../../components/RemoveRenewalModal";
import { ContainerProps } from "../../PrivateRoute/types";
import ModalWrapper from "../../components/StyledComponents/Modal/Modal";
import { legalCategoryCodeEnum } from "../../components/FinishScriptForm/FinishScriptCard";
import { useDispatchErrors } from "../../hooks/useDispatchErrors";
import { TreatmentType } from "../../components/FinishScriptForm/AcknowledgeErrors/types";

const PendingRx = (props: ContainerProps) => {
  const { claimCheckKey, workflowType, pendingKey } = useParams<{ claimCheckKey: string, workflowType: string, pendingKey: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [patientCollapsed, setPatientCollapsed] = useState(true);
  const [productCollapsed, setProductCollapsed] = useState(false);
  const [showUpdatePatientWeightModal, setShowUpdatePatientWeightModal] = useState(false);
  const [workflowStatus, setWorkflowStatus] = useState("ACTION_NEEDED");
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);

  const { loading, error, data } = useGetPracticeApprovalQuery({
    variables: { approvableKey: pendingKey! },
    fetchPolicy: "cache-first",
    skip: workflowType !== WorkFlowType.APPROVALS
  });

  const { loading: loadingRenewals, error: errorRenewal, data: dataRenewals } = useGetPracticeRenewalQuery
    ({
      variables: { renewalKey: pendingKey! },
      fetchPolicy: "cache-first",
      skip: workflowType !== WorkFlowType.RENEWALS
    });

  const baseProductKey = data?.getApproval?.baseProductKey || dataRenewals?.getRenewal?.baseProductKey || '';
  const { loading: loadingBaseProduct, error: errorBaseProduct, data: baseProduct } = useGetBaseProductQuery
    ({
      variables: { guid: baseProductKey },
      skip: !exists(data?.getApproval?.baseProductKey || dataRenewals?.getRenewal?.baseProductKey)
    });

  const baseProductTreatmentCategories = baseProduct?.getBaseProduct?.treatmentCategories || null;

  const user = useRetrievePracticeUserWithClaimCheckQuery({
    variables: {
      claimCheckKey,
    },
  });

  const [declinePendingApproval, { loading: declineApprovalLoading }] = useDeclineApprovalMutation();
  const [createDraftRx, { loading: createDraftRxLoading }] = useCreateDraftRxWithUnlinkedProductMutation();
  const [removeRenewal] = useRemoveRenewalMutation();

  const gpmPatientQuery = useRetrieveLinkedPatientDetailsQuery({
    variables: { key: data?.getApproval?.patientKey || dataRenewals?.getRenewal?.patientKey || "" },
    skip: !exists(data?.getApproval?.patientKey || dataRenewals?.getRenewal?.patientKey),
  });
  const gpmClientQuery = useRetrieveLinkedClientDetailsQuery({
    variables: {
      guid:
        data?.getApproval?.clientKey || dataRenewals?.getRenewal?.clientKey || gpmPatientQuery?.data?.getPatient?.clientKey || "",
    },
    skip:
      !exists(data?.getApproval?.clientKey || dataRenewals?.getRenewal?.clientKey) &&
      !exists(gpmPatientQuery?.data?.getPatient?.clientKey),
  });

  useDispatchErrors([
    { error: error, message: messages.fetchApprovalsFailure },
    { error: errorRenewal, message: messages.fetchRenewalsFailure },
    { error: errorBaseProduct, message: messages.fetchBaseProductFailure }
  ]);

  if (!props.claimCheck) return <WarningCard reason={"Error fetching claim check"} />;
  if (!claimCheckKey) return <WarningCard reason={"No Claim Check GUID present on URL"} />;
  // if (!prescriptionGuid) return <WarningCard reason={"No Prescription GUID present on URL"} />;
  if (
    loading ||
    loadingRenewals ||
    loadingBaseProduct ||
    gpmPatientQuery.loading ||
    gpmClientQuery.loading ||
    declineApprovalLoading ||
    createDraftRxLoading ||
    user.loading
  ) {
    return <Loading fullPage />;
  }
  if (!user.data || user.error) {
    return <WarningCard reason={"Unable to fetch practice user"} />;
  }
  if ((!data?.getApproval && !dataRenewals?.getRenewal) || error) {
    console.error(error);
    return <WarningCard reason={"Unknown error."} />;
  }
  if (!data?.getApproval?.baseProductKey && !dataRenewals?.getRenewal?.baseProductKey) {
    return <WarningCard reason={"No product was found."} />;
  }
  if (!gpmPatientQuery?.data?.getPatient) {
    return <WarningCard reason={"No linked patient was found."} />;
  }
  if (!gpmClientQuery?.data?.retrieveClientByGuid) {
    return <WarningCard reason={"No linked client was found."} />;
  }

  const getPatientWeightRightValue = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', flexFlow: 'row' }} title={gpmWeightWithUnits || ''}>
        {gpmWeightWithUnits}
        <div style={{ marginRight: 20 }}>
          <div style={{ position: 'absolute', whiteSpace: 'normal', flexDirection: 'row' }}>
            <div style={{ cursor: 'pointer' }} onClick={() => setShowUpdatePatientWeightModal(true)}>
              <PencilIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const userCanEditRx = true;
  const userCanDeclineRx = true;
  const BasicDelegateCode = DELEGATION_TYPE_CODE.US_OTC_AOTC_PREVENTATIVE;
  const AdvancedDelegateCode = DELEGATION_TYPE_CODE.US_OTC_AOTC_PREVENTATIVE_RX;
  const isVet = user?.data?.retrievePracticeUser?.practiceUserTypeCode === PRACTICE_USER_TYPE_CODE.VETERINARIAN;
  const isVetWithValidDeaLicense = isVet && user?.data?.retrievePracticeUser?.isDeaLicenceActive;
  const prescribingVetKey = data?.getApproval?.prescribingVetKey || dataRenewals?.getRenewal?.prescribingVetKey;

  const delegateAlignsWithPrescribingVet = (delegateLevel: string) => user &&
    user.data?.retrievePracticeUser?.vetDelegateLevels?.some(x =>
      x.delegationTypeCode === delegateLevel &&
      x.veterinarianKey === prescribingVetKey)

  // isBasicDelegate = a basic delegate for some Vet and (if there is a prescribingVetKey, then it must align with the grant) 
  const isBasicDelegate = !isVet && user && user.data?.retrievePracticeUser?.vetDelegateLevels?.some(
    (vetDelegateLevel) => vetDelegateLevel?.delegationTypeCode === BasicDelegateCode)
    && (!prescribingVetKey || delegateAlignsWithPrescribingVet(BasicDelegateCode));

  // isAdvancedDelegate = an advanced delegate for some Vet and (if there is a prescribingVetKey, then it must align with the grant) 
  const isAdvancedDelegate = !isVet && user && user.data?.retrievePracticeUser?.vetDelegateLevels?.some(
    (vetDelegateLevel) => vetDelegateLevel?.delegationTypeCode === AdvancedDelegateCode)
    && (!prescribingVetKey || delegateAlignsWithPrescribingVet(AdvancedDelegateCode));

  const treatmentCategoriesSearch = () => {
    if (baseProductTreatmentCategories?.some(x => x === TreatmentType.DIET)) return true;
    if (baseProductTreatmentCategories?.some(x => x === TreatmentType.PREVENTATIVE)) return true;
    return false;
  }

  const legalCategoryCode = dataRenewals?.getRenewal?.legalCategoryCode === legalCategoryCodeEnum.RX || data?.getApproval?.legalCategoryCode === legalCategoryCodeEnum.RX;
  let warningMessage: any = null;

  if (isBasicDelegate && !isAdvancedDelegate && ((legalCategoryCode && !treatmentCategoriesSearch()) || data?.getApproval?.isControlled || dataRenewals?.getRenewal?.isControlled)) {
    warningMessage = <div>You have <b>Basic Delegate</b> permissions and are not authorized to prescribe a medication for a Veterinarian at your practice.</div>;
  } else {
    if (data?.getApproval?.isControlled || dataRenewals?.getRenewal?.isControlled) {
      //if approvable does not have a vet check to see if user is a vet with dea license
      if ((!isVetWithValidDeaLicense && !isAdvancedDelegate) && data?.getApproval?.prescribingVetKey === null) {
        warningMessage = <div>You must be the prescribing <b>Veterinarian</b> with an active and valid <b>DEA license</b> or an <b>Advanced Delegate</b> to create or modify this prescription for a controlled substance product</div>;
      }
      //check to see if user is a vet with a DEA License and is the prescribing vet
      if ((!isVetWithValidDeaLicense && !isAdvancedDelegate) && (data?.getApproval?.prescribingVetKey !== user.data?.retrievePracticeUser?.veterinarianKey
        && dataRenewals?.getRenewal?.prescribingVetKey !== user.data?.retrievePracticeUser?.veterinarianKey && data?.getApproval?.prescribingVetKey !== null)) {
        warningMessage = <div>You must be the prescribing <b>Veterinarian</b> with an active and valid <b>DEA license</b> or an <b>Advanced Delegate</b> to create or modify this prescription for a controlled substance product</div>;
      }
    }
  }

  const getWorkflowType = () => {
    if (workflowType === WorkFlowType.APPROVALS) {
      return WorkFlowType.APPROVALS;
    }
    return WorkFlowType.RENEWALS;
  }

  const handleDecline = (declineReason: string, clientNote: string, declineVet: string) => {
    setShowDeclineModal(false);
    if (claimCheckKey && data) {
      declinePendingApproval({
        variables: {
          declineApproval: {
            approvableKey: pendingKey!,
            declineReason: declineReason,
            noteToClient: clientNote,
            declineVet: declineVet,
            authorizingVet: data.getApproval?.prescribingVetKey
          }
        }
      }).then(decline => {
        if (decline.data?.declineApproval) {
          createDraftRx({
            variables: {
              createRx: {
                baseProductKey: data.getApproval?.baseProductKey,
                productKey: "",
                claimCheckKey: claimCheckKey,
                installationKey: user.data?.retrieveClaimCheckByGuid?.installationKey,
                patientKey: data.getApproval?.patientKey,
                clientKey: data.getApproval?.clientKey,
                approvalKey: pendingKey
              }
            }
          }).then(result => {
            history.push({ pathname: `/rx-success/${claimCheckKey}/${result.data?.createDraftRxWithUnlinkedProduct}`, state: "DECLINE APPROVALS" });
          }).catch(_ => {
            dispatch(
              addToast({
                message: toastMessages.createOrUpdateDraftPrescriptionFailure,
                id: generateToastID(),
                kind: "error",
              }),
            );
          });
        } else {
          dispatch(
            addToast({
              message: toastMessages.declinePrescriptionFailure,
              id: generateToastID(),
              kind: "error",
            }),
          );
        }
      }).catch(err => {
        if (err && err.toString().includes("Approvable request with status code PENDING can be declined")) {
          history.push({
            pathname: `/rx-success/${claimCheckKey}`,
            state: "HAS_PROCESS_BY_ANOTHER_USER"
          });
        } else {
          dispatch(
            addToast({
              message: toastMessages.declinePrescriptionFailure,
              id: generateToastID(),
              kind: "error",
            }),
          );
        }
      });
    }
  }

  const handleRemoveRx = () => {
    if (pendingKey) {
      removeRenewal({
        variables: {
          renewalKey: pendingKey
        }
      }).then(data => {
        createDraftRx({
          variables: {
            createRx: {
              baseProductKey: dataRenewals?.getRenewal?.baseProductKey,
              productKey: "",
              claimCheckKey: claimCheckKey,
              installationKey: user.data?.retrieveClaimCheckByGuid?.installationKey,
              patientKey: dataRenewals?.getRenewal?.patientKey,
              clientKey: dataRenewals?.getRenewal?.clientKey,
              renewalKey: pendingKey
            }
          }
        }).then(data => {
          history.push({ pathname: `/rx-success/${claimCheckKey}/${data.data?.createDraftRxWithUnlinkedProduct}`, state: "REMOVE" });
        }).catch(_ => {
          dispatch(
            addToast({
              message: toastMessages.createOrUpdateDraftPrescriptionFailure,
              id: generateToastID(),
              kind: "error",
            }),
          );
        });
      }).catch(err => {
        dispatch(
          addToast({
            message: toastMessages.removeRenewalFailure,
            id: generateToastID(),
            kind: "error",
          }),
        );
      })
    }
  }

  //TODO: waiting on newtron team to update child/base lookup.  Hard coding for now:
  const handlePrimaryButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    createDraftRx({
      variables: {
        createRx: {
          baseProductKey: data?.getApproval?.baseProductKey || dataRenewals?.getRenewal?.baseProductKey,
          productKey: "",
          claimCheckKey: claimCheckKey,
          installationKey: user.data?.retrieveClaimCheckByGuid?.installationKey,
          patientKey: data?.getApproval?.patientKey || dataRenewals?.getRenewal?.patientKey,
          clientKey: data?.getApproval?.clientKey || dataRenewals?.getRenewal?.clientKey,
          renewalKey: workflowType === WorkFlowType.RENEWALS ? dataRenewals?.getRenewal?.renewableKey : null,
          approvalKey: workflowType === WorkFlowType.APPROVALS ? data?.getApproval?.approvableKey : null

        }
      }
    }).then(result => {
      if (result.data?.createDraftRxWithUnlinkedProduct) {
        history.push(`/review-rx/${claimCheckKey}/${result.data?.createDraftRxWithUnlinkedProduct}`);
      }
    }).catch(_ => {
      dispatch(
        addToast({
          message: toastMessages.createOrUpdateDraftPrescriptionFailure,
          id: generateToastID(),
          kind: "error",
        }),
      );
    });
  }

  const handleSecondaryButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    createDraftRx({
      variables: {
        createRx: {
          baseProductKey: data?.getApproval?.baseProductKey || dataRenewals?.getRenewal?.baseProductKey,
          productKey: "",
          claimCheckKey: claimCheckKey,
          installationKey: user.data?.retrieveClaimCheckByGuid?.installationKey,
          patientKey: data?.getApproval?.patientKey || dataRenewals?.getRenewal?.patientKey,
          clientKey: data?.getApproval?.clientKey || dataRenewals?.getRenewal?.clientKey,
          renewalKey: workflowType === WorkFlowType.RENEWALS ? dataRenewals?.getRenewal?.renewableKey : null,
          approvalKey: workflowType === WorkFlowType.APPROVALS ? data?.getApproval?.approvableKey : null
        }
      }
    }).then(result => {
      if (result.data?.createDraftRxWithUnlinkedProduct) {
        history.push(`/product-search/${claimCheckKey}/${result.data?.createDraftRxWithUnlinkedProduct}`);
      }
    }).catch(_ => {
      dispatch(
        addToast({
          message: toastMessages.createOrUpdateDraftPrescriptionFailure,
          id: generateToastID(),
          kind: "error",
        }),
      );
    });
  }

  const comparisonProductQty = data?.getApproval?.productQty || 1;
  const comparisonTotalQty = data?.getApproval?.qtyOrdered || dataRenewals?.getRenewal?.qtyPerFill || 1;
  const comparisonPackageQty = data?.getApproval ? comparisonTotalQty / comparisonProductQty : 1;
  const comparisonGrid =
    [
      { label: commonMessages.name, rightValue: data?.getApproval?.productName || dataRenewals?.getRenewal?.productName },
      { label: commonMessages.strength, rightValue: data?.getApproval?.productStrength || dataRenewals?.getRenewal?.productStrength },
      { label: commonMessages.form, rightValue: data?.getApproval?.productUOM || dataRenewals?.getRenewal?.productUOM },
      { label: commonMessages.packageQty, rightValue: comparisonPackageQty.toString() },
      { label: commonMessages.totalQuantity, rightValue: comparisonTotalQty.toString() },
    ]

  const productProps: ProductCollapseCardValues = {
    headerIcon: <RxIcon />,
    collapsed: productCollapsed,
    headerText: data?.getApproval?.productName || dataRenewals?.getRenewal?.productName || "",
    setCollapsed: setProductCollapsed,
    comparison: {
      cvetMatchFound: true,
      linkSelectionMade: false,
      showPimsData: false,
      continue: () => { },
      comparisonGrid: comparisonGrid,
    },
    actions: {
      workflowType: getWorkflowType(),
      key: "",
      workflowStatus,
      setWorkflowStatus,
      disablePrimaryButton: !userCanEditRx,
      disableSecondaryButton: !userCanEditRx,
      disableDeclineButton: !userCanDeclineRx,
      handlePrimaryButtonClick: warningMessage ? () => setShowWarningModal(true) : handlePrimaryButtonClick,
      handleSecondaryButtonClick: warningMessage ? () => setShowWarningModal(true) : handleSecondaryButtonClick,
      handleDeclineButtonClick: () => warningMessage ? setShowWarningModal(true) : setShowDeclineModal(true),
    }
  };

  const gpmClient = gpmClientQuery?.data?.retrieveClientByGuid;
  const gpmPatient = gpmPatientQuery?.data?.getPatient;
  const gpmWeightWithUnits = fetchPatientWeight(gpmPatient?.weight, gpmPatient?.weightUnitCode);
  const collapsedText = fetchCollapsedText(gpmWeightWithUnits, gpmClient?.givenName, gpmClient?.familyName);
  const gpmClientName = fetchClientName(gpmPatient?.clientName, gpmClient?.givenName, gpmClient?.familyName);
  const loggedInUsername = props.user?.username;

  const patientProps = {
    headerIcon: <SpeciesPicker species={gpmPatient?.speciesCode || ""} useThumbnail={false} />,
    collapsed: patientCollapsed,
    setCollapsed: setPatientCollapsed,
    headerText: gpmPatient?.name,
    collapsedText,
    comparison: {
      cvetMatchFound: true,
      linkSelectionMade: false,
      showPimsData: false,
      continue: () => { },
      comparisonGrid: [
        { label: commonMessages.name, rightValue: gpmPatient?.name },
        { label: commonMessages.client, rightValue: gpmClientName },
        { label: commonMessages.email, rightValue: gpmClient?.emailAddress },
        { label: commonMessages.phone, rightValue: gpmClient?.phoneNumber },
        { label: commonMessages.species, rightValue: gpmPatient?.speciesCode.toLowerCase().replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function (key, p1) { return key.toUpperCase(); }) },
        { label: commonMessages.weight, rightValue: getPatientWeightRightValue() },
        { label: commonMessages.sex, rightValue: gpmPatient?.sexCode },
        { label: commonMessages.dob, rightValue: gpmPatient?.dateOfBirth },
      ],
    },
  };

  const updatePatientWeightModal = () => {
    if (showUpdatePatientWeightModal && gpmPatient)
      return <UpdatePatientWeightModal
        patient={gpmPatient}
        onClose={() => setShowUpdatePatientWeightModal(false)}
        onFinish={() => {
          dispatch(
            addToast({
              message: messages.updateWeightSuccessful,
              id: generateToastID(),
              kind: "success",
            }),
          );
          setShowUpdatePatientWeightModal(false);
          gpmPatientQuery.refetch();
        }}
        onError={(err) => {
          dispatch(
            addToast({
              message: messages.updateWeightFailure,
              id: generateToastID(),
              kind: "error",
            }),
          );
        }}
      />;
    return null;
  };

  const warningCardButtonProps = () => ({
    show: { primary: true },
    text: { primary: "close" },
    onClick: { primary: () => setShowWarningModal(false) },
  });

  const warningModalOnBasicPerms = () => {
    if (showWarningModal) {
      const buttonProps = warningCardButtonProps();
      return (
        <ModalWrapper>
          <WarningCard
            buttons={buttonProps}
            reason={
              <>
                {warningMessage}
              </>
            }
          />
        </ModalWrapper>
      );
    } else {
      return null;
    }
  };

  const getInitialVet = () => {
    if (data?.getApproval?.prescribingVetKey != null) {
      return data?.getApproval?.prescribingVetKey
    }
    else {
      if (user.data?.retrievePracticeUser?.veterinarianKey != null) {
        return user.data?.retrievePracticeUser?.veterinarianKey;
      }
    }
    return "";
  }

  const declineModal = () => {
    if (showDeclineModal) {
      const workflowType = getWorkflowType();
      if (workflowType === WorkFlowType.APPROVALS) {
        return <DeclineModal
          onSubmit={(reason, note, declineVet) => handleDecline(reason, note, declineVet)}
          onClose={() => setShowDeclineModal(false)}
          intialVet={(getInitialVet())}
          approval={(data?.getApproval!)}
          user={(user.data?.retrievePracticeUser!)}

        />
      }
      else if (workflowType === WorkFlowType.RENEWALS) {
        return <RemoveRenewalModal
          onSubmit={() => handleRemoveRx()}
          onClose={() => setShowDeclineModal(false)}
        />
      }
    }
    return null;
  }


  return (
    <Container>
      {updatePatientWeightModal()}
      {warningModalOnBasicPerms()}
      {declineModal()}
      <DescriptionComponent {...messages} username={loggedInUsername || ""} claimCheckKey={claimCheckKey} />
      <CollapsableCard {...patientProps} />
      <CollapsableCard {...productProps} />
    </Container>
  );
};

export default PendingRx;
