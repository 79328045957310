import commonMessages from "../../common/messages";
export default {
  headerText: "Link Patient & Client",
  descriptionText: (pimsType: string) =>
    `You’ll only have to do this once so we can accurately fulfill this prescription. You can also update patient information in Rx Management from ${
      pimsType || "your PIMS"
    }`,
  noMatchText: "Search for a patient below to get started.",

  searchHeader: "Rx Management Patient Search",
  searchNoResultsText: (pimsType: string) =>
    `Search something above to look for a patient record in Prescription Management to link with your ${
      pimsType || "PIMS"
    } record.`,
  searchHelpText: "Search by patient name",
  sortButtons: [commonMessages.name, commonMessages.birthday, commonMessages.weight, commonMessages.clientName],
  patientLinked: "This patient is already linked.",
  importAsNew: "import as new",
  patientsOrClients: "patients or clients",
  weightSyncMessage: (pimsType: string) =>
    `Patient weight will be updated from the ${pimsType || ''} patient record once linked.`,
  gpmPatientSerachError: "Error occurred while trying to search GPM patients"
};
