export default {
  headerText: "Rx Management Product Search",
  searchHelpText: "Search by name or active ingredient",
  noResultsText: (pimsType?: string) =>
    `Search something above to look for a product SKU in Rx Management to link with your ${
      pimsType || "PIMS"
    } product.`,
  noMatchTypeText: "products",
  noMatchButtonText: "Request a new product",
  noMatchText: (searchType: string, searchString: string) =>
    `We couldn't find any ${searchType} named "${searchString}". Try checking your filters and spelling.`,
  species: "Species",
  minStrength:"Minimum Strength",
  maxStrength:"Maximum Strength",
  filters: "filters",
  patientWeightCheckLabel: (patientWeight: string | null, pimsType?: string) =>
    `Filter by patient weight ${patientWeight ? ` (${patientWeight})` : ""}`,
  disabledFilter: (pimsType?: string) =>
    `This filter is disabled because there was no weight found for the patient.`,
};
