import * as React from "react";

import { Redirect, useLocation, useParams } from "react-router-dom";
import { WarningCard } from "../../components/WarningCard/WarningCard";
import Loading from "../../components/StyledComponents/Loading/Loading";
import {
  useimportClientMutation,
  useimportPatientMutation,
  useretrieveLinksByClaimCheckGuidQuery,
} from "../../data/gql/apiTypes";
import PatientImportForm from "./PatientImportForm";
import { ParamTypes } from "./types";
import {exists} from "../../util";
import { ContainerProps } from "../../PrivateRoute/types";

const PatientImport = (props: ContainerProps) => {
  const [importPatient] = useimportPatientMutation();
  const [importClient] = useimportClientMutation();
  const [didSubmitOnce, setDidSubmitOnce] = React.useState(false);
  const [onSubmitSuccess, setOnSubmitSuccess] = React.useState(false);
  const [onSubmitErrors, setOnSubmitErrors] = React.useState("");

  const { claimCheckKey } = useParams<ParamTypes>();
  const [existingClientGuid, setExistingClientGuid] = React.useState(
    new URLSearchParams(useLocation().search).get("existingClient"),
    );
  const [speciesCode, setSpeciesCode] = React.useState("");
  const [speciesName, setSpeciesName] = React.useState("");
  const [speciesError] = React.useState("");

  if (!props.claimCheck) return <WarningCard reason={"Error fetching claim check"} />;
  if (!props.user) return <WarningCard reason={"Error fetching logged in user info"}/>;
  if (!claimCheckKey) return <WarningCard reason={"No Claim Check GUID present on URL"} />;
  const { loading, data, error } = useretrieveLinksByClaimCheckGuidQuery({
    variables: { guid: claimCheckKey },
  });
  if (loading) return <Loading fullPage />;

  if (error || (!loading && !data)) {
    return <WarningCard reason={"Error occurred while fetching data"} />;
  }

  if (onSubmitSuccess) {
    // TODO: add toast that import & link was successful?
    if (exists(props.claimCheck.payload.product)) {
      return <Redirect to={`/product-linking/${claimCheckKey}`} />;
    } else {
      return <Redirect to={`/product-search/${claimCheckKey}`} />;
    }
  }


  const formProps = {
    data,
    user: props.user,
    claimCheck: props.claimCheck,
    claimCheckKey,
    importPatient,
    importClient,
    setOnSubmitSuccess,
    onSubmitErrors,
    setOnSubmitErrors,
    didSubmitOnce,
    setDidSubmitOnce,
    existingClientGuid,
    setExistingClientGuid,
    speciesCode,
    setSpeciesCode,
    speciesName,
    setSpeciesName,
    speciesError,
  };

  return (
    <>
      <PatientImportForm {...formProps} />
    </>
  );
};

export default PatientImport;
