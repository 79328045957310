import React from "react";
import { ColumnLabel, PRIMARY_CLASS, TableHeader } from "./styles";
import { SchemaColumn } from "./types";
import { SortArrowIcon } from "../../assets/SortArrowIcon";
import { ArrowIcon } from "../../assets/ArrowIcons";
import { isDataColumn, SortOpts } from "./utils";
import { InfoCircled } from "../../assets/InfoCircled";
import Tooltip from "../../components/Tooltip";

const MINIMUM_WIDTH_DEFAULT = 100;

export interface ResizableTableHeaderProps<T> {
  gridSchema: SchemaColumn<T>[];
  sort?: SortOpts<T> | null;
  changeSort: (sort: SortOpts<T>) => void;
  allowResizing?: boolean;
  hideSortIcon?: boolean;
}

export const SortableResizableTableHeader = <T extends object>(props: ResizableTableHeaderProps<T>) => {
  const {
    gridSchema,
    sort,
    changeSort,
    allowResizing,
  } = props;

  const getSortIcon = (item: SchemaColumn<T>) => {
    if (item.primary && sort === null) {
      return <ArrowIcon direction="down" />;
    }
    if (sort?.sortVal === item.dataGetter) {
      return sort.descending ? <ArrowIcon direction="down" /> : <ArrowIcon direction="up" />;
    }
    return <SortArrowIcon />;
  };

  const shouldResize = (i: number) => {
    return allowResizing && (i !== gridSchema.length - 1);
  };

  const headerRefs: React.RefObject<HTMLTableHeaderCellElement>[] = [];
  const columns = gridSchema.map((item, i) => {
    headerRefs.push(React.createRef<HTMLTableHeaderCellElement>());
    return (
      <ColumnLabel
        style={{ width: item.width }}
        className={item.primary ? PRIMARY_CLASS : undefined}
        key={`sort-button-${i}`}
        ref={headerRefs[i]}
      >
        {/* only if this is a datacolumn with a label header.. render the sort button for that column */}
        {isDataColumn(item) ? (
          <div style={{ display: "inline-flex" }}>
            <button
              onClick={() =>
                changeSort({
                  sortVal: item.dataGetter,
                  descending: sort?.sortVal !== item.dataGetter ? true : !sort.descending,
                  label: item.label,
                })
              }
            >
              {item.label}
              {props.hideSortIcon ? <></> : getSortIcon(item)}
            </button>
            {item.tooltipText ? (
              <Tooltip message={item.tooltipText} position="right">
                <InfoCircled />
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
        ) : null}

        {/*
          * A 'resizer' div sits on the right of each column header.
          * Clicking it and dragging will adjust the pixel width of the column to
          * the left and right.
          * There is a minimum possible width so columns don't get too squished.
          */}
        { shouldResize(i) ?
        <div
          className="resizer"
          onMouseDown={(e) => {

            // turn any percentage-based widths into pixel numbers
            headerRefs.forEach((headerRef, widthLockIndex) => {
              const headerElement = headerRef.current!;

              if (headerElement.style.width.endsWith('%')) {
                const headerStyles = window.getComputedStyle(headerElement);
                const headerWidth = parseInt(headerStyles.width, 10);

                headerElement.style.width = `${headerWidth}px`;
              }
            });


            const x = e.clientX;
            const headerCellLeft = headerRefs[i].current!;
            const headerCellRight = headerRefs[i+1].current!

            const stylesLeft = window.getComputedStyle(headerCellLeft);
            const startingWidthLeft = parseInt(stylesLeft.width, 10);

            const stylesRight = window.getComputedStyle(headerCellRight);
            const widthCombined = parseInt(stylesRight.width, 10) + startingWidthLeft;

            const minWidthLeft = item.minWidth ? item.minWidth : MINIMUM_WIDTH_DEFAULT;
            const minWidthRight = gridSchema[i+1].minWidth! ? gridSchema[i+1].minWidth! : MINIMUM_WIDTH_DEFAULT;

            const mouseMoveHandler = function(e: any) {
              const dx = e.clientX - x;
              const widthLeft = startingWidthLeft + dx;
              const boundedWidthLeft = widthLeft < minWidthLeft ? minWidthLeft : widthLeft;

              const widthRight = widthCombined - boundedWidthLeft;

              const finalRightWidth = (widthRight < minWidthRight) ? minWidthRight : widthRight;
              const finalLeftWidth = (finalRightWidth <= minWidthRight) ? (widthCombined - finalRightWidth) : boundedWidthLeft;

              headerCellLeft.style.width = `${finalLeftWidth}px`;
              headerCellRight.style.width = `${finalRightWidth}px`;
            };

            const mouseUpHandler = function() {
              document.removeEventListener('mousemove', mouseMoveHandler);
              document.removeEventListener('mouseup', mouseUpHandler);
            };

            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
          }}>
            <div className="resizerIndicator"></div>
          </div> : null}
      </ColumnLabel>
    )
  });

  return (
    <TableHeader>
      <tr>
        {columns}
      </tr>
    </TableHeader>
  );
}