import styled from "styled-components/macro";

export const NoMatchGrid = styled.div`
  border-top: solid 1px ${({ theme }) => theme.tableBorderColor};
  color: ${({ theme }) => theme.primaryTextColor};

  padding: 20px;
  padding-bottom: 40px;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;

  p,
  b {
    padding: 5px;
  }
`;

export const TableWrapper = styled.table`
  table-layout: fixed;
  width: 100%;
  color: ${({ theme }) => theme.primaryTextColor};
  border-spacing: 0;
  border-collapse: collapse;

  svg {
    vertical-align: bottom;
  }

  tr {
    border-bottom: solid 1px ${({ theme }) => theme.tableBorderColor};
  }

  th {
    text-align: left;
    padding: 8px;
  }

  td {
    padding: 8px;
    width: 216px;
    text-align: left;
    font-size: 14px;
    vertical-align: top;
  }

  td.data {
    max-width: 216px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tr:nth-child(even) {
    background: #fafaf8;
  }

  td:nth-child(1) {
    font-weight: bold;
  }

  td:not(:first-child):not(:last-child) {
    border-right: solid 1px ${({ theme }) => theme.tableBorderColor};
  }

  div {
    display: inline-flex;
    flex-flow: column;
  }
`;

export const LabelColTh = styled.th`
  width: 25%;
`;

export const NoMatchLabelColTh = styled.th`
  width: 30%;
`;

export const NoMatchTableWrapper = styled(TableWrapper)`
  width: 60%;
`;

export const NoMatchDiv = styled.div`
  min-width: 40%;
  max-width: 40%;

  svg {
    height: 37px;
    float: left;
    margin-left: 6px;
    padding-top: 5px;
  }
`;

export const NoMatchWrapper = styled.div`
  display: flex;
`;
