import * as React from "react";
import { useHistory } from "react-router-dom";
import { back } from "../ProductDetailsModal/utils";
import ModalWrapper from "../StyledComponents/Modal/Modal";
import { FaqItemList } from "./styles";

import { FaqItems, Props } from "./types";

const FaqModal = (props: Props) => {
  const history = useHistory();

  const buildSubItem = (subs: FaqItems[] | undefined) => {
    if (subs === undefined) {
      return null;
    }

    return subs.map((answers: FaqItems, j: number) => (
      <li key={`answers-${j}`}>
        {answers.text}
        <ul>{buildSubItem(answers?.subItems)}</ul>
      </li>
    ));
  };

  const buildFaqItems = (faqItems: FaqItems[]) =>
    faqItems.map((questions, i) => (
      <li key={`questions-${i}`}>
        <strong>{questions.text}</strong>
        <ul>{buildSubItem(questions?.subItems)}</ul>
      </li>
    ));

  return (
    <ModalWrapper onClose={(e) => back(e, history, props.returnTo)} cancellable={true}>
      <h2>{props.header}</h2>
      <br />
      <FaqItemList>{buildFaqItems(props.faqItems)}</FaqItemList>
      <br />
      <p>{props.bottomText}</p>
    </ModalWrapper>
  );
};

export default FaqModal;
