import styled from "styled-components/macro";
import React from "react";
import LoadingAnimation from "../../../assets/Loading";

type Props = {
  width?: string;
  fullPage?: boolean;
};

const LoadingContainer = styled.div<Props>`
  width: ${(props) => props.width};
  height: ${(props) => (props.fullPage ? "100vh" : "100%")};
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
`;

export default (props: Props) => (
  <LoadingContainer width={props.width} fullPage={props.fullPage}>
    <LoadingAnimation />
  </LoadingContainer>
);
