import * as H from "history";
import * as r from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { addToast } from "../../actions/toast";
import { LinkIcon } from "../../assets/LinkIcon";
import CollapsableCard from "../../components/CollapsableCard/index";
import { DescriptionComponent } from "../../components/DescriptionComponent";
import { getDisplayPimsName } from "../../components/PimsLogo/getDisplayPimsName";
import PimsLogo from "../../components/PimsLogo/PimsLogo";
import SearchGrid from "../../components/SearchGrid/index";
import { SpeciesCell } from "../../components/SearchGrid/styles";
import { SpeciesPicker } from "../../components/Species/Species";
import { Container } from "../../components/StyledComponents/Container/Container";
import Loading from "../../components/StyledComponents/Loading/Loading";
import toastMessages from "../../components/Toast/messages";
import { generateToastID } from "../../components/Toast/Toast";
import Tooltip from "../../components/Tooltip";
import { WarningCard } from "../../components/WarningCard/WarningCard";
import {
  ClaimCheckPatientT,
  searchGPMPatientsQuery,
  searchGPMPatientsQueryResult,
  usecreatePatientLinkMutation,
  useRetrieveClaimCheckByGuidForPatientQuery,
  useRetrieveClientByGuidQuery,
  usesearchGPMPatientsQuery
} from "../../data/gql/apiTypes";
import { smoothScroll } from "../../domUtils";
import { exists, nullMap } from "../../util";
import messages from "./messages";
import commonMessages from "../../common/messages";
import { InfoCircled } from "../../assets/InfoCircled";
import {GlobalStateContext} from "../../context/state";
import { ContainerProps } from "../../PrivateRoute/types";
import { hasFeature } from "../../config/featureFlags";
import { singleMatchSuggestion } from "./suggestLinkUtils";
import { useDispatchErrors } from "../../hooks/useDispatchErrors";

export type SearchPatientResult = searchGPMPatientsQuery["searchPatients"][0];
export const PatientLinking = (props: ContainerProps) => {
  const { claimCheckKey } = useParams<{ claimCheckKey?: string }>();
  const [searchCriteria, setSearchCriteria] = useState({ query: "", pageNumber: 1, pageSize: null, filter: { isActive: true, isClientActive: true, isLinked: false} });
  const [selectedPatient, setSelectedPatient] = useState<SearchPatientResult | null>(null);
  const [toProductSearchOrLink, setToProductSearchOrLink] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [cleared, setCleared] = useState(false);
  //const [matchClientIndex, setMatchClientIndex] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation<H.History>();
  const globalContext = React.useContext(GlobalStateContext);
  useEffect( () => {
    if (!selectedPatient) {
      return;
    }
    // 64px is the height of the header so this scrolls to the very bottom of the header and allows
    // the user to see the page title / description
    smoothScroll(64);
  }, [selectedPatient]);

  const { loading, error, data } = useRetrieveClaimCheckByGuidForPatientQuery({
    variables: { claimCheckKey: claimCheckKey || "", pimsPrescriptionKey: null },
    fetchPolicy: "cache-and-network",
  });

  const qResult = usesearchGPMPatientsQuery({
    variables: { criteria: searchCriteria },
    skip: !exists(searchCriteria.query),
  });
  
  const [createPatientLink, patientLinkResults] = usecreatePatientLinkMutation();

  // Commented for INTEXP-13521
  
   const clientDetails = useRetrieveClientByGuidQuery({
     variables: { guid: selectedPatient?.clientKey || "" },
     skip: !exists(selectedPatient?.clientKey),
   });

  useDispatchErrors([
    { error: qResult.error, message: messages.gpmPatientSerachError }
  ]);
   

   if (error) {
     console.error(error);
     return <WarningCard reason={"Unknown error."} />;
   }

  const claimCheckProductPayload = props.claimCheck?.payload?.product;
  const maybePimsName = globalContext.state.session.pimsType || data?.retrieveRxByGuidOrClaimCheckGuid?.pimsType || "";
  const linkedPatientKey = data?.retrieveRxByGuidOrClaimCheckGuid?.linkedPatientKey || "";
  const displayPimsName = getDisplayPimsName(maybePimsName);

  const existingPatientLinkFound = !!linkedPatientKey;
  if (toProductSearchOrLink || existingPatientLinkFound) {
    if (exists(claimCheckProductPayload)) {
      return <Redirect to={`/product-linking/${claimCheckKey}`}/>;
    } else {
      return <Redirect to={`/product-search/${claimCheckKey}`}/>;
    }
  }

  const retrieveClaimCheckByGuid = props.claimCheck;

  // TODO: switch over to fetchPatientWeight
  const fetchSearchResultPatientWeight = (patientResult: SearchPatientResult) => {
    return patientResult.weight !== null && patientResult.weightUnitCode !== null && patientResult.weight !== "0.00"
      ? `${patientResult.weight} ${patientResult.weightUnitCode}`
      : "";
  };

  if (loading || patientLinkResults.loading) return <Loading fullPage />;
  if (!claimCheckKey) return <WarningCard reason={"No Claim Check GUID present on URL"} />;
  if (!props.claimCheck) return <WarningCard reason={"Error fetching claim check"} />;
  if (!props.user) return <WarningCard reason={"Error fetching logged in user info"}/>;
  if (!data || !retrieveClaimCheckByGuid) {
    return <WarningCard reason={`Claim check not found with guid: ${claimCheckKey}`} />;
  }
  if (clientDetails.error) return <WarningCard reason={"Unable to fetch client details"} />;

  const client = retrieveClaimCheckByGuid.payload?.client;
  const patient = retrieveClaimCheckByGuid.payload?.patient;
  const loggedInUsername = props.user.username;

  if (!patient) return <WarningCard reason={"No patient found for Claim Check"} />;

  const fetchClaimCheckPatientWeight = (patient: ClaimCheckPatientT) => {
    return patient.currentWeight != null && patient.currentWeightUnit != null
      ? `${patient.currentWeight} ${patient.currentWeightUnit}`
      : "";
  };
  const weightWithUnits = fetchClaimCheckPatientWeight(patient);
  const collapsedText = `${weightWithUnits ? weightWithUnits + " • " : ""}${client?.lastName + ", " || ""}${
    client?.firstName || ""
  }`;

  const getContinueFn = () => {
    const vdsFields = [
      retrieveClaimCheckByGuid.payload?.patient?.vdsKey,
      retrieveClaimCheckByGuid.installationKey,
      retrieveClaimCheckByGuid.payload?.patient?.pmsKey,
      retrieveClaimCheckByGuid.payload?.client?.vdsKey,
    ];
    const allVdsFieldsPresent = r.all(exists)(vdsFields);
    const patientKey =  selectedPatient?.key;
    const clientKey =  selectedPatient?.clientKey;
    if (exists(selectedPatient) && allVdsFieldsPresent && patientKey) {
      return () =>
        createPatientLink({
          variables: {
            patientLink: {
              claimCheckKey,
              patientKey: patientKey,
              clientKey: clientKey?.toString() || "",
              vdsPatientKey: r.path(["payload", "patient", "vdsKey"], retrieveClaimCheckByGuid) as string,
              vdsClientKey: r.path(["payload", "client", "vdsKey"], retrieveClaimCheckByGuid) as string,
              pimsPatientKey: r.path(["payload", "patient", "pmsKey"], retrieveClaimCheckByGuid) as string,
              pimsClientKey: r.path(["payload", "client", "pmsKey"], retrieveClaimCheckByGuid) as string,
              installationKey: retrieveClaimCheckByGuid.installationKey as string,
            },
          },
        }).then((_) => {
          setToProductSearchOrLink(true);
          dispatch(
            addToast({
              message: toastMessages.patientSuccess,
              id: generateToastID(),
              kind: "success",
            }),
          );
        }).catch( err_ => {
          dispatch(
            addToast({
              message: toastMessages.patientFailure,
              id: generateToastID(),
              kind: "error",
            }),
          );
        });
    }
    return () => dispatch(
      addToast({
        message: "Error: missing vds fields detected cannot create pet link",
        id: generateToastID(),
        kind: "error"
      })
    );
  };

  const {
    noMatchText,
    headerText,
    descriptionText,
    searchHelpText,
    patientsOrClients,
    importAsNew,
    searchHeader,
    searchNoResultsText,
  } = messages;

  const getPatientWeightRightValue = () => {
    if (exists(selectedPatient)) {
      if (patient.currentWeight && (!exists(patient.currentWeight) || patient.currentWeight === "0")) {
        return fetchSearchResultPatientWeight(selectedPatient);
      }
      return (
        <div
          style={{ display: "flex", justifyContent: "space-between", flexFlow: "row" }}
          title={fetchSearchResultPatientWeight(selectedPatient) || ""}
        >
          <div style={{ fontWeight: "bold" }}>{fetchSearchResultPatientWeight(selectedPatient)}</div>
          <div style={{ marginRight: 10 }}>
            <div style={{ position: "absolute", whiteSpace: "normal" }}>
              <Tooltip message={messages.weightSyncMessage(maybePimsName)} position="right">
                <InfoCircled />
              </Tooltip>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  const getResultsProp = (qResult: searchGPMPatientsQueryResult) => {
    if (qResult.loading) {
      return { kind: "loading" as "loading" };
    }
    if (exists(qResult.error)) {
      return { kind: "error" as "error", error: qResult.error };
    }
    return (
      nullMap((it) => ({ kind: "loaded" as "loaded", results: it.searchPatients }), qResult.data) || {
        kind: "noop" as "noop",
      }
    );
  };

  // Commented for INTEXP-13521
  if (hasFeature("suggestPatientLinks") && getResultsProp(qResult).kind === "loaded" && 
      qResult.data?.searchPatients.length === 1 &&
      exists(client) && !exists(selectedPatient) &&
      !cleared) {
    const [singleMatch] = singleMatchSuggestion(qResult.data?.searchPatients[0], client, patient);
    if (singleMatch) {
      //setMatchClientIndex(singleMatchClientIndex ?? 0);
      setSelectedPatient(qResult.data?.searchPatients[0]);
      setCollapsed(false);
    }
  }

  const patientLinkingProps = {
    headerIcon: <SpeciesPicker species={patient?.speciesDescription} useThumbnail={false} />,
    headerText: patient?.name,
    collapsed,
    setCollapsed,
    speciesIcon: 1,
    collapsedText,
    buttonRouteTo: {
      pathname: `/patient-choose-flow/${claimCheckKey}`,
      state: { background: location },
    },
    comparison: {
      noMatchText,
      leftColumnIcon: <PimsLogo pimsType={maybePimsName} />,
      cvetMatchFound: exists(selectedPatient),
      linkSelectionMade: exists(selectedPatient) && !cleared,
      showPimsData: true,
      continue: getContinueFn(),
      comparisonGrid: [
        {
          label: commonMessages.name,
          leftValue: nullMap(r.prop("name"), patient),
          rightValue: nullMap(r.prop("name"), selectedPatient),
        },
        {
          label: commonMessages.client,
          leftValue: `${nullMap(r.prop("firstName"), client)} ${nullMap(r.prop("lastName"), client)}`,
          rightValue: selectedPatient?.clientName,
        },
        {
          label: commonMessages.email,
          leftValue: nullMap(r.prop("email"), client),
          rightValue: nullMap(r.prop("emailAddress"), clientDetails?.data?.retrieveClientByGuid),
        },
        {
          label: commonMessages.phone,
          leftValue: nullMap(r.prop("phoneNumber"), client),
          rightValue: nullMap(r.prop("phoneNumber"), clientDetails?.data?.retrieveClientByGuid),
        },
        {
          label: commonMessages.species,
          leftValue: nullMap(r.prop("speciesDescription"), patient),
          rightValue: nullMap(r.prop("speciesCode"), selectedPatient),
        },
        {
          label: commonMessages.weight,
          leftValue: `${weightWithUnits}`,
          rightValue: getPatientWeightRightValue(),
        },
        {
          label: commonMessages.sex,
          leftValue: nullMap(r.prop("gender"), patient),
          rightValue: nullMap(r.prop("sexCode"), selectedPatient),
        },
        {
          label: commonMessages.dob,
          leftValue: nullMap(r.prop("dateOfBirth"), patient),
          rightValue: nullMap(r.prop("dateOfBirth"), selectedPatient),
        },
      ],
    },
    clearSelected: () => {
      setSelectedPatient(null);
      setCleared(true);
    }
  };

  const hasSearchSuggestion =
    hasFeature("suggestPatientLinks") && patient?.name && !patientLinkingProps.comparison.cvetMatchFound;

  const gridHeaders = [
    {
      dataGetter: (row: SearchPatientResult) => (
        <SpeciesCell>
          <SpeciesPicker species={r.propOr(null, "speciesCode", row)} useThumbnail={true} />
        </SpeciesCell>
      ),
      width: "7.5%",
    },
    {
      label: commonMessages.name,
      dataGetter: "name" as "name",
      width: "27.5%",
      primary: true,
    },
    {
      label: commonMessages.birthday,
      dataGetter: "dateOfBirth" as "dateOfBirth",
      width: "15%",
    },
    {
      label: commonMessages.weight,
      dataGetter: fetchSearchResultPatientWeight,
      width: "12.5%",
    },
    {
      label: commonMessages.clientName,
      dataGetter: "clientName" as "clientName",
      width: "25%",
    },
    {
      label: commonMessages.info,
      dataGetter: (row: SearchPatientResult) =>
        row.isLinked ? (
            <LinkIcon height="20px" width="20px" />
        ) : null,
      width: "10.5%",
      overflow: true,
    },
  ];

  return (
    <Container>
      <DescriptionComponent 
      header={headerText} 
      description={descriptionText(displayPimsName)} 
      pimsType={displayPimsName} 
      username={loggedInUsername || ""}
      claimCheckKey={claimCheckKey} />
      <CollapsableCard {...patientLinkingProps} />
      <SearchGrid<SearchPatientResult>
        searchHelpText={searchHelpText}
        noMatchTypeText={patientsOrClients}
        noMatchButtonText={importAsNew}
        headerText={searchHeader}
        noResultsText={searchNoResultsText(displayPimsName)}
        gridSchema={gridHeaders}
        searchResults={getResultsProp(qResult)}
        autocomplete={false}
        initialSearchString={hasSearchSuggestion ? patient?.name : ""}
        commitSearch={(newCriteria: any) => setSearchCriteria(newCriteria)}
        selectResult={(patient) => {
          setSelectedPatient(patient);
          setCollapsed(false);
          setCleared(false);
        }}
        guidRowSelected={nullMap(r.prop("key"), selectedPatient)}
        getIsLinked={(row: SearchPatientResult) => r.prop("isLinked", row)}
        buttonText={importAsNew}
        buttonRouteTo={{
          pathname: `/patient-choose-flow/${claimCheckKey}`,
          state: { background: location },
        }}
      />
    </Container>
  );
};
