import * as React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ContainerProps } from "../../PrivateRoute/types";
import RxList from "../../components/RxList";
import { WorkFlowType } from "../../components/CollapsableCard/types";
import { addToast } from "../../actions/toast";
import toastMessages from "../../components/Toast/messages";
import { generateToastID } from "../../components/Toast/Toast";
import { Container } from "../../components/StyledComponents/Container/Container";
import {
  useRetrievePracticeUserWithClaimCheckQuery,
  searchPracticeApprovalsQuery,
  usesearchPracticeApprovalsQuery,
  usesearchPracticeRenewalsQuery,
  usegetAuthorizingVetsForUserQuery,
  VetsForPracticeUserT,
  ApprovalsT,
  RenewalsT,
  FullPracticeUserT,
  Maybe} from "../../data/gql/apiTypes";
import Loading from "../../components/StyledComponents/Loading/Loading";
import { WarningCard } from "../../components/WarningCard/WarningCard";
import { HeaderContainer } from "./styles";
import DropdownSelector from "../../components/DropdownSelector";
import { ListType } from "../../components/RxList/types";
import { useSetVetFilterSessionEffect, useSession, useSetListTypeSessionEffect } from "../../hooks/sessionFlow";
import { hasFeature } from "../../config/featureFlags";
import { pendoImpl } from "../../util";

export type SearchApprovalsResult = searchPracticeApprovalsQuery["searchApprovals"];

const PendingRxList = (props: ContainerProps) => {
  const { claimCheckKey } = useParams<{ claimCheckKey: string }>();

  const history = useHistory();
  const dispatch = useDispatch();

  const user = useRetrievePracticeUserWithClaimCheckQuery({
    variables: {
      claimCheckKey,
    },
  });

  const searchCriteria = {
    practiceKey: user.data?.retrieveClaimCheckByGuid?.animalCareBusinessKey, clientOrPatientQuery: null, authorizingVetKey: null, workflowStatusCode: null,
    shippingTypeCode: null, externalCartId: null
  };

  const { vetFilter: stateVetFilter, listType: stateListType } = useSession();

  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [vetFilter, setVetFilter] = React.useState(stateVetFilter || "all");
  const [listType, setListType] = React.useState<ListType.APPROVALS | ListType.RENEWALS>(stateListType || ListType.APPROVALS);

  const [approvals, setApprovals] = React.useState<Array<ApprovalsT>>([]);
  const [renewals, setRenewals] = React.useState<Array<RenewalsT>>([]);
  const [isFilterChanged, setIsFilterChanged] = React.useState<boolean>(false);

  const { loading, error, data } = usesearchPracticeApprovalsQuery({
    variables: { searchApprovals: searchCriteria }
  });

  const { loading: loadingRenewals, error: errorRenewals, data: dataRenewals } = usesearchPracticeRenewalsQuery({
    variables: {
      searchRenewals: {
        practiceKey: user.data?.retrieveClaimCheckByGuid?.animalCareBusinessKey,
        onlyPreventatives: null,
        onlyPending: true,
        onlyMyItems: null,
        showExpiredBy: null,
        workflowStatus: null,
        onlyAutoships: null,
        prescribingVetKey: null,
        excludeRecentlyAuthorized: null
      }
    }
  });

  const { loading: loadingVets, error: errorVets, data: dataVets } = usegetAuthorizingVetsForUserQuery({});
  const gpmAuthorizingVets = dataVets?.retrieveVetsForPracticeUser as VetsForPracticeUserT[];
  const practiceUser = user.data?.retrievePracticeUser as FullPracticeUserT;

  useSetVetFilterSessionEffect(vetFilter);
  useSetListTypeSessionEffect(listType);

  React.useEffect(() => {
    if ((data || dataRenewals) && vetFilter === "all") {
      setApprovals(data?.searchApprovals?.filter(item => item?.approvableKey) as Array<ApprovalsT>);
      setRenewals(dataRenewals?.searchRenewals?.filter(item => item?.renewableKey) as Array<RenewalsT>);
    }
    if ((data || dataRenewals) && vetFilter === "none") {
      setApprovals(data?.searchApprovals?.filter(item => !item?.prescribingVetKey && item?.approvableKey) as Array<ApprovalsT>);
      setRenewals(dataRenewals?.searchRenewals?.filter(item => !item?.prescribingVetKey && item?.renewableKey) as Array<RenewalsT>);
    } else {
      if ((data || dataRenewals) && vetFilter !== "all") {
        setApprovals(data?.searchApprovals?.filter(item => item?.prescribingVetKey === vetFilter && item?.approvableKey !== null) as Array<ApprovalsT>);
        setRenewals(dataRenewals?.searchRenewals?.filter(item => item?.prescribingVetKey === vetFilter && item?.renewableKey !== null) as Array<RenewalsT>);
      }
    }
  }, [data, dataRenewals, vetFilter]);

  if (loading || showLoading || loadingRenewals || loadingVets) return <Loading />
  if (user.error) return <WarningCard reason={"Error fetching practice user."} />
  if (error || errorRenewals || errorVets) return <WarningCard reason={"Error fetching pending prescriptions."} />;

  const checkDelegatePermissions = (prescribingVetKey: Maybe<string>) => {
    if (prescribingVetKey == null && user.data?.retrievePracticeUser?.veterinarianKey != null){
      return true;
    }
    if ((user.data?.retrievePracticeUser?.vetDelegateLevels != null && prescribingVetKey !== user.data?.retrievePracticeUser?.veterinarianKey)) {
      const delegate = user.data.retrievePracticeUser?.vetDelegateLevels.find(x => x.veterinarianKey === prescribingVetKey);
      if (delegate == null){
        dispatch(
          addToast({
            message: toastMessages.noPermissions,
            id: generateToastID(),
            kind: "error",
          }),
        );
        return false;
      }
    }
    return true;
  }

  const onRxClick = (pendingAuthRequest: ApprovalsT) => {
    if (pendingAuthRequest.approvableKey) {
      if (checkDelegatePermissions(pendingAuthRequest.prescribingVetKey)){
        setShowLoading(true);
        history.push(`/pending-rx/${claimCheckKey}/${WorkFlowType.APPROVALS}/${pendingAuthRequest.approvableKey}`);
      }
    }
  }

  const onRxRenewalClick = (pendingAuthRequest: RenewalsT) => {
    if (pendingAuthRequest.renewableKey) {
      if (checkDelegatePermissions(pendingAuthRequest.prescribingVetKey)){
        setShowLoading(true);
        history.push(`/pending-rx/${claimCheckKey}/${WorkFlowType.RENEWALS}/${pendingAuthRequest.renewableKey}`);
      }
    }
  }

  const getVets = () => {
    const vets: Array<any> = [];
    const vetGuids: Array<string> = [];
    gpmAuthorizingVets?.forEach(VetsForPracticeUserT => {
      let vetGuid = VetsForPracticeUserT?.veterinarianKey.toString() || "";
      if (vetGuid && !(vetGuids.includes(vetGuid))) {
        vets.push({ value: vetGuid, label: VetsForPracticeUserT?.givenName + " " + VetsForPracticeUserT.familyName || "" });
        vetGuids.push(vetGuid);
      }
    });
    vets.sort((a, b) => a.label < b.label ? -1 : 1);
    vets.unshift({ value: "all", label: "All" });
    vets.push({ value: "none", label: "None" });
    return vets;
  }

  const renderHeader = () => (
    <HeaderContainer>
      <div>
        <label style={{ paddingLeft: 5 }}><input className="radio" type="radio" value="approvals" name="type" checked={listType === ListType.APPROVALS} onChange={(e: React.BaseSyntheticEvent) => { setListType(ListType.APPROVALS); setIsFilterChanged(true) }} /> Client Requests </label>
        {hasFeature("renewals") &&
          <label style={{ paddingLeft: 5 }}><input className="radio" type="radio" value="renewals" name="type" checked={listType === ListType.RENEWALS} onChange={(e: React.BaseSyntheticEvent) => { setListType(ListType.RENEWALS); setIsFilterChanged(true) }} /> Renewals </label>}
      </div>
      <DropdownSelector
        id="vet"
        inputLabel={"Veterinarian"}
        value={vetFilter}
        initialValWhenLoaded={vetFilter}
        onChange={(e: React.BaseSyntheticEvent) => { setVetFilter(e.target.value); setIsFilterChanged(true) }}
        opts={getVets()}
        noSpacer={true}
      />
    </HeaderContainer>
  );

  // Pendo Report Impl
  if (user.data?.retrievePracticeUser) {
    const userKey= user.data?.retrievePracticeUser?.userKey;
    const acbkey =user.data?.retrievePracticeUser?.animalCareBusinessKey;
    pendoImpl(userKey,acbkey)
    
  }


  return (
    <Container>
      {renderHeader()}
      <RxList
        renewals={renewals}
        approvals={approvals}
        onRxClick={onRxClick}
        onRxRenewalClick={onRxRenewalClick}
        listType={listType}
        isFilterChanged={isFilterChanged}
        setIsFilterChanged={setIsFilterChanged}
        user={practiceUser}
      />
    </Container>
  );
}

export default PendingRxList;