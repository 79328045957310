import * as Yup from "yup";
import { InitialOTCvalues, InitialAOTCvalues } from "./types";
import flaggedMessages from "../Subcomponents/FlaggedIssuesError/messages";
import { FlaggedGridValues } from "../Subcomponents/FlaggedIssuesError/types";
import messages from "../messages";
import { DEFAULT_NOTIFY_DATE, DEFAULT_UTC_NOTIFY_DATE } from "../Subcomponents/NotificationField/Notification";
import { getDefaultVet } from "../ScriptForm/formHelpers";
import { VetsForPracticeUserT } from "../../../data/gql/apiTypes";
import { LinkedGuids } from "../types";
import { checkVetLicenseExpired, checkVetLicenseInvalid } from "../Subcomponents/AuthorizingVet/utils";
import getFourByteUtfCharacters from "../../../util";

// Initial Form Values
const baseIntialValues = {
  type: "OTC" as "OTC",
  notification: DEFAULT_NOTIFY_DATE,
  notesToClientShortcuts: "",
  notesToClient: "",
  autoship: true,
  isExternalPharmacy: false,
  expiration: "",
  legalCategoryCode: "OTC" as "OTC",
  veterinarianLicenseKey: "",
};

const oneYearFromYesterday = () => {
  const date = new Date();
  date.setDate(date.getDate() - 1);
  date.setFullYear(date.getFullYear() + 1);
  return date.toISOString();
};

export const getInitialValues = (
  flagged: FlaggedGridValues[] | null,
  isAOTC: boolean,
  authorizingVets: VetsForPracticeUserT[],
  authorizingVet: string | null,
  expirationDate?: string,
  strength?: string,
  approveWithChangeReason?: string,
  approveWithChangeClientNote?: string,
): InitialOTCvalues | InitialAOTCvalues => {
  const defaultVet = getDefaultVet(authorizingVets);

  const baseWithVet = {
    ...baseIntialValues,
    authorizingVet: defaultVet?.veterinarianKey || authorizingVet || "",
    strength: strength || "",
  };

  if (flagged && flagged.filter(e => e.required).length > 0 && isAOTC) {
    return {
      ...baseWithVet,
      type: "APPROVED_OTC",
      expiration: expirationDate || oneYearFromYesterday(),
      approveWithChangeReason,
      approveWithChangeClientNote,
      legalCategoryCode: "APPROVED_OTC",
    };
  }

  if (flagged && flagged.filter(e => e.required).length > 0) {
    return { ...baseWithVet, approveWithChangeReason, approveWithChangeClientNote, legalCategoryCode: "OTC" };
  }

  if (isAOTC) {
    return {
      ...baseWithVet,
      type: "APPROVED_OTC",
      expiration: expirationDate || "",
      approveWithChangeReason,
      approveWithChangeClientNote,
      legalCategoryCode: "APPROVED_OTC",
    };
  }

  return baseWithVet;
};

export const getFormSchema = (
  flagged: FlaggedGridValues[] | null,
  isAOTC: boolean,
  authorizedWithChanges: boolean,
  authorizingVets: VetsForPracticeUserT[],
) => {
  // Form Validation Schema
  const baseValidationSchema = {
    notification: Yup.date().min(new Date(new Date().setHours(0, 0, 0, 0)), messages.validations.dateInPast),
    notesToClientShortcuts: Yup.string(),
    notesToClient: Yup.string().max(1000, messages.validations.notesToClient)
    .test("UTF_FOUND", messages.validations.utfFound, (notesToClient) => getFourByteUtfCharacters(notesToClient)),
    autoship: Yup.boolean(),
    isExternalPharmacy: Yup.boolean(),
    // For OTC
    authorizingVet: Yup.string()
      .required(messages.validations.required)
      .test("VET_LICENSE_EXPIRED", messages.validations.authorizingVet.vetLicenseExpired, (vetKey) =>
        checkVetLicenseExpired(vetKey, authorizingVets),
      ),
  };

  if (flagged && flagged.filter(e => e.required).length > 0 && isAOTC) {
    return Yup.object().shape({
      ...baseValidationSchema,
      expiration: Yup.date()
        .min(new Date(new Date().setHours(24, 0, 0, 0)), messages.validations.dateInPast)
        .max(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), messages.validations.expOneYearGreater),
      reason: Yup.string()
        .min(5, messages.validations.reasonMin)
        .max(512, messages.validations.reasonMax)
        .required(flaggedMessages.reasonHelpText)
        .test("UTF_FOUND", messages.validations.utfFound, (reason) => getFourByteUtfCharacters(reason)),
      authorizingVet: Yup.string()
        .required(messages.validations.required)
        .test("VET_LICENSE_INVALID", messages.validations.authorizingVet.vetLicenseInvalid, (vetKey) =>
          checkVetLicenseInvalid(vetKey, authorizingVets),
        )
        .test("VET_LICENSE_EXPIRED", messages.validations.authorizingVet.vetLicenseExpired, (vetKey) =>
          checkVetLicenseExpired(vetKey, authorizingVets),
        ),
      ...(authorizedWithChanges && {
        approveWithChangeReason: Yup.string().required(messages.validations.required),
        approveWithChangeClientNote: Yup.string().required(messages.validations.required),
      }),
    });
  }
  if (isAOTC) {
    return Yup.object().shape({
      ...baseValidationSchema,
      expiration: Yup.date()
        .min(new Date(new Date().setHours(24, 0, 0, 0)), messages.validations.dateInPast)
        .max(new Date(new Date().setFullYear(new Date().getFullYear() + 1)), messages.validations.expOneYearGreater),
      authorizingVet: Yup.string()
        .required(messages.validations.required)
        .test("VET_LICENSE_INVALID", messages.validations.authorizingVet.vetLicenseInvalid, (vetKey) =>
          checkVetLicenseInvalid(vetKey, authorizingVets),
        )
        .test("VET_LICENSE_EXPIRED", messages.validations.authorizingVet.vetLicenseExpired, (vetKey) =>
          checkVetLicenseExpired(vetKey, authorizingVets),
        ),
      ...(authorizedWithChanges && {
        approveWithChangeReason: Yup.string().required(),
        approveWithChangeClientNote: Yup.string().required(),
      }),
    });
  }
  if (flagged && flagged.filter(e => e.required).length > 0) {
    return Yup.object().shape({
      ...baseValidationSchema,
      reason: Yup.string()
        .min(5, messages.validations.reasonMin)
        .max(512, messages.validations.reasonMax)
        .required(flaggedMessages.reasonHelpText)
        .test("UTF_FOUND", messages.validations.utfFound, (reason) => getFourByteUtfCharacters(reason)),
    });
  }
  return Yup.object().shape(baseValidationSchema);
};

export const transformRxForApi = (
  values: InitialOTCvalues | InitialAOTCvalues,
  linkedGuids: LinkedGuids,
  claimCheckKey: string,
) => ({
  rx: {
    guid: linkedGuids.prescription,
    prescribingVetKey: values.authorizingVet,
    claimCheckKey,
    linkedClientKey: linkedGuids.client,
    linkedPatientKey: linkedGuids.patient,
    linkedProductKey: linkedGuids.product,
    baseProductKey: linkedGuids.baseProduct,
    notificationDisplayDate: values.notification === DEFAULT_NOTIFY_DATE ? DEFAULT_UTC_NOTIFY_DATE : DEFAULT_NOTIFY_DATE,
    notesToClientShortcut: values.notesToClientShortcuts,
    notesToClient: values.notesToClient,
    hasRecommendedAutoship: values.autoship,
    refills: null,
    directions: "",
    units: 0,
    maxQtyPerFill: 1,
    strength: values.strength,
    isExternalPharmacy: values.type === "APPROVED_OTC" ? values.isExternalPharmacy || false : false,
    acknowledgeErrors: values.acknowledgeErrors ?? [],
    smartScribeOverrideReason: values.reason,
    expirationDate: values.type === "APPROVED_OTC" ? values.expiration : null,
    approveWithChangesClientNote: values.approveWithChangeClientNote,
    approveWithChangeReason: values.approveWithChangeReason,
    approvalKey : linkedGuids.approvalKey,
    renewalKey : linkedGuids.renewalKey,
    legalCategoryCode: values.legalCategoryCode,
    veterinarianLicenseKey: values.veterinarianLicenseKey,    
  },
});
