import * as React from "react";

type Props = {
  width?: string;
  height?: string;
};

export const UserCheckmarkIcon = (props: Props) => (
  <svg
    width={props.width ? `${props.width}px` : "20px"}
    height={props.height ? `${props.height}px` : "20px"}
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>user-checkmark</title>
    <g id="🐼-Icon-Sheet" >
      <g id="Icon-Sheet" fill="#39B54A" fill-rule="nonzero">
      <path xmlns="http://www.w3.org/2000/svg" d="M405.333333 448a170.666667 170.666667 0 1 1 0-341.333333 170.666667 170.666667 0 0 1 0 341.333333z m0-85.333333a85.333333 85.333333 0 1 0 0-170.666667 85.333333 85.333333 0 0 0 0 170.666667z m192 469.333333H133.226667A90.666667 90.666667 0 0 1 42.666667 741.333333l0.106666-3.029333c0.256-3.562667 0.746667-9.557333 1.493334-17.194667a772.906667 772.906667 0 0 1 4.48-37.610666c3.562667-24.32 7.658667-42.602667 13.824-56C89.237333 569.557333 274.197333 490.666667 405.333333 490.666667c69.781333 0 154.816 22.336 223.104 51.968A212.501333 212.501333 0 0 1 768 490.666667c117.824 0 213.333333 95.509333 213.333333 213.333333s-95.509333 213.333333-213.333333 213.333333a213.013333 213.013333 0 0 1-170.666667-85.333333z m-38.4-85.333333a214.314667 214.314667 0 0 1 16.128-133.802667C517.738667 589.866667 457.429333 576 405.333333 576c-55.744 0-120.853333 15.872-181.674666 41.813333-25.344 10.794667-48.234667 22.72-65.429334 33.792a155.157333 155.157333 0 0 0-17.536 12.757334 54.613333 54.613333 0 0 0-1.536 1.408c-1.706667 5.418667-3.968 16.448-5.973333 30.101333a690.176 690.176 0 0 0-5.12 46.272 5.333333 5.333333 0 0 0 5.184 4.522667H558.933333z m153.898667-94.165334a42.666667 42.666667 0 0 0-60.330667 60.330667l64 64c8.32 8.32 19.242667 12.501333 30.165334 12.501333 10.922667 0 21.845333-4.16 30.165333-12.501333l106.666667-106.666667a42.666667 42.666667 0 0 0-60.330667-60.330666L746.666667 686.336l-33.834667-33.834667z" fill="#39B54A"
      id="user-checkmark"></path>
      </g>
    </g>
  </svg>
);