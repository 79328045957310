import * as React from "react";
import { Props } from "./types";
import DropdownSelector from "../../../DropdownSelector";
import messages from "../../messages";
import * as r from "ramda";
import { HelpLabel } from "../../styles";

const StateLicenseSelect = (props: Props) => {
  const { authorizingVet, errors, value, onChange } = props;
  const multiLicense = (authorizingVet?.veterinarianLicenses?.filter(l => l?.isActive).length || 0) > 1 ?
    authorizingVet?.veterinarianLicenses?.filter(l => l?.isActive) :
    null;
  const valOrDefault = !value ? authorizingVet ? authorizingVet.primaryVetLicenseNumber : "" : value;
  if (!multiLicense || !valOrDefault) {
    return null;
  }
  return <DropdownSelector
    noSpacer
    id="stateLicense"
    inputLabel={"Licenses"}
    required={true}
    onChange={onChange}
    value={valOrDefault}
    errors={errors}
    opts={r.map(
      (license) => ({
        value: license?.veterinarianLicenseKey ?? "",
        label: license?.stateOrProvince ?? "",
      }),
      multiLicense || []
    ) || undefined}
    helpFooter={<HelpLabel>{messages.choseStateLicense}</HelpLabel>}
  />
};

export default StateLicenseSelect;
