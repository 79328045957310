import { Props, Messages } from "./types";
import { formatPrice } from "./utils";

export const makeMessages = (product: Props["product"]): Messages => {
  return {
    header: {
      title: product.name,
      description: `Est. Client Subtotal:`,
    },
    description: {
      title: "Description",
      description: product.description || "",
    },
    activeIngredients: {
      title: "Active Ingredients",
      description: product.ingredients || "",
    },
    productInfo: {
      title: "Product Information",
      description: product.information || "",
    },
    directions: {
      title: "Directions for Use",
      description: product.directions || "Use as directed by your veterinarian.",
    },
    specialPrecautions: {
      title: "Special Precautions",
      description: product.precautions || "",
    },
    tableInfo: {
      title: "Product Pricing Table",
      header: ["Strength", "Size", "Client Retail", "Handling", "Client Subtotal"],
      tooltip: ["", "", "", "The handling fee covers the cost of the veterinarian’s process of researching and determining the proper dosage form to create and manage a personalized compounded prescription for your pet or horse.", ""],
      data: [
        product.strength || "",
        product.size || "",
        formatPrice(product.clientRetailPrice),
        formatPrice(product.handling),
        formatPrice(product.clientSubTotal),
      ],
    },
  };
};
