import * as React from "react";
import { AlertCircleIcon } from "../../assets/AlertCircleIcon";
import { FormErrorCard } from "../StyledComponents/FormErrorCard/FormErrorCard";
import { Props } from "./types";
import messages from "./messages";

export const FormError = (props: Props) => {
  const { title = messages.defaultErrorTitle, directions = messages.defaultErrorDirections, reason } = props;
  return (
    <FormErrorCard className={props.className || ""}>
      <div className={"icon-container"}>
        <AlertCircleIcon />
      </div>
      <div className={"copy-container"}>
        <h2 className={"title"}>{title}</h2>
        <p className={"error-directions"}>{directions}</p>
        <p className={"error-reason"}>{reason || messages.invalidCreds}</p>
      </div>
    </FormErrorCard>
  );
};
