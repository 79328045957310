import * as React from "react";

type Props = {
  className?: string;
};

export const TriangleDownIcon = (props: Props) => (
  <svg
    width="12px"
    height="6.5px"
    viewBox="0 0 24 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>triangle-down</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="translate(-640.000000, -347.000000)" fill="#9B9B9B">
        <polygon id="triangle-down" points="640 347.514196 652 359.514196 664 347.514196"></polygon>
      </g>
    </g>
  </svg>
);
