import * as React from "react";
import styled from "styled-components/macro";

type Props = {
  label: string;
  htmlFor?: string;
  className?: string;
  required?: boolean;
  errors?: string;
  style?: React.CSSProperties;
};

const BaseInputLabel: React.FC<Props> = ({ className, children, htmlFor, label, required, style }) => (
  <div className={className} style={style}>
    <label htmlFor={htmlFor}>{label}</label>{required? <span>*</span> : <span/>}
  </div>
);

export const InputLabel = styled(BaseInputLabel)<Props>`
  margin-bottom: 4px;
  font-size: 14px;
  ${(props) => (props.errors ? `color: ${props.theme.errorDarkColor};` : "")}
`;
