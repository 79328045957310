import { ProductComparisonGridValues } from "../ComparisonGrid/types";
import * as H from "history";
export interface BaseProps {}

export interface HandleUnlinkProductClick {
  (productLinkGuid: string): void;
}

export enum WorkFlowType {
  APPROVALS = "client requests",
  RENEWALS = "renewals"
}

export interface ProductCollapseCardValues {
  headerIcon: JSX.Element;
  headerText: string;
  collapsed: boolean;
  displayLoading?: boolean;
  setCollapsed: (collapsed: boolean) => void;
  collapsedText?: string;
  comparison: ProductComparisonGridValues;
  unlinkButtonText?: string;
  clearSelected?: () => void;
  buttonRouteTo?: {
    pathname?: string;
    state?: { background: H.Location };
    handleClick?: () => void | HandleUnlinkProductClick;
  };
  actions?: { 
    workflowType: WorkFlowType,
    key: string,
    workflowStatus: string;
    setWorkflowStatus: (key: string, status: string) => void;
    disablePrimaryButton?: boolean;
    disableSecondaryButton?: boolean;
    disableDeclineButton?: boolean;
    handlePrimaryButtonClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    handleSecondaryButtonClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    handleDeclineButtonClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }
}

export type OwnProps = BaseProps;
export type Props = OwnProps;
