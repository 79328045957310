import { ReactNode } from "react";
import { ListType } from "../components/RxList/types";

export type FlowType = "linked" | "unlinked" | "pending" | null;

export interface Session {
  flow: FlowType;
  pimsType: string | null;
  claimCheckKey?: string | null;
  isDelegate?: boolean;
  user?: any;
  vetFilter?: string | null;
  listType?: ListType | null;
  useSSO?: boolean;
}

export interface SessionState {
  session: Session;
}

export interface ProviderProps {
  children?: ReactNode;
}

export interface Store {
  state: SessionState;
  dispatch?: React.Dispatch<Action>;
}

export enum Actions {
  SET_SESSION = "SET_SESSION",
  REMOVE_SESSION = "REMOVE_SESSION",
}

interface SetSession {
  type: Actions.SET_SESSION;
  session: Session;
}

interface RemoveSession {
  type: Actions.REMOVE_SESSION;
}

export interface RemoveSessionAction {
  (): RemoveSession;
}

export type Action = SetSession | RemoveSession;
