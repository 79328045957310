import * as React from "react";
import ModalWrapper from "../StyledComponents/Modal/Modal";
import { Props } from "./types";
import { back } from "../ProductDetailsModal/utils";
import messages from "./messages";
import { ImportPatientContainer, CopyDiv } from "./styles";
import { Link, useHistory, useParams } from "react-router-dom";
import { ParamTypes } from "../../containers/PatientImport/types";

export const ImportPatientModal = (props: Props) => {
  const history = useHistory();
  const { claimCheckKey } = useParams<ParamTypes>();
  
  const {
    header = messages.header,
    copy = messages.copy,
    topLinkText = messages.topLinkText,
    orText = messages.orText,
    bottomLinkText = messages.bottomLinkText,
    topLink,
    bottomLink,
  } = props;

  const modalStyleProps = {
    widthStyle: props.styles?.widthStyle || "464px",
    heightStyle: props.styles?.heightStyle || "382px",
    xTopStyle: props.styles?.xTopStyle || "37px",
    xRightStyle: props.styles?.xRightStyle || "37px",
  };

  return (
    <ModalWrapper onClose={(e) => back(e, history, props.returnTo)} styles={modalStyleProps} cancellable={true}>
      <ImportPatientContainer>
        <h2>{header}</h2>
        <CopyDiv>
          {copy.partOne}
          <span>{copy.bold}</span>
          {copy.partTwo}
        </CopyDiv>
        <Link to={topLink || `/client-linking/${claimCheckKey}`}>{topLinkText}</Link>
        <div className="separator">{orText}</div>
        <Link to={bottomLink || `/patient-import/${claimCheckKey}`}>{bottomLinkText}</Link>
      </ImportPatientContainer>
    </ModalWrapper>
  );
};
