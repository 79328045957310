import styled from "styled-components/macro";

export const Container = styled.div`
  display: flex;
  margin-bottom: 32px;
  padding-bottom: 15px;
  border: 1px solid black;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    color: ${({ theme }) => theme.secondaryTextColor};
    font-size: 12;
  }
  h3 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
  }
`;

export const HelpLabel = styled.label`
  display: block;
  margin-top: 4px;
  font-size: 12px;
  color: #737373;
`;

export const ErrorContainer = styled.div`
  padding: 20px;
  p {
    color: red;
    font-size: 20px;
    font-weight: bold;
  }
`;

export const FormContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
`;

export const InputWrapper = styled.div`
  padding-bottom: 10px;
  text-align: left;

  button {
    border: none;
  }

  input {
    font-family: arial;
    margin-bottom: 2px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  select#notesToClientShortcuts,
  select#sigShortcuts {
    width: 57%;
    margin-right: 11px;
  }
`;

export const FooterContainer = styled.div`
  margin-top: 20px;
  justify-content: end;
  display: flex;
`;
