import { Action, Actions, SessionState } from "./types";

const initState: SessionState = {
  session: { flow: null, pimsType: null, claimCheckKey: null, vetFilter: null },
};

export const sessionReducer = (state: SessionState = initState, action: Action): SessionState => {
  switch (action.type) {
    case Actions.SET_SESSION:
      return {
        ...state,
        session: action.session,
      };
    case Actions.REMOVE_SESSION:
    default:
      return initState;
  }
};
