import { FrontendConfig } from "../types";

const config: FrontendConfig = {
  pimsRxBasePath: "http://localhost:3001",
  covetrusDomain: "covetrus.com",
  auth0ApiDomain: 'https://covetrus-int.auth0.com',
  auth0ClientId: 'Sz8RVMoGLCEVR0OaSCa5Xeztm5yPb6dK',
  logicalEnv: "local"
};

export default config