/*
 * UnitsPerFillField Messages
 *
 * This contains all the text for the UnitsPerFillField container.
 */

export default {
  unitsPerFillLabel: (units: string | null) => `${units || "Units"}`,
  unitsPerFillMessage: (defaultSupplyCount: number | 0, unitsPerFill: number) => 
  `Total Quantity: ${ (defaultSupplyCount * unitsPerFill) || 0}`
};
