export enum AcknowledgeError {
  CLIENT_AGE = "CLIENT_AGE",
  SPECIES_MISMATCH = "SPECIES_CONFLICT",
  WEIGHT_OUT_OF_RANGE = "WEIGHT_CONFLICT",
  DIRECTIONS = "DIRECTIONS",
  ESSENTIAL_COPY = "ESSENTIAL_COPY"
}

export enum TreatmentType {
  ACUTE = "ACUTE_CARE",
  DIET = "DIET",
  DISEASE = "DISEASE_MANAGEMENT",
  PREVENTATIVE = "PREVENTATIVE",
  SUPPLEMENT = "SUPPLEMENT",
  CHRONIC = "CHRONIC",
}

interface BaseProps {
  acknowledgeError: string;
}

export type Props = BaseProps;
