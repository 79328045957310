import { isEmpty, map } from "ramda";
import * as React from "react";
import ModalWrapper from "../StyledComponents/Modal/Modal";
import { 
    HeaderText,
    HeaderContainer,
    InputWrapper,
    FormContainer,
    FooterContainer
} from "./styles";
import { Button } from "../StyledComponents/Button/Button";
import { TextArea } from "../StyledComponents/TextArea/TextArea";
import { InputLabel } from "../StyledComponents/InputLabel/InputLabel";
import { ApprovalT, FullPracticeUserT, UserVetDelegateLevelT, VetsForPracticeUserT, useGetNotesShortcutsQuery, usegetAuthorizingVetsForUserQuery } from "../../data/gql/apiTypes";
import messages from "./messages";
import DropdownSelector from "../DropdownSelector";
import { DefaultReasons } from "../DeclineModal/types";
import * as r from "ramda";
import { DELEGATION_TYPE_CODE } from "../../common/enum";

interface Props {
    onSubmit: (reason: string, note: string, declineVet: string) => void;
    onClose: () => void;
    intialVet: string;
    approval: ApprovalT;
    user: FullPracticeUserT;
}

const DeclineModal = (props: Props) => {
    const [reason, setReason] = React.useState("");
    const [note, setNote] = React.useState("");
    const [noteShortcut, setNoteShortcut] = React.useState("");
    const [hasSubmitted, setHasSubmitted] = React.useState(false);
    const [declineVet, setDeclineVet] = React.useState(props.intialVet);

    const {data: dataVets } = usegetAuthorizingVetsForUserQuery({});
    const gpmAuthorizingVets = dataVets?.retrieveVetsForPracticeUser as VetsForPracticeUserT[];

    const delegates = props.user.vetDelegateLevels as UserVetDelegateLevelT[];
    if (props.approval.prescriptionTypeCode === DELEGATION_TYPE_CODE.US_OTC_AOTC_PREVENTATIVE_RX){
    delegates.filter(delegate => delegate.delegationTypeCode = DELEGATION_TYPE_CODE.US_OTC_AOTC_PREVENTATIVE_RX)
    }

    const getVets = () => {
        const vets: Array<any> = [];
        const vetGuids: Array<string> = [];
        gpmAuthorizingVets?.forEach(VetsForPracticeUserT => {
          let vetGuid = VetsForPracticeUserT?.veterinarianKey.toString() || "";
          if ((vetGuid && !(vetGuids.includes(vetGuid)) && delegates.find(element => element.veterinarianKey === vetGuid)) || vetGuid === props.user.veterinarianKey) {
            vets.push({ value: vetGuid, label: VetsForPracticeUserT?.givenName + " " + VetsForPracticeUserT?.familyName || "" });            
            vetGuids.push(vetGuid);    
            
          }
        });
        vets.sort((a, b) => a.label < b.label ? -1 : 1);
        return vets;
      }


    return (
        <ModalWrapper cancellable onClose={() => props.onClose()} styles={{heightStyle: '684px', widthStyle: '600px'}}>
            <HeaderContainer>
                <HeaderText>
                    <h1>{messages.headerTitle}</h1>
                </HeaderText>
            </HeaderContainer>
            <HeaderText>
                <p>{messages.headerDescription}</p>
            </HeaderText>
            <FormContainer>
                <InputWrapper>
                    <DropdownSelector 
                        id="reason"
                        inputLabel={"Reason for Decline"}
                        value={reason}
                        errors={(hasSubmitted && isEmpty(reason)) ? "Reason is a required field" : undefined}
                        required
                        onChange={(e: React.BaseSyntheticEvent) => setReason(e.target.value)}
                        opts={r.map(
                            (reason) => ({
                                value: reason,
                                label: DefaultReasons[reason as keyof typeof DefaultReasons],
                              }),
                            Object.keys(DefaultReasons),
                          )}
                    />
                </InputWrapper>
                <InputWrapper>
                    <DropdownSelector
                        id="noteShortcut"
                        inputLabel={"Notes to Client Shortcuts"}
                        value={noteShortcut}
                        onChange={(e: React.BaseSyntheticEvent) => {
                            setNote(e.target.value && e.target.value.length > 512 ? e.target.value.substr(0,512) : e.target.value);
                            setNoteShortcut(e.target.value);
                        }}
                        useOptionsGetter={useGetNotesShortcutsQuery}
                        queryVars={{}}
                        getOptions={(results) =>
                            map(
                                (row) => ({
                                    value: row?.note || "",
                                    label: row?.label || "",
                                }),
                                results.retrieveNotesShortcuts || [],
                            )
                        }
                    />
                </InputWrapper>
                <InputWrapper>
                        <InputLabel htmlFor="note" required={true} label={"Note to Client"} />
                        <TextArea 
                            id="note"
                            name="note"
                            fullWidth
                            height={100}
                            errors={(hasSubmitted && isEmpty(note)) ? "Client note is a required field" : undefined}
                            onChange={(c) => {
                                setNoteShortcut("");
                                setNote(c.target.value)
                            }}
                            value={note}
                            maxLength={512}
                        />
                    </InputWrapper>
                    <InputWrapper>
                    <DropdownSelector
                        id="vet"
                        inputLabel={"Veterinarian"}
                        value={declineVet}
                        onChange={(e: React.BaseSyntheticEvent) => { setDeclineVet(e.target.value);}}
                        initialValWhenLoaded={props.intialVet}
                        disabled={props.approval.isControlled || false}
                        opts={getVets()}               
                        noSpacer={true}
                    />
                    </InputWrapper>
            </FormContainer>
            <FooterContainer>
                <Button btnStyle={"secondary"} style={{marginRight: 10}} onClick={() => props.onClose()}>Cancel</Button>
                <Button 
                    style={{paddingLeft: 15, paddingRight: 15}} 
                    onClick={() => {
                        setHasSubmitted(true);
                        props.onSubmit(reason, note, declineVet)
                    }}
                    disabled={isEmpty(reason) || isEmpty(note) || isEmpty(declineVet)}>
                    Submit
                </Button>
            </FooterContainer>
        </ModalWrapper>
    );
}

export default DeclineModal;