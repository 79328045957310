import { useContext, useEffect } from "react";
import { flowType, removeSession, setSession } from "../context/actions";
import { GlobalStateContext } from "../context/state";
import { ClaimCheckT } from "../data/gql/apiTypes";
import { useHasChanged } from "./useHasChanged";
import { exists } from "../util";
import { ListType } from "../components/RxList/types";

export const useSetSessionEffect = (
 claimCheck: ClaimCheckT | null
) => {
  const context = useContext(GlobalStateContext);
  const dispatch = context.dispatch;
  const hasCCGuidChanged = useHasChanged(claimCheck?.claimCheckKey);
  const hasPimsTypeChanged = useHasChanged(claimCheck?.pims);

  const shouldSetSession =
    exists(claimCheck) && (hasCCGuidChanged || hasPimsTypeChanged);

  useEffect(() => {
    if (claimCheck && shouldSetSession) {
      
      const flowType = getFlowType(claimCheck);
      dispatch && dispatch(setSession({ flow: flowType, pimsType: claimCheck.pims, claimCheckKey: claimCheck.claimCheckKey}));
    }
  }, [dispatch, claimCheck, shouldSetSession]);
};

export const useSetVetFilterSessionEffect = ( vetFilter: string | null ) => {
  const context = useContext(GlobalStateContext);
  const dispatch = context.dispatch;
  const session = context.state.session;

  const shouldSetSession = vetFilter !== session.vetFilter;

  useEffect(() => {
    shouldSetSession && dispatch && dispatch(setSession({ flow: session.flow, pimsType: session.pimsType, claimCheckKey: session.claimCheckKey, vetFilter: vetFilter }))
  }, [vetFilter, dispatch, session, shouldSetSession]);
}

export const useSetListTypeSessionEffect = ( listType: ListType | null ) => {
  const context = useContext(GlobalStateContext);
  const dispatch = context.dispatch;
  const session = context.state.session;

  const shouldSetSession = listType !== session.listType;

  useEffect(() => {
    shouldSetSession && dispatch && dispatch(setSession({ flow: session.flow, pimsType: session.pimsType, claimCheckKey: session.claimCheckKey, vetFilter: session.vetFilter, listType: listType }))
  }, [listType, dispatch, session, shouldSetSession]);
}

const getFlowType = (claimCheck: ClaimCheckT) => {
  if (claimCheck.eventType === "pendingRxEvent") {
    return flowType.pending;
  } else {
    if (claimCheck.payload.product) {
      return flowType.linked;
    }
    return flowType.unlinked;
  }
}

export const useRemoveSessionExceptVetAndListTypeFilterEffect = () => {
  const context = useContext(GlobalStateContext);
  const dispatch = context.dispatch;
  const vetFilter = context.state.session.vetFilter;
  const listType = context.state.session.listType;

  useEffect(() => {
    dispatch && dispatch(setSession({ flow: null, pimsType: null, claimCheckKey: null, vetFilter: vetFilter, listType: listType }));
  }, [dispatch, vetFilter, listType]);
}

export const useRemoveSessionEffect = () => {
  const context = useContext(GlobalStateContext);
  const dispatch = context.dispatch;

  useEffect(() => {
    dispatch && dispatch(removeSession());
  }, [dispatch]);
};

export const useSession = () => {
  const context = useContext(GlobalStateContext);

  return { claimCheckKey: context.state.session.claimCheckKey, pimsType: context.state.session.pimsType, flow: context.state.session.flow, vetFilter: context.state.session.vetFilter , listType: context.state.session.listType};
};
