import React from "react";
import { Props } from "./types";
import { CloseToastBtn, ToastContent, ToastContainer, ToastNotification } from "./styles";
import { InfoCircledThin } from "../../assets/InfoCircled";

export const generateToastID: () => number = () => Math.floor(Math.random() * 1000);

export const Toast = (props: Props) => {
  const { message, kind } = props;
  return (
    <ToastContainer className="top-center">
      <ToastNotification kind={kind} className="top-center">
        <ToastContent>
          <InfoCircledThin />
          <p>{message}</p>
        </ToastContent>
        <CloseToastBtn>X</CloseToastBtn>
      </ToastNotification>
    </ToastContainer>
  );
};
