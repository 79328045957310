import styled from "styled-components/macro";
import { ToastType } from "../../reducers/toast";

type ToastProps = {
  kind?: ToastType["kind"];
};

export const ToastContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 9999;
  display: flex;
  justify-content: center;
`;

export const ToastNotification = styled.div<ToastProps>`
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  line-height: 1.5;
  display: flex;
  width: 480px;
  height: 48px;
  background-color: ${({ theme, kind }) => {
    return kind === "error" ? theme.errorToastColor : theme.successToastColor;
  }};
  border-radius: 4px;
  align-items: center;
  margin-top: 72px;
  padding: 0 12px;
  justify-content: space-between;
  height: auto;
`;

export const ToastContent = styled.div`
  display: flex;
  align-items: center;

  svg {
    height: 24px;
    width: 24px;
    margin-right: 12px;
  }
`;

export const CloseToastBtn = styled.button`
  border: none;
  background: none;
  color: #fff;
  cursor: pointer;
  height: 16px;
  width: 16px;
  font-size: 16px;
  padding: 0;
`;
