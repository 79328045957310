/**
 *
 * UnitsPerFillField
 *
 */

import React, { useState } from "react";
import { InfoCircled } from "../../../../assets/InfoCircled";
import { parseNumberFromString } from "../../../SearchGrid";
import { InputUnitsPerFill } from "../../../StyledComponents/Input/Input";
import { InputLabel } from "../../../StyledComponents/InputLabel/InputLabel";
import Tooltip from "../../../Tooltip";
import { InputWrapper, SpacerErrorDiv, FillMessageLabel } from "../../styles";
import FieldError from "../FieldError";
import messages from "./messages";
import { Props } from "./types";

const UnitsPerFillField = (props: Props) => {
  const { handleChange, errors, defaultSupplyCount, packageQuantity, defaultPerFill, disabled } = props;
  const { unitsPerFillLabel } = messages;
  const [unitsPerFill, setUnitsPerFill] = useState(defaultPerFill);

  return (
    <InputWrapper>
      <InputLabel htmlFor="units" label={unitsPerFillLabel(packageQuantity)} required={true} errors={errors.units} />
      <InputUnitsPerFill
        id="units"
        name="units"
        fullWidth
        type="number"
        min={1}
        max={999}
        onChange={(e) => {
          const regex = /^[1-9][0-9]{0,2}$/;
          if (!e.target.value.length || regex.test(e.target.value)) {
            setUnitsPerFill(parseNumberFromString(e.target.value));
            handleChange(e);
          }
        }}
        value={unitsPerFill}
        errors={errors.units}
        inputMode="numeric"
        disabled={props.disabled}
      />
      <div style={{ display: "flex", flexFlow: "row" }}>
      {<FillMessageLabel>{messages.unitsPerFillMessage(defaultSupplyCount, unitsPerFill)}</FillMessageLabel>}
      {(disabled) ?
        <div style={{ marginTop: "4px", marginLeft: "4px" }}>
          <Tooltip message={"To update qty per fill, use the back arrow and select make changes"} position="right" >
            <InfoCircled />
          </Tooltip>
        </div>
        : null}
      </div>
      {errors.units ? <FieldError error={errors.units} hideAlertIcon /> : <SpacerErrorDiv />}
    </InputWrapper>
  );
};

export default UnitsPerFillField;
