import styled from "styled-components/macro";
import { Card } from "../StyledComponents/Card/Card";
import { Button } from "../StyledComponents/Button/Button";

export const PRIMARY_CLASS = "primary";

interface ColDivProps {
  size?: string;
}

interface FlexColumnWrapperProps {
  direction: "row" | "column";
}

interface FilterHeaderProps {
  isNoMargin?: boolean;
}

interface MaybePatientWeight {
  patientWeight?: string | null;
}

export const ColumnDiv = styled.div<ColDivProps>`
  margin: 10px;
  flex: ${(props) => props.size || 1};
  color: ${({ theme }) => theme.primaryTextColor};
`;

export const FlexColumnWrapper = styled.div<FlexColumnWrapperProps>`
  display: flex;
  width: 100%;
  text-align: left;
  justify-content: space-between;
  padding: 20px;
  flex-direction: ${({ direction }) => direction};
`;

export const FooterDiv = styled.div`
  display: inline-flex;
  padding: 24px;
  width: 100%;
  justify-content: space-between;
`;

export const PaginationElements = styled.div`
  display: inline-flex;
  width: 55%;
  justify-content: space-between;
  align-items: center;
`;

export const PageSelect = styled.div`
  color: ${({ theme }) => theme.primaryTextColor};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PageSelectFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  select {
    cursor: pointer;
    height: 34px;
    width: 46px;
    background-color: #f5f5f5;

    -webkit-appearance: none;
    border-radius: 0;
    border: none;
    font-size: 14px;
    color: #495361;
    padding: 7.5px;
  }

  svg {
    margin-left: -20px;
    pointer-events: none;
  }
`;

export const ImportButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const PageLimit = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

export const FilterBaseDiv = styled.div`
  select {
    cursor: pointer;
    height: 36px;
    width: 124px;
    color: #495361;
    border-bottom: 2px solid #4d5051;
  }
`;

export const FilterRowDiv = styled(FilterBaseDiv)`
  display: inline-block;
  margin-left: 40px;
`;

export const FilterFlexColDiv = styled(FilterBaseDiv)`
  display: flex;
  align-items: center;
  select {
    margin-right: 24px;
  }
`;

export const WeightCheckboxDiv = styled.div<MaybePatientWeight>`
  display: flex;
  height: 34px;
  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    cursor: inherit;
    pointer-events: none;
  }

  label {
    display: flex;
    color: ${({ patientWeight }) => (patientWeight ? "#000" : "#bfbfb6")};
    cursor: ${({ patientWeight }) => (patientWeight ? "pointer" : "default")};
  }

  svg {
    margin-right: 12px;
  }
`;

export const CheckBoxEmptyDiv = styled.div<MaybePatientWeight>`
  display: inline-block;
  margin-right: 12px;
  height: 20px;
  width: 20px;
  border: ${({ patientWeight }) => `2px solid ${patientWeight ? "#1F9798" : "#bfbfb6"}`};
`;

export const FooterText = styled.div`
  color: ${(props) => props.theme.primaryTextColor};
  font-size: 12px;
  padding: 2px;
`;

export const PageButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  &:disabled {
    fill: #efefef;
    cursor: default;
    color: #efefef;
  }
`;

export const SearchHeader = styled.h1`
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryTextColor};
  margin-bottom: 38px;
  svg {
    vertical-align: middle;
    margin-right: 5px;
  }
`;

export const CompoundProductTableGroupHeader = styled.h1`
  font-size: 18px;
  line-height: 20px;
  color: ${({ theme }) => theme.primaryTextColor};
  margin: 15px;
  svg {
    vertical-align: middle;
    margin-right: 5px;
  }
  text-align: left;
`;

export const NoFilterSearchResults = styled.div`
  margin-top: 82px;
  float: right;
`;

export const FilterHeader = styled.h2<FilterHeaderProps>`
  font-size: 16px;
  line-height: 16px;
  color: #767676;
  text-transform: uppercase;
  margin-bottom: 22px;
  margin-left: ${({ isNoMargin }) => (isNoMargin ? "0" : "40px")};
  font-weight: bold;
`;

export const Container = styled(Card)`
  border: solid 1px #e5e5e5;
  margin: 20px;
  max-width: 720px;
`;

export const SearchBarContainer = styled.div`
  display: flex;

  span {
    padding-left: 8px;
    flex: 1;
    padding-top: 5px;
  }
`;

export const SearchBar = styled.div`
  border: solid 1px #b5b5b6;
  border-radius: 3px;
  flex: 2;

  display: inline-flex;
  width: 98%;
  justify-content: space-between;

  input {
    width: 100%;
    border: none;
    padding: 5px;
    padding-left: 15px;
    padding-right: 18px;
    font-size: 14px;
  }
`;

export const AutoComplete = styled.div`
  width: 100%;

  input {
    height: 33px;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
    margin-left: 0px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}
`;

export const SearchButton = styled.button`
  background-color: #b5b5b6;
  width: 32px;
  height: 33px;
  border: none;
  margin-left: -50px;
  margin-top: -0.5px;
  padding-top: 4px;
  cursor: pointer;
`;

export const ProductRequestButton = styled(Button)`
  margin: 20px;
  padding: 10px;
  cursor: pointer;
`;

export const NoResultsDiv = styled.div`
  margin-top: 40px;
  margin-top: 20px;
  max-width: 66%;
  padding: 40px;
  color: ${({ theme }) => theme.primaryTextColor};

  h3 {
    margin-bottom: 15px;
    font-size: 24px;
  }

  p {
    font-style: italic;
  }
`;

export const TableContainer = styled.table`
  width: 100%;
  color: ${({ theme }) => theme.primaryTextColor};
  border-collapse: collapse;
  table-layout: fixed;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: visible;
  .primary {
    color: ${({ theme }) => theme.accentColor};
    font-weight: bold;
    padding-left: 16px;
  }
  .selected {
    outline: none;
    background-color: ${({ theme }) => theme.accentColor} !important;
    color: ${({ theme }) => theme.backgroundSecondary};
    .primary {
      color: ${({ theme }) => theme.backgroundSecondary};
    }
    use {
      fill: ${({ theme }) => theme.backgroundSecondary};
    }
    path {
      fill: ${({ theme }) => theme.backgroundSecondary};
    }
  }
`;

export const TableHeader = styled.thead`
  font-size: 14px;
  border-top: 1px solid ${({ theme }) => theme.tableBorderColor};
  border-bottom: 1px solid ${({ theme }) => theme.tableBorderColor};
  button {
    color: ${({ theme }) => theme.primaryTextColor};
    padding-left: 0;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    background: none;
    & svg {
      margin-left: 8px;
    }
  }
  .spacer {
    width: 65px;
  }
  .primary button {
    color: ${({ theme }) => theme.accentColor};
  }
`;

export const ColumnLabel = styled.th`
  padding: 16px 0;
  text-align: left;

  .resizer {
    width: 20px;
    height: 18px;
    float: right;
  };

  .resizerIndicator {
    width: 1px;
    height: 18px;
    text-align: center;
    background: grey;
    margin: auto;
    opacity: 0.4;
  }

  .resizer:hover {
    cursor: col-resize;
  }
`;

type TableRowProps = {
  isLinked?: boolean;
};

export const TableRow = styled.tr<TableRowProps>`
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.tableBorderColor};
  &:hover {
    background-color: ${({ theme, isLinked }) => (isLinked ? undefined : theme.tableHoverColor)};
    cursor: ${({ isLinked }) => (isLinked ? undefined : "pointer")};
  }
  &:nth-child(odd) {
    &:hover {
      background-color: ${({ theme, isLinked }) => (isLinked ? undefined : theme.tableHoverColor)};
    }
    background: #fafaf8;
  }
  td.primary {
    color: ${({ theme, isLinked }) => (isLinked ? theme.primaryTextColor : undefined)};
  }
  .selected {
    outline: none;
    background-color: ${({ theme }) => theme.accentColor};
    color: ${({ theme }) => theme.backgroundSecondary};
    .primary {
      color: ${({ theme }) => theme.backgroundSecondary};
    }
    use {
      fill: ${({ theme }) => theme.backgroundSecondary};
    }
  }
`;

type DataCellProps = {
  overflow?: boolean;
};

export const DataCell = styled.td<DataCellProps>`
  padding: 10px 4px 10px 0;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: ${({ overflow }) => (overflow ? "visible" : "hidden")};
`;

export const SpeciesCell = styled.div`
  padding-left: 16px;
`;

export const FooterWrapper = styled.div`
  border-top: solid 1px #b5b5b6;
`;

export const FooterButtonDiv = styled.div`
  button {
    margin: 10px;
    padding: 10px;
  }
`;
