/*
 * ProductUnlinked Messages
 *
 * This contains all the text for the ProductUnlinked container.
 */

export default {
  description: "Search for a product in Rx Management to recommend.",
  header: "Select a Product",
  noMatchHeader: "This patient isn’t linked.",
  noMatchText: "You will be able to link them in the next step.",
  weightSyncFailedMessage: (pimsName: string) =>  `Patient weight was not updated because ${pimsName || 'PIMS'} has no weight for this patient.`,
  updateWeightSuccessful: "Successfully updated patient weight.",
  updateWeightFailure: "Failed to update patient weight.",
  updateRxWithUnlinkedProductFailure: "Failed to update RX with unlinked product.",
  searchProductsFailure: "Error searching products.",
  fetchLinkedPatientQueryFailure: "Error retrieving linked patient details.",
  fetchLinkedClientQueryFailure: "Error retrieving linked client details.",
  fetchBaseProductQueryFailure: "Error retrieving base product details.",
  fetchProductDetailsQueryFailure: "Error retrieving product details."
};
