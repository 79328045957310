export default {
  header: "Import New Patient",
  copy: {
    partOne:
      "You can either add this patient to an existing client, or you can add a new client and patient. Importing a patient or client ",
    bold: "cannot",
    partTwo: " be undone.",
  },
  topLinkText: "This is an existing client",
  orText: "or",
  bottomLinkText: "This is a new client",
};
