import React, { useState } from "react";
import * as r from "ramda";
import CollapsableCard from "../../components/CollapsableCard";
import { DescriptionComponent } from "../../components/DescriptionComponent";
import SearchGrid from "../../components/SearchGrid/index";
import { Container } from "../../components/StyledComponents/Container/Container";
import { WarningCard } from "../../components/WarningCard/WarningCard";

import { Redirect, useParams } from "react-router-dom";
import { ClientIcon } from "../../assets/ClientIcon";
import { getDisplayPimsName } from "../../components/PimsLogo/getDisplayPimsName";
import PimsLogo from "../../components/PimsLogo/PimsLogo";
import { SpeciesPicker } from "../../components/Species/Species";
import { GlobalStateContext } from "../../context/state";
import {
  ClaimCheckPatientT,
  searchGPMClientsQuery,
  searchGPMClientsQueryResult,
  usesearchGPMClientsQuery,
} from "../../data/gql/apiTypes";
import { exists, nullMap } from "../../util";
import messages from "./messages";
import commonMessages from "../../common/messages";
import { ContainerProps } from "../../PrivateRoute/types";
import { hasFeature } from "../../config/featureFlags";
import Loading from "../../components/StyledComponents/Loading/Loading";
import { useDispatchErrors } from "../../hooks/useDispatchErrors";

export type SearchClientResult = searchGPMClientsQuery["searchClients"][0];

const ClientLinking = (props: ContainerProps) => {
  const { claimCheckKey } = useParams<{ claimCheckKey: string }>();

  const [collapsed, setCollapsed] = useState(true);
  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  const [selectedClient, setSelectedClient] = useState<SearchClientResult | null>(null);
  const retrieveClaimCheckByGuid = props.claimCheck;
  const client = retrieveClaimCheckByGuid?.payload.client;
  const patient = retrieveClaimCheckByGuid?.payload.patient;
  const initSearchStr = (hasFeature("suggestPatientLinks") && 
                              client?.firstName && client?.lastName ? `${client.firstName} ${client.lastName}` : "");
  const [suggestSearch, setSuggestSearch] = useState(initSearchStr !== "");
  const [searchCriteria, setSearchCriteria] = useState({ query: initSearchStr, pageNumber: 1, pageSize: null, filter: { isActive: true, isActivePatient: true, isLinkedPatient: null} });

  const qResult = usesearchGPMClientsQuery({
    variables: { criteria: searchCriteria },
    skip: !exists(searchCriteria.query),
  });

  const context = React.useContext(GlobalStateContext);
  const maybePimsType = context.state.session.pimsType || props.claimCheck?.pims || "";
  const displayPimsName = getDisplayPimsName(maybePimsType);

  const getResultsProp = (qResult: searchGPMClientsQueryResult) => {
    if (qResult.loading) {
      return { kind: "loading" as "loading" };
    }
    if (exists(qResult.error)) {
      return { kind: "error" as "error", error: qResult.error };
    }
    return (
      nullMap((it) => ({ kind: "loaded" as "loaded", results: it.searchClients }), qResult.data) || {
        kind: "noop" as "noop",
      }
    );
  };

  useDispatchErrors([
    { error: qResult.error, message: messages.searchErrorText }
  ]);

  // prevent flicker (render) of the rest of the page when in the middle of a suggest-a-link search
  if (getResultsProp(qResult).kind === 'loading' && initSearchStr !== "" && suggestSearch) {
    return <Loading fullPage />;
  }

  if (getResultsProp(qResult).kind === 'loaded' && suggestSearch) {
    setSuggestSearch(false);
  }

  if (hasFeature("suggestPatientLinks") && getResultsProp(qResult).kind === "loaded" && 
      qResult.data?.searchClients.length === 1 &&
      exists(client) && !exists(selectedClient)) {
      setSelectedClient(qResult.data?.searchClients[0]);
      setCollapsed(false);
  }

  if (!props.claimCheck) return <WarningCard reason={"Error fetching claim check"} />;
  if (!claimCheckKey) return <WarningCard reason={"No Claim Check GUID present on URL"} />;
  if (exists(redirectTo)) return <Redirect to={redirectTo} />;

  if (!retrieveClaimCheckByGuid) return <WarningCard reason={`Claim check not found with guid: ${claimCheckKey}`} />;

  if (!patient) return <WarningCard reason={"No patient found for Claim Check"} />;

  const fetchPatientWeight = (patient: ClaimCheckPatientT) => {
    return patient.currentWeight != null && patient.currentWeightUnit != null
      ? `${parseFloat(patient.currentWeight).toFixed(3)} ${patient.currentWeightUnit}`
      : "";
  };
  const weightWithUnits = fetchPatientWeight(patient);
  const collapsedText = `${weightWithUnits ? weightWithUnits + " • " : ""}${client?.lastName + ", " || ""}${
    client?.firstName || ""
  }`;
  const loggedInUsername = props.user?.username;

  const collapsableCardProps = {
    headerIcon: <SpeciesPicker species={patient?.speciesDescription} useThumbnail={false} />,
    headerText: patient?.name, // TODO: default?
    buttonText: messages.comparisonButton,
    collapsed,
    setCollapsed,
    collapsedText,
    comparison: {
      noMatchText: messages.comparisonNoMatchText,
      leftColumnIcon: <PimsLogo pimsType={maybePimsType} />,
      linkSelectionMade: false,
      cvetMatchFound: exists(selectedClient),
      showPimsData: true,
      continue: () => {},
      comparisonGrid: [
        { label: commonMessages.name, leftValue: patient?.name },
        { label: commonMessages.client, 
          leftValue: `${client?.firstName || ""} ${client?.lastName || ""}`, 
          rightValue: `${selectedClient?.givenName || ""} ${selectedClient?.familyName || ""}` },
        { label: commonMessages.email, leftValue: client?.email, rightValue: selectedClient?.emailAddress },
        { label: commonMessages.phone, leftValue: client?.phoneNumber, rightValue: selectedClient?.phoneNumber },
        { label: commonMessages.species, leftValue: patient?.speciesDescription },
        { label: commonMessages.weight, leftValue: `${weightWithUnits || ""}` },
        { label: commonMessages.sex, leftValue: patient?.gender },
        { label: commonMessages.dob, leftValue: patient?.dateOfBirth },
      ],
    }
  };

  const gridHeaders = [
    {
      label: commonMessages.name,
      dataGetter: (clientResult: SearchClientResult) => {
        return `${clientResult.givenName || ""} ${clientResult.familyName || ""}`;
      },
      width: "40%",
      primary: true,
    },
    {
      label: commonMessages.phone,
      dataGetter: "phoneNumber" as "phoneNumber",
      width: "20%",
    },
    {
      label: commonMessages.email,
      dataGetter: "emailAddress" as "emailAddress",
      width: "40%",
    },
  ];

  return (
    <Container>
      <DescriptionComponent 
        header={messages.header}
        description={messages.description(displayPimsName)}
        pimsType={displayPimsName}
        username={loggedInUsername || ""}
        claimCheckKey={claimCheckKey}
        />
      <CollapsableCard {...collapsableCardProps} />
      <SearchGrid<SearchClientResult>
        searchHelpText={messages.searchHelpText}
        noMatchTypeText={messages.searchNoMatchTypeText}
        noMatchButtonText={messages.searchNoMatchButtonText}
        headerText={messages.searchHeaderText}
        noResultsText={messages.searchNoResultsText(displayPimsName)}
        initialSearchString={initSearchStr}
        gridSchema={gridHeaders}
        searchResults={getResultsProp(qResult)}
        commitSearch={(newCriteria: any) => setSearchCriteria(newCriteria)}
        autocomplete={false}
        selectResult={(client: SearchClientResult) => {
          setSelectedClient(client);
          setCollapsed(false);

          setRedirectTo(`/patient-import/${claimCheckKey}?existingClient=${client.key}`);
        }}
        guidRowSelected={nullMap(r.prop("key"), selectedClient)}
        headerIcon={<ClientIcon />}
        noSearchFilter={true}
        buttonRouteTo={{
          pathname: `/patient-import/${claimCheckKey}`
        }}
      />
    </Container>
  );
};

export default ClientLinking;
