import { Environment, FrontendConfig } from "./types";

declare global {
  interface Window {
    _env_?: {
      REACT_APP_LOGICALENV?: string;
      REACT_APP_LOCALDEBUG?: "true";
      REACT_APP_USER_DOMAIN_LOGICAL_ENV?: "dev";
    };
    onCloseMessage?: () => void;
  }
}

export const logicalEnv: Environment = (window && window._env_ && window._env_.REACT_APP_LOGICALENV as Environment) || "local";

export const userDomainLogicalEnv : Environment = (window && window._env_ && window._env_.REACT_APP_USER_DOMAIN_LOGICAL_ENV as Environment) || "dev";

export const localDebugEnabled = !!(window && window._env_ && window._env_.REACT_APP_LOCALDEBUG) || false;

export const settings: FrontendConfig = require(`./frontend/${logicalEnv}`).default;

export const covetrusDomain = settings.covetrusDomain || "covetrus.com";

export const pimsRxBasePath = localDebugEnabled? "http://localhost:3001" : settings.pimsRxBasePath || "https://pimsrx-dev.covetrus.com";

export const termsOfServiceLink = "https://covetrus.com/legal/covetrus-vrxpro-terms-of-service/";

export const privacyPolicyLink = "https://covetrus.com/legal/privacy-policy/";

export const practiceEnrollmentsLink = "https://dev-wordpress-geb-vfcb2b-cdn.s3.amazonaws.com/wp-content/uploads/PRACTICE-ENROLLMENTS.pdf";

export const requestProductLink = "https://www.surveymonkey.com/r/TT2HTY5";

export const betaFeedbackLink = "https://www.surveymonkey.com/r/MMW6D3H";

export default settings;