import * as React from "react";

type Props = {
  className?: string;
  height?: string;
  width?: string;
  direction: "left" | "right" | "up" | "down";
};

const toDegrees = (dir: "left" | "right" | "up" | "down") => {
  switch (dir) {
    case "left":
      return 90;
    case "right":
      return 270;
    case "down":
      return 0;
    case "up":
      return 180;
  }
};

export const ArrowIcon = (props: Props) => (
  <svg
    width={props.width || "12px"}
    height={props.height || "12px"}
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    transform={`rotate(${toDegrees(props.direction)})`}
  >
    <title>arrow</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="translate(-584.000000, -347.000000)" fill="#354580" fillRule="nonzero">
        <polygon
          id="arrow-down"
          points="608 359.514196 605.885 357.399196 597.5 365.769196 597.5 347.514196 594.5 347.514196 594.5 365.769196 586.13 357.384196 584 359.514196 596 371.514196"
        ></polygon>
      </g>
    </g>
  </svg>
);
