import * as React from "react";

type Props = {
  className?: string;
};

export const SearchIcon = (props: Props) => (
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>search</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="translate(-752.000000, -172.000000)" fill="#F5F5F5">
        <path
          d="M769.155403,180.906523 L768.065866,180.906523 L767.687136,181.282488 C769.025043,182.842605 769.838765,184.865131 769.838765,187.081127 C769.838765,192.007089 765.845626,196 760.919383,196 C755.993139,196 752,192.007089 752,187.081127 C752,182.155166 755.993139,178.162255 760.919383,178.162255 C763.135506,178.162255 765.156775,178.974558 766.716981,180.311017 L767.095712,179.935052 L767.095712,178.848322 L773.954031,172 L776,174.045852 L769.155403,180.906523 L769.155403,180.906523 Z M760.666667,181.333333 C757.352,181.333333 754.666667,184.018667 754.666667,187.333333 C754.666667,190.646667 757.352,193.333333 760.666667,193.333333 C763.98,193.333333 766.666667,190.646667 766.666667,187.333333 C766.666667,184.018667 763.98,181.333333 760.666667,181.333333 L760.666667,181.333333 Z"
          id="search"
          transform="translate(764.000000, 184.000000) scale(1, -1) translate(-764.000000, -184.000000) "
        ></path>
      </g>
    </g>
  </svg>
);
