import styled from "styled-components/macro";

export const TooltipContainer = styled.div<{ isElHalfWidth?: boolean }>`
  position: relative;
  overflow: visible;

  .tooltip-top {
    bottom: 100%;
    left: 50%;
    padding-bottom: 9px;
    transform: translateX(-50%);

    &::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-top: 9px solid rgba(0, 0, 0, 0.7);
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .tooltip-bottom {
    top: 100%;
    right: -120px;
    padding-top: 9px;
    transform: ${({ isElHalfWidth }) => {
      return `translateX(-${isElHalfWidth ? "160%" : "50%"})`;
    }};

    &::after {
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid rgba(0, 0, 0, 0.7);
      top: 0;
      right: 12px;
      transform: translateX(-50%);
    }
  }

  .tooltip-left {
    top: 50%;
    right: 100%;
    padding-right: 9px;
    transform: translateY(-50%);

    &::after {
      border-left: 9px solid rgba(0, 0, 0, 0.7);
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  .tooltip-right {
    top: 50%;
    left: 100%;
    padding-left: 9px;
    transform: translateY(-50%);

    &::after {
      border-right: 9px solid rgba(0, 0, 0, 0.7);
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
`;

export const TooltipBubble = styled.div`
  min-width: 220px;
  max-width: 320px;
  position: absolute;
  z-index: 10;
  &::after {
    content: "";
    position: absolute;
  }
`;

export const TooltipTrigger = styled.span<{ isTriggerFullWidth?: boolean }>`
  display: inline-block;
  width: ${({ isTriggerFullWidth }) => (isTriggerFullWidth ? "100%" : "")};
`;

export const TooltipMessage = styled.div`
  background: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  color: #fff;
  line-height: 1.4;
  padding: 0.75em;
  text-align: center;
  white-space: pre-wrap;
`;
