import * as React from "react";
import { useLocation } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import Loading from "../components/StyledComponents/Loading/Loading";
import { FullPracticeUserT, ClaimCheckT,
  useRetrievePracticeUserWithClaimCheckLazyQuery } from "../data/gql/apiTypes";
import { exists } from "../util";
import { checkForLUTAndLogin, getValidGuid } from "./utils";
import { useSetSessionEffect } from "../hooks/sessionFlow";

const bypassPermissionCheck = ["/pending-rx"];

const PrivateRoute = ({ children, ...rest }: any) => {
  const location = useLocation();
  const claimCheckKey = rest.computedMatch.params?.claimCheckKey;

  const [loginFailure, setLoginFailure] = React.useState(false);
  const [user, setUser] = React.useState<FullPracticeUserT | null>(null);
  const [claimCheck, setClaimCheck] = React.useState<ClaimCheckT | null>(null);
  const [loading, setLoading] = React.useState(true);

  const [getUserWithClaimCheck] = useRetrievePracticeUserWithClaimCheckLazyQuery({
    onCompleted: data => {
      if (!user) {
        setUser(data.retrievePracticeUser);
        setClaimCheck(data.retrieveClaimCheckByGuid);
        setLoading(false);
      }
    },
    onError: e => {
      setLoginFailure(true);
      setLoading(false);
    },
    fetchPolicy: "cache-first"
  });

  const pimsType = new URLSearchParams(location.search).get("pt");
  const isPulseIntegration = pimsType?.includes("evet");

  React.useEffect(() => {
    async function loginAndFetchUserWithClaimCheck() {
      const containsLoginToken = exists(getValidGuid(location));
      if (containsLoginToken) {
        if ((await checkForLUTAndLogin(location)) === false) {
          setLoginFailure(true);
          setLoading(false);
          return;
        }
      }
      if (claimCheckKey) {
        getUserWithClaimCheck({
          variables: {claimCheckKey}
        });
      } else {
        setLoginFailure(true);
        setLoading(false);
      }
    }
    loginAndFetchUserWithClaimCheck();
  }, [location, claimCheckKey]);
  useSetSessionEffect(claimCheck);

  if (loading) {
    return <Loading fullPage />;
  }

  if (!user && isPulseIntegration) {
    return (
      <Redirect
        to={{
          pathname: "/user-not-set-up",
          state: { from: location },
        }}
        push
      />
    );
  }

  if (loginFailure) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
        push
      />
    );
  }

  if (user && claimCheck && user.animalCareBusinessKey !== claimCheck.animalCareBusinessKey) {
    return (
      <Redirect
        to={{
          pathname: "/forbidden",
          state: { from: location },
        }}
        push
      />
    );
  }

  if (user && !user.hasAccessPrescriptionPermission && !bypassPermissionCheck.includes(rest.path[1])) {
    return (
      <Redirect
        to={{
          pathname: "/no-permissions",
          state: { from: location },
        }}
        push
      />
    );
  }

  return (
    <>
      {user && <input type="hidden" id="userNameFullName" value={`${user.givenName} ${user.familyName}`} />}
      <Route
        {...rest}
        render={() => {
          return React.cloneElement(children, { user, claimCheck });
        }}
      />
    </>
  );
};

export default PrivateRoute;
