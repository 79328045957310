import * as React from "react";

type Props = {
  width?: string;
  height?: string;
};

export const CheckmarkCircledIcon = (props: Props) => (
  <svg
    width={props.width ? `${props.width}px` : "20px"}
    height={props.height ? `${props.height}px` : "20px"}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>checkmark-circled</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="translate(-584.000000, -172.000000)" fill="#39B54A" fillRule="nonzero">
        <path
          d="M596,172 C602.624,172 608,177.376 608,184 C608,190.624 602.624,196 596,196 C589.376,196 584,190.624 584,184 C584,177.376 589.376,172 596,172 Z M602.708,178 L593.6,186.740883 L589.292,182.618042 L587.6,184.241843 L593.6,190 L604.4,179.635317 L602.708,178 Z"
          id="checkmark-circled"
        ></path>
      </g>
    </g>
  </svg>
);
