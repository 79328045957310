import { logicalEnv } from "./frontend";
import { AllFeatureFlags, FeatureFlagNames } from "./types";

// Feature flags are all defined (centrally) here. At a glance, see all (flags X envs)
// Each feature flags is typed in the string union 'FeatureFlags'
// To add a new feature flag extend the FeatureFlags union and then
// specify the flag here in allFeatures
const allFeaturesFlags: AllFeatureFlags = {
  suggestPatientLinks: {
    envFlags: { local: true, dev: true, qa: true, stage: true, prod: true },
    description: "automatically suggest patient links for unlinked patients",
  },
  renewals: {
    envFlags: { local: true, dev: true, qa: true, stage: true, prod: true },
    description: "Show renewals list and other renewal related routes"
  }
};
Object.freeze(allFeaturesFlags);

// Usage: hasFeature("somePig")? isWilber: isSomeOtherPig;
export const hasFeature = (featureName: FeatureFlagNames): boolean => allFeaturesFlags[featureName].envFlags[logicalEnv];
