import styled from "styled-components/macro";
import { SuccessCard } from "../RxSuccess/styles";

export const WarningCardWrapper = styled(SuccessCard)`
  margin-top: 80px;

  button {
    margin: 10px;
    padding: 9px;
  }
`;