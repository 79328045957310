import { ApprovalsT, FullPracticeUserT, RenewalsT} from "../../data/gql/apiTypes";

export interface RxListProps {
    approvals?:  Array<ApprovalsT>;
    renewals?: Array<RenewalsT>;
    onRxClick?: (pendingRx: ApprovalsT) => void;
    onRxRenewalClick?: (pendingRx: RenewalsT) => void;
    listType: ListType;
    isFilterChanged: boolean;
    setIsFilterChanged: React.Dispatch<React.SetStateAction<boolean>>;
    user: FullPracticeUserT;
}

export enum ListType {
    APPROVALS = "client requests",
    RENEWALS = "renewals"
}