import styled from "styled-components/macro";
import { Card } from "../../components/StyledComponents/Card/Card";

export const HeaderContainer = styled(Card)`
  border: solid 1px #e5e5e5;
  margin-top: 20px;
  max-width: 720px;
  padding: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;