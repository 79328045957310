import * as r from "ramda";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PageLeft, PageRight } from "../../assets/Chevron";
import { SearchIcon } from "../../assets/SearchIcon";
import { TriangleDownIcon } from "../../assets/TriangleDownIcon";
import { exists } from "../../util";
import Loading from "../StyledComponents/Loading/Loading";
import { Filters } from "./Filters";
import messages from "./messages";
import {
  ColumnDiv,
  Container,
  DataCell,
  FlexColumnWrapper,
  FooterDiv,
  FooterText,
  NoFilterSearchResults,
  NoResultsDiv,
  PageButton,
  PageSelect,
  PageSelectFilter,
  PaginationElements,
  ProductRequestButton,
  SearchBar,
  SearchBarContainer,
  SearchButton,
  SearchHeader,
  CompoundProductTableGroupHeader,
  TableContainer,
  TableRow,
  PRIMARY_CLASS,
  FooterButtonDiv,
  FooterWrapper,
  ImportButton,
  AutoComplete,
} from "./styles";
import TruncateStyled from "../../styles/TruncateStyled";
import { Props, SchemaColumn } from "./types";
import { isDataColumn, SortOpts } from "./utils";
import { SortableResizableTableHeader } from "./SortableResizableTableHeader";
import { Button } from "../StyledComponents/Button/Button";
import AutoSuggest from "react-autosuggest";
import { debounce } from "lodash";

const DEFAULT_PAGE_SIZE = 10;
const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];
const DEFAULT_STARTING_PAGE = 1;

const SPECIES_OPTIONS = [
  { value: "ALL", label: "All" },
  { value: "DOG", label: "Dog" },
  { value: "CAT", label: "Cat" },
  { value: "HORSE", label: "Horse" },
  { value: "RABBIT", label: "Rabbit" },
  { value: "REPTILE", label: "Reptile" },
  { value: "BIRD", label: "Bird" },
  { value: "SMALL_MAMMAL", label: "Small Mammal" },
  { value: "OTHER", label: "Other" },
];

const isRowLinked = <T extends { key: string }>(props: Props<T>, row: T): boolean =>
  exists(props.getIsLinked) && props.getIsLinked(row);

export const parseNumberFromString = (str: string) => {
  return parseInt(r.replace(/[^\d.-]/g, "", str), 10);
};

function SearchGrid<T extends { key: string }>(props: Props<T>) {
  const {
    headerText = messages.headerText,
    noResultsText,
    noMatchText = messages.noMatchText,
    searchHelpText = messages.searchHelpText,
    noMatchTypeText = messages.noMatchTypeText,
    noMatchButtonText = messages.noMatchButtonText,
    commitSearch,
    buttonText,
    buttonRouteTo,
    autocomplete,
    autocompleteSuggestions,
    preventSearchWhenPressingEnterKeyForAutocomplete,
    isCompoundProduct
  } = props;
  const [searchString, setSearchString] = useState(props.initialSearchString || "");
  const [speciesFilter, setSpeciesFilter] = useState(r.toUpper(props.filters?.patientSpecies || ""));
  const [weightFilter, setWeightFilter] = useState(props.filters?.patientWeight || "");
  const [minStrengthFilter, setMinStrengthFilter] = useState("");
  const [maxStrengthFilter, setMaxStrengthFilter] = useState("");
  const [familyCode, setFamilyCode] = useState(props.filters?.familyCode || "");
  const [lastSearched, setLastSearched] = useState("");
  const [sortBy, setSortBy] = useState<SortOpts<T> | null>(null);
  const [pageOptions, setPageOptions] = useState({ pageNum: DEFAULT_STARTING_PAGE, pageSize: DEFAULT_PAGE_SIZE });
  const [alwaysRenderSuggestions, setAlwaysRenderSuggestions] = useState(false);
  
  const getNumSearchResultsMessage = (searchResults: Props<T>["searchResults"]) => {
    let numResults;
    switch (searchResults.kind) {
      case "loading":
      case "error":
        return null;
      case "noop":
        numResults = 0;
        break;
      case "loaded":
        const { results } = searchResults;
        numResults = results.length;
        return `${numResults} result${numResults === 1 ? "" : "s"} total`;
    }
  };

  const determineSortImplementation = (row: T, sortBy: SortOpts<T>) => {
    // If we are sorting based on weight, convert to number
    if (sortBy.label === "Weight") {
      return Number(r.prop("weight" as keyof T, row));
    }
    return typeof sortBy.sortVal === "function" ? sortBy.sortVal(row) : r.prop(sortBy.sortVal, row);
  };

  const applySortFilter = (rows: T[]) => {
    return exists(sortBy)
      ? r.sort(
        sortBy.descending
          ? r.ascend((it) => determineSortImplementation(it, sortBy))
          : r.descend((it) => determineSortImplementation(it, sortBy)),
        rows,
      )
      : r.identity(rows);
  };

  const changeSort = (newSort: SortOpts<T>) => {
    setSortBy({
      sortVal: newSort.sortVal,
      descending: newSort.descending,
      label: newSort.label,
    });
  };

  const toPageSet = r.slice(
    pageOptions.pageSize * (pageOptions.pageNum - 1),
    pageOptions.pageSize * pageOptions.pageNum,
  );

  // title attribute only accepts string or undefined
  // check types and only return when string else implicitly return undefined
  const getTitle = (dataGetter: SchemaColumn<T>["dataGetter"], row: T): string | undefined => {
    let title;
    if (typeof dataGetter !== "function") {
      title = row[dataGetter];
      if (typeof title === "string") {
        return title;
      }
    }
    if (typeof dataGetter === "function") {
      title = dataGetter(row);
      if (typeof title === "string") {
        return title;
      }
    }
  };

  const getCellData = (row: T) =>
    props.gridSchema.map((column, i: number) => (
      <DataCell
        /* With the title attribute the browser renders a tooltip on hover for free
          This comes in handy when the text is too big for its allotted space and we render ellipsis */
        title={getTitle(column.dataGetter, row)}
        key={`dataCell-${i}${row.key}`}
        className={column.primary ? PRIMARY_CLASS : undefined}
        overflow={isDataColumn(column) ? undefined : column.overflow}
      >
        {typeof column.dataGetter === "function" ? column.dataGetter(row) : r.prop(column.dataGetter, row)}
      </DataCell>
    ));

  const mapSearchResultRow = (row: T) => (
    <TableRow
      key={r.propOr(null, "key", row)}
      onClick={(e) => {
        // If row is linked do nothing onClick
        if (isRowLinked(props, row)) {
          return null;
        }
        e.stopPropagation();
        props.selectResult(row);
      }}
      className={props.guidRowSelected && row.key === props.guidRowSelected ? "selected" : undefined}
      isLinked={isRowLinked(props, row)}
    >
      {getCellData(row)}
    </TableRow>
  );

  const mapSearchResults = (rows: T[]) =>
    r.map((row) => {
      return mapSearchResultRow(row);
    }, rows);

  const renderFooterButton =
    buttonText && buttonRouteTo ?
      <FooterButtonDiv>
        <Link to={buttonRouteTo}>
          <Button onClick={buttonRouteTo?.handleClick}>{buttonText}</Button>
        </Link>
      </FooterButtonDiv> : null;

  const renderFooterButtonAlone =
    buttonText && buttonRouteTo ? (
      <FooterWrapper>
        <FooterDiv>
          <PaginationElements>
            <PageSelect />
            <PageSelectFilter />
          </PaginationElements>
          <ImportButton>
            {renderFooterButton}
          </ImportButton>
        </FooterDiv>
      </FooterWrapper>
    ) : null;

  const noResultsDiv = (
    <>
      <NoResultsDiv id={"NoResultsDiv"}>
        <h3>{"No results to show yet."}</h3>
        <p>{noResultsText}</p>
      </NoResultsDiv>
      {renderFooterButtonAlone}
    </>
  );

  const noneFoundDiv =
    props.searchResults.kind === "loaded" && !exists(props.searchResults.results) ? (
      <NoResultsDiv id={"NoneFoundDiv"}>
        <h3>No Matches for <TruncateStyled>{lastSearched}</TruncateStyled></h3>
        <p>{noMatchText(noMatchTypeText, lastSearched)}</p>
        {props.buttonRouteTo ? (
          <Link to={props.buttonRouteTo}>
            <ProductRequestButton>{noMatchButtonText}</ProductRequestButton>
          </Link>
        ) : null}
      </NoResultsDiv>
    ) : null;

  const compoundProductTableHeader = (
    <SortableResizableTableHeader
      gridSchema={props.gridSchema}
      changeSort={changeSort}
      allowResizing={true}
      hideSortIcon={true}
    />
  );

  const processCompoundProductData = (r.pipe as any)(
    (r.sortBy as any)((r.pipe as any)(r.path(['size']), parseFloat)),
    (r.groupBy as any)(r.prop("normalizedStrengthWithUOM")),
    r.toPairs,
    r.map(r.zipObj(["normalizedStrengthWithUOM", "childProducts"])),
    r.reject(r.propEq('normalizedStrengthWithUOM', "null null")),
    (r.sortBy as any)((r.pipe as any)(r.path(['normalizedStrengthWithUOM']), parseFloat))
  );

  const renderCompoundProductTable = (props: Props<T>) => {
    switch (props.searchResults.kind) {
      case "loading":
        return <><TableContainer cellSpacing={0}>{compoundProductTableHeader}<Loading width={"718px"} /></TableContainer></>; 
      case "error":
        return <><TableContainer cellSpacing={0}>{compoundProductTableHeader}<div>there has been an error... {props.searchResults.error.message}</div></TableContainer></>;
      case "noop":
        return null;
      case "loaded":
        const compoundProducts = processCompoundProductData(props.searchResults.results);
        
        return exists(compoundProducts)? (
          <div>
            {r.map((item) => {
              return exists(item) && exists(item.childProducts) ? (
                <>
                <CompoundProductTableGroupHeader>{item.normalizedStrengthWithUOM} {item.childProducts[0].familyName}</CompoundProductTableGroupHeader>
                <TableContainer cellSpacing={0}>
                {compoundProductTableHeader}
                <tbody>
                  {r.pipe< T[], ReactElement[]>(
                    mapSearchResults,
                  )(item.childProducts)}
                </tbody>
              </TableContainer></>
                
              ) : null;
            }, compoundProducts)}
          </div>
        ) : <><TableContainer cellSpacing={0}>{compoundProductTableHeader}</TableContainer>{(noneFoundDiv || noResultsDiv)}</>;
    }
  };

  const renderTableBody = (props: Props<T>) => {
    switch (props.searchResults.kind) {
      case "loading":
        return <Loading width={"718px"} />;
      case "error":
        return <div>there has been an error... {props.searchResults.error.message}</div>;
      case "noop":
        return null; // returning null allows us to do a shortcircuit check outside of the TableContainer ~line 270 - If this function returns a falsey val
      // render the no results div - if not, short cuircuit
      case "loaded":
        return exists(props.searchResults.results)
          ? r.pipe<T[], T[], T[], ReactElement[]>(
            applySortFilter,
            toPageSet,
            mapSearchResults,
          )(props.searchResults.results)
          : null;
    }
  };

  const executeAutoCompleteSearch = (value: string) => {
    let obj = {
      query: value,
      pageNumber: pageOptions.pageNum,
      pageSize: pageOptions.pageSize,
      filter: {
        isActive: true,
        isLinked: false,
        isClientActive: true,
        isActivePatient: true,
        isLinkedPatient: false,
        isCompound: null,
        categoryCode: null,
        treatmentCategoryCode: null,
        speciesCode: speciesFilter && speciesFilter !== "ALL" ? speciesFilter : null,
        brandCode: null,
        primaryManufacturerCode: null,
        catalogCategoryCode: null,
        baseProductStatus: null,
        baseProductKey: null,
        familyCode: null,
        weight: null,
        minStrength: null,
        maxStrength: null
      }
    };
    props.commitAutoCompleteSearch && props.commitAutoCompleteSearch(obj);
    setAlwaysRenderSuggestions(false);
  }
  
  const debounceAutoComplete = useCallback(debounce(executeAutoCompleteSearch, 200), [speciesFilter]);

  useEffect(() => {
    return () => {
      debounceAutoComplete.cancel();
    }
  }, [debounceAutoComplete]);

  const executeSearch = (newSearchString?: string, familyCode?: string) => {
    const commitSearchString = newSearchString !== undefined && newSearchString !== "" ? newSearchString : searchString;
    !props.noSearchFilter
      ? commitSearch({
        query: commitSearchString,
        pageNumber: pageOptions.pageNum,
        pageSize: pageOptions.pageSize,
        filter: {
          isActive: true,
          isLinked: null,
          isClientActive: true,
          isActivePatient: true,
          isLinkedPatient: null,
          isCompound: isCompoundProduct || null,
          categoryCode: null,
          treatmentCategoryCode: null,
          speciesCode: speciesFilter && speciesFilter !== "ALL" ? speciesFilter : null,
          brandCode: null,
          primaryManufacturerCode: null,
          catalogCategoryCode: null,
          baseProductStatus: null,
          baseProductKey: props.initialSearchBaseProduct || null,
          familyCode: familyCode || "",
          weight: r.replace(/[^\d.-]/g, "", weightFilter),
          minStrength: r.replace(/[^\d.-]/g, "", minStrengthFilter),
          maxStrength: r.replace(/[^\d.-]/g, "", maxStrengthFilter)
        }
      })
      : commitSearch({
        query: commitSearchString,
        pageNumber: pageOptions.pageNum,
        pageSize: pageOptions.pageSize,
        filter: {
          isActive: true,
          isLinked: null,
          isClientActive: true,
          isActivePatient: true,
          isLinkedPatient: null,
          isCompound: isCompoundProduct || null,
          categoryCode: null,
          treatmentCategoryCode: null,
          speciesCode: speciesFilter.length && speciesFilter !== "ALL" ? speciesFilter : null,
          brandCode: null,
          primaryManufacturerCode: null,
          catalogCategoryCode: null,
          baseProductStatus: null,
          baseProductKey: props.initialSearchBaseProduct || null,
          familyCode: familyCode || "",
          weight: r.replace(/[^\d.-]/g, "", weightFilter),
          minStrength: r.replace(/[^\d.-]/g, "", minStrengthFilter),
          maxStrength: r.replace(/[^\d.-]/g, "", maxStrengthFilter)
        }
      });
    setLastSearched(commitSearchString);
    setPageOptions({ pageNum: 1, pageSize: pageOptions.pageSize });
  };

  useEffect(() => {
    executeSearch(searchString, familyCode);  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [speciesFilter, weightFilter, minStrengthFilter, maxStrengthFilter]);


  const renderTableFooter = (props: Props<T>, numResults: number) => {
    const FINAL_PAGE = Math.ceil(numResults / pageOptions.pageSize);
    return (
      <FooterDiv>
        <PaginationElements>
          <PageSelect>
            <FooterText>{`Page ${pageOptions.pageNum} of ${FINAL_PAGE}`}</FooterText>

            <PageButton
              disabled={pageOptions.pageNum === 1}
              onClick={(e) => setPageOptions({ pageNum: pageOptions.pageNum - 1, pageSize: pageOptions.pageSize })}
            >
              <PageLeft />
            </PageButton>
            <PageButton
              disabled={pageOptions.pageNum === FINAL_PAGE}
              onClick={(e) => setPageOptions({ pageNum: pageOptions.pageNum + 1, pageSize: pageOptions.pageSize })}
            >
              <PageRight />
            </PageButton>
          </PageSelect>
          <PageSelectFilter>
            <FooterText>{`Rows per page:`}</FooterText>
            <select
              name={"pageSize"}
              value={pageOptions.pageSize}
              onChange={(e) => setPageOptions({ pageSize: Number(e.target.value), pageNum: 1 })}
            >
              {r.map(
                (pageSizeOption) => (
                  <option key={`pagesize-${pageSizeOption}`} value={pageSizeOption}>
                    {pageSizeOption}
                  </option>
                ),
                PAGE_SIZE_OPTIONS,
              )}
            </select>
            <TriangleDownIcon />
          </PageSelectFilter>
        </PaginationElements>
        <ImportButton>
          {renderFooterButton}
        </ImportButton>
      </FooterDiv>
    );
  };

  const renderInput = () => {
    if (autocomplete) {
      return (
        <AutoComplete>
          <AutoSuggest
            inputProps={{
              disabled: props.disableSearch,
              placeholder: searchHelpText,
              value: searchString,
              onChange: (_, { newValue }) => {
                setSearchString(newValue);
              },
              onKeyDown: e => {
                if (e.key === "Enter") {
                  if (preventSearchWhenPressingEnterKeyForAutocomplete) {
                    setAlwaysRenderSuggestions(true);
                  }
                  else {
                    e.preventDefault();
                    setFamilyCode("");
                    executeSearch();
                  }
                }
              }
            }}
            shouldRenderSuggestions={(value) => value.trim().length > 1}
            focusInputOnSuggestionClick={!preventSearchWhenPressingEnterKeyForAutocomplete}
            alwaysRenderSuggestions={alwaysRenderSuggestions}
            suggestions={autocompleteSuggestions?.kind === "loaded" ? autocompleteSuggestions.results : []}
            renderSuggestion={suggestion => <span style={{ cursor: "pointer" }}>{suggestion.name}</span>}
            onSuggestionsFetchRequested={({ value }) => {
              debounceAutoComplete(value);
            }}
            onSuggestionsClearRequested={() => { }}
            onSuggestionSelected={(_, { suggestion }) => {
              setSearchString(suggestion.name);
              setFamilyCode(suggestion.familyCode)
              if(props.setIsCompoundProduct) props.setIsCompoundProduct(suggestion.isCompound);
              executeSearch(suggestion.name, suggestion.familyCode);
            }}
            getSuggestionValue={suggestion => suggestion.name}
          />
        </AutoComplete>
      )
    } else {
      return (
        <input
          disabled={props.disableSearch}
          type="search"
          inputMode="text"
          id={"SearchInput"}
          value={searchString}
          placeholder={searchHelpText}
          onChange={(e) => setSearchString(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setFamilyCode("");
              executeSearch(searchString);
            }
          }}
        />
      )
    }
  };

  return (
    <Container>
      <FlexColumnWrapper direction={props.filters?.displayType || "row"}>
        <ColumnDiv size="2">
          <SearchHeader>
            {" "}
            {props.headerIcon} {headerText}
          </SearchHeader>
          <SearchBarContainer>
            <SearchBar>
              {renderInput()}
              <SearchButton hidden={props.disableSearch || preventSearchWhenPressingEnterKeyForAutocomplete}
                onClick={_ => executeSearch()}>
                <SearchIcon />
              </SearchButton>
            </SearchBar>
            {!props.noSearchFilter ? <span>{getNumSearchResultsMessage(props.searchResults)}</span> : <> </>}
          </SearchBarContainer>
        </ColumnDiv>
        <ColumnDiv>
          {props.noSearchFilter ? (
            <NoFilterSearchResults>{getNumSearchResultsMessage(props.searchResults)}</NoFilterSearchResults>
          ) : (
              <Filters
                searchProps={{
                  setLastSearched,
                  searchString,
                  setSearchString,
                  speciesFilter,
                  setSpeciesFilter,
                  setPageOptions,
                  pageOptions,
                  speciesOptions: SPECIES_OPTIONS,
                  familyCode: props.filters?.familyCode || "",
                  weightFilter,
                  minStrengthFilter,
                  maxStrengthFilter,
                }}
                speciesProps={{
                  patientSpecies: props.filters?.patientSpecies,
                  disabled: props.filters?.speciesDisabled,
                }}
                displayType={props.filters?.displayType}
                patientWeight={props.filters?.patientWeight ?? null}
                setWeightFilter={setWeightFilter}
                setMinStrengthFilter={setMinStrengthFilter}
                setMaxStrengthFilter={setMaxStrengthFilter}
                displayStrengthFilter={isCompoundProduct || false}
                familyCode={familyCode}
                searchResults={props.searchResults} />
            )}

        </ColumnDiv>
      </FlexColumnWrapper>
      <>
        {
        isCompoundProduct ?
        (
          <>
            {renderCompoundProductTable(props)}
          </>
        )
        :
        (
          <>
            <TableContainer cellSpacing={0}>
              <SortableResizableTableHeader
                gridSchema={props.gridSchema}
                sort={sortBy}
                changeSort={changeSort}
                allowResizing={true}
              />
              <tbody>{renderTableBody(props)}</tbody>
            </TableContainer>
            {!renderTableBody(props) && (noneFoundDiv || noResultsDiv)}
          </>
        )}
        {props.searchResults.kind === "loaded" && exists(props.searchResults.results) && !props.isCompoundProduct
          ? renderTableFooter(props, props.searchResults.results.length)
          : null}
      </>
    </Container>
  );
}

export default SearchGrid;
