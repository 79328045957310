import styled from "styled-components/macro";
import { Card } from "../StyledComponents/Card/Card";

export const Container = styled(Card)`
  border: solid 1px #e5e5e5;
  margin: 20px;
  max-width: 720px;
  padding: 20px;
`;

export const FooterButtonsDiv = styled.div`
  margin: 10px 0px 0px 20px;
  padding: 5px 0px 0px 10px;

  button {
    margin: 10px;
    padding: 10px;
  }
`;

export const FlexEndWrapper = styled.div<{ isMatch: boolean }>`
  display: flex;
  justify-content: flex-end;
  ::before {
    ${(props) =>
      props.isMatch
        ? undefined
        : `
        content: "";
        border-top: 1px solid #ccc;
        margin-top: -1px;
        width: 279px;
        display: block;
        position: absolute;
    `}
  }
`;

export const CollapseButton = styled.button`
  border: none;
  font-size: 2em;
  color: ${({ theme }) => theme.primaryTextColor};
  background: none;
  font-weight: bold;
  cursor: pointer;
`;

export const HeaderText = styled.div`
  color: #021660;
  align-items: center;
  display: flex;
  width: 93%;

  h1 {
    margin-left: 10px;
    font-size: 20px;

    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const CollapseDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

export const CollapsedTextDiv = styled.div`
  font-size: 14px;
  color: #4d5051;
  font-weight: normal;
`;

export const WorkflowStatusDropdownWrapper = styled.div`
  width: 150px;
  text-align: left;
  float: right;
`;
