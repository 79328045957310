import * as React from "react";
import { Link } from "react-router-dom";
import { ChevronDown, ChevronUp } from "../../assets/Chevron";
import ProductComparisonGrid from "../ComparisonGrid/index";
import { Button } from "../StyledComponents/Button/Button";
import {
  CollapseButton,
  CollapseDiv,
  CollapsedTextDiv,
  Container,
  FlexEndWrapper,
  FooterButtonsDiv,
  HeaderText
} from "./styles";
import { ProductCollapseCardValues, WorkFlowType } from "./types";
import Loading from "../../components/StyledComponents/Loading/Loading";

const CollapsableCard = (props: ProductCollapseCardValues) => {
  const {
    displayLoading,
    unlinkButtonText,
    buttonRouteTo,
    headerIcon,
    setCollapsed,
    collapsed,
    collapsedText,
    comparison,
    clearSelected,
    headerText,
    actions
  } = props;

  const getPrimaryButtonName = () => {
    if (actions) {
      if (actions.workflowType === WorkFlowType.APPROVALS) {
        return "Approve";
      } else if (actions.workflowType === WorkFlowType.RENEWALS) {
        return "Renew";
      }
    }
    return "";
  }

  const getSecondaryButtonName = () => {
    if (actions) {
        return "Make changes";
    }
    return "";
  }

  const getDeclineButtonName = () => {
    if (actions && actions.workflowType === WorkFlowType.RENEWALS) {
      return "Remove";
    }
    return "Decline";
  }

  return (
    <Container id="CollapsableContainer">
      <CollapseDiv>
        <HeaderText>
          {headerIcon}
          <h1>
            {headerText}
            {!collapsed ? <></> : <CollapsedTextDiv>{collapsedText}</CollapsedTextDiv>}
          </h1>
        </HeaderText>
        <CollapseButton onClick={() => setCollapsed(!collapsed)}>
          {!collapsed ? <ChevronUp /> : <ChevronDown />}
        </CollapseButton>
      </CollapseDiv>
      {!collapsed ? (
        <>
          {displayLoading ? (
            <Loading />
          ): (
            <>
              <ProductComparisonGrid {...comparison} /> 
            </>
          )}
          <FlexEndWrapper isMatch={comparison.linkSelectionMade}>
            {comparison.linkSelectionMade ? (
              <FooterButtonsDiv>
                <Button
                  id="DeselectButton"
                  btnStyle={"secondary"}
                  disabled={displayLoading}
                  onClick={() => (clearSelected ? clearSelected() : null)}
                >
                  Clear Selection
                </Button>
                <Button 
                  id="LinkAndContinueButton" 
                  onClick={comparison.continue}
                  disabled={displayLoading}>
                  Link and Continue
                </Button>
              </FooterButtonsDiv>
            ) : unlinkButtonText && buttonRouteTo ? (
              <FooterButtonsDiv>
                <Link to={buttonRouteTo}>
                  <Button 
                    onClick={buttonRouteTo?.handleClick}
                    disabled={displayLoading}>{unlinkButtonText}</Button>
                </Link>
              </FooterButtonsDiv>
            ) : actions ? (
              <FooterButtonsDiv>
                <Button
                  id="DeclineButton"
                  btnStyle={"none"}
                  disabled={actions.disableDeclineButton}
                  onClick={actions.handleDeclineButtonClick}
                >
                  {getDeclineButtonName()}
                </Button>
                <Button
                  id="SecondaryButton"
                  btnStyle={"secondary"}
                  disabled={actions.disableSecondaryButton}
                  onClick={actions.handleSecondaryButtonClick}
                >
                  {getSecondaryButtonName()}
                </Button>
                <Button
                  id="PrimaryButton"
                  btnStyle={"primary"}
                  disabled={actions.disablePrimaryButton}
                  onClick={actions.handlePrimaryButtonClick}
                >
                  {getPrimaryButtonName()}
                </Button>
              </FooterButtonsDiv>
            ) : (
              <>
              {
                unlinkButtonText && (
                  <FooterButtonsDiv>
                    <Button 
                      onClick={buttonRouteTo?.handleClick}
                      disabled={displayLoading}>{unlinkButtonText}</Button>
                  </FooterButtonsDiv>
                )
              }
              </>
            )}
          </FlexEndWrapper>
        </>
     ): (
        <> </>
      )}
    </Container>
  );
};

export default CollapsableCard;
