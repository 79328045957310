import React, { ReactElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GlobalState } from "../../store/types";
import { toast as toastify } from "react-toastify";
import { removeToast } from "../../actions/toast";
import Toast from "../../components/Toast";
import { StyledContainer } from "./styles";

const ToastNotificationListener = (): ReactElement => {
  const dispatch = useDispatch();
  const toastState = useSelector((state: GlobalState) => state.toast);

  const toasts = toastState.toasts;

  const processNotificationQueue = () => {
    if (toasts && toasts.length) {
      for (const toast of toasts) {
        toast.kind
          ? toastify[toast.kind](<Toast message={toast.message} id={toast.id} kind={toast.kind} />)
          : toastify(toast.message);
        dispatch(removeToast(toast.id));
      }
    }
  };

  processNotificationQueue();
  return <StyledContainer closeButton={false} hideProgressBar={true} position={"top-center"} autoClose={5000} />;
};

export default ToastNotificationListener;
