export default {
  birthday: "Birthday",
  dob: "Date of Birth",
  name: "Name",
  form: "Form",
  email: "Email",
  client: "Client",
  clientName: "Client Name",
  weight: "Weight",
  phone: "Phone",
  sex: "Sex",
  sexAltered: "Sex Altered",
  speciesType: "Species Type",
  species: "Species",
  strength: "Strength",
  quantity: "Product Quantity",
  orderedQuantity: "Order Quantity",
  totalQuantity: "Total Quantity",
  packageQty: "Package Quantity",
  info: "Info",
};

export const avimarkCloseWarningMessage =
  "Are you sure you want to close the window? Your prescription is not complete and will not be sent.";

export const pendingRequestNotAvailable = "Pending Request Not Available";

export const prescriptionStatusUpdated = "The status on this prescription has been updated by another user.";

export const prescriptionExpired = "This prescription has expired and cannot be updated.";
