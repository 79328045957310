import * as React from "react";
import Modal from "./Modal";
import ModalWrapper from "../StyledComponents/Modal/Modal";
import Loading from "../StyledComponents/Loading/Loading";
import { FormError } from "../FormError/FormError";
import { useProductDetailsByKeyQuery } from "../../data/gql/apiTypes";
import { ModalProps } from "./types";

export default ({ baseProductKey, productKey, claimCheckKey, onClose }: ModalProps) => {
  const { loading, error, data } = useProductDetailsByKeyQuery({
    variables: { baseProductKey: baseProductKey, productKey: productKey, claimCheckKey: claimCheckKey },
  });

  if (loading) {
    return (
      <ModalWrapper>
        <Loading />
      </ModalWrapper>
    );
  }

  if (error) {
    return (
      <ModalWrapper onClose={() => onClose()} cancellable={true}>
        <FormError reason={"Error fetching product details"} />
      </ModalWrapper>
    );
  }

  const product = data?.getProduct;

  if (!product) {
    return (
      <ModalWrapper onClose={() => onClose()} cancellable={true}>
        <FormError reason={`Error fetching product with key ${productKey}`} />
      </ModalWrapper>
    );
  }

  return <Modal product={product} onClose={onClose} />;
};
