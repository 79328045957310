export interface ErrorType {
  upstreamErrorSubstring: string;
  errorCopy: string;
}

export interface SubmitErrorTypes {
  dupeEmaiError: ErrorType;
  credInstallationError: ErrorType;
  patientIdNotFound: ErrorType;
}

export default {
  dupeEmaiError: {
    upstreamErrorSubstring: "Client already exists with email address",
    errorCopy: "Client with email address already exists",
  },
  credInstallationError: {
    upstreamErrorSubstring: "The supplied credentials do not have access to this installation",
    errorCopy: "The supplied credentials do not have access to this installation",
  },
  patientIdNotFound: {
    upstreamErrorSubstring: "The VDS patient string does not exist on the remote PIMS system with installation",
    errorCopy: "The claim check's vds patient id was not found in DVM",
  },
} as SubmitErrorTypes;
