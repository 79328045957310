import styled from "styled-components/macro";
import { InputErrorDiv as InputErrorDivBase } from "../../containers/PatientImport/styles";

export const InputWrapper = styled.div<{ noSpacer?: boolean }>`
  padding-bottom: ${props => props.noSpacer ? '0px' : '10px'};

  button {
    border: none;
  }

  input {
    font-family: arial;
    margin-bottom: 2px;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  select#notesToClientShortcuts,
  select#sigShortcuts {
    width: 57%;
    margin-right: 11px;
  }
`;

export const SpacerErrorDiv = styled.div`
  padding: 0;
  height: 20px;
`;

export const InputErrorDiv = styled(InputErrorDivBase)`
  min-height: 20px;
`;