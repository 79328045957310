import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { settings } from "./config/frontend";
import { Auth0Provider, AppState } from "@auth0/auth0-react";
import App from "./App/App";
import ContextProvider from "./context/state";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/store";
import { getTheme } from "./styles/theme";
import { WrappedApolloProvider } from "./WrappedApolloProvider";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const onRedirectCallback = (appState: AppState | undefined) => {
  history.replace(appState?.returnTo || window.location.pathname);
  //need to do a reload to get rid of the blank screen
  window.location.reload();
};

const authorizationParams = {
  redirect_uri: window.location.origin,
  audience: 'https://delorean-corporate-portal',
};

ReactDOM.render(
  <React.StrictMode>
    {/* Configure the SDK by wrapping the app in Auth0Provider */}
    <Auth0Provider
      domain={settings.auth0ApiDomain}
      clientId={settings.auth0ClientId}
      authorizationParams={authorizationParams}
      cacheLocation="memory"
      useRefreshTokens
      useRefreshTokensFallback
      onRedirectCallback={onRedirectCallback}
    >
      <ReduxProvider store={configureStore()}>
        <BrowserRouter>
          <WrappedApolloProvider>
            <ContextProvider>
              <ThemeProvider theme={getTheme()}>
                <Route path={"/"}>
                  <App />
                </Route>
              </ThemeProvider>
            </ContextProvider>
          </WrappedApolloProvider>
        </BrowserRouter>
      </ReduxProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
