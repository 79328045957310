import { ADD_TOAST, REMOVE_TOAST } from "../actions/toast";
import { AnyAction } from "redux";

export type ToastType = {
  message: string;
  id: string | number;
  kind?: "success" | "warn" | "error" | "info";
};

export type State = {
  toasts: ToastType[];
};

export default (
  state: State = {
    toasts: [],
  },
  action: AnyAction,
): State => {
  switch (action.type) {
    case ADD_TOAST:
      return {
        ...state,
        toasts: [...state.toasts, action.toast],
      };
    case REMOVE_TOAST:
      return {
        ...state,
        toasts: [...state.toasts.filter((t) => t.id !== action.id)],
      };
    default:
      return state;
  }
};
