import * as React from "react";
import { Props } from "./types";
import { HelpLabel, InputWrapper, SpacerErrorDiv } from "../../styles";
import messages from "../../messages";
import FieldError from "../FieldError";
import { Input } from "../../../StyledComponents/Input/Input";
import { InputLabel } from "../../../StyledComponents/InputLabel/InputLabel";

const NotesToClient = (props: Props) => {
  const { errors, value, onChange } = props;
  return (
    <InputWrapper>
      <InputLabel htmlFor="notesToClient" label={messages.notesToClient} errors={errors} />
      <Input
        id="notesToClient"
        name="notesToClient"
        type="text"
        fullWidth
        onChange={onChange}
        value={value}
        errors={errors}
      />
      <HelpLabel>{messages.optionalHelpText}</HelpLabel>
      {errors ? <FieldError error={errors} /> : <SpacerErrorDiv />}
    </InputWrapper>
  );
};

export default NotesToClient;
