export enum WorkflowStatuses {
  ACTION_NEEDED = "Action Needed",
  UNDER_REVIEW = "Under Review",
  EXAM_NEEDED = "Exam Needed",
  FINAL_APPROVAL = "Final Approval",
  CLIENT_CONTACTED = "Client Contacted",
  EXAM_SCHEDULED = "Exam Scheduled"
}

export interface BaseProps {
  onChange: (e: React.SyntheticEvent) => void;
  value?: string;
  errors?: string;
  required?: boolean;
  defaultValue?: string;
  disabled?: boolean;
}

export type OwnProps = BaseProps;
export type Props = OwnProps;
