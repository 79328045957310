export default {
  patientSuccess: "Patients linked successfully!",
  patientFailure: "Unable to link patient",
  productSuccess: "Products linked successfully!",
  updateWorkflowStatusSuccess: "Updated workflow status successfully!",
  updateWorkflowStatusFailure: "Unable to Update workflow status!",
  declinePrescriptionSuccess: "Successfully declined prescription!",
  declinePrescriptionFailure: "Unable to decline prescription",
  createOrUpdateDraftPrescriptionFailure: "Unable to create or update draft prescription",
  removePrescriptionFailure: "Unable to remove prescription",
  noPermissions: "You do not have permissions to update this request",
  removeRenewalFailure: "Unable to remove renewal"
};
