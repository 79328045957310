import * as React from "react";

type Props = {
  className?: string;
};

export const HelpIcon = (props: Props) => (
  <svg
    width="24px"
    height="25px"
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={props.className}
  >
    <title>question-mark-circled</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="translate(-696.000000, -285.000000)" fill="#B4B4B0" fillRule="nonzero">
        <path
          d="M708,285.346555 C701.376,285.346555 696,290.722555 696,297.346555 C696,303.970555 701.376,309.346555 708,309.346555 C714.624,309.346555 720,303.970555 720,297.346555 C720,290.722555 714.624,285.346555 708,285.346555 Z M709.2,305.746555 L706.8,305.746555 L706.8,303.346555 L709.2,303.346555 L709.2,305.746555 Z M711.684,296.446555 L710.604,297.550555 C709.74,298.426555 709.2,299.146555 709.2,300.946555 L706.8,300.946555 L706.8,300.346555 C706.8,299.026555 707.34,297.826555 708.204,296.950555 L709.692,295.438555 C710.136,295.006555 710.4,294.406555 710.4,293.746555 C710.4,292.426555 709.32,291.346555 708,291.346555 C706.68,291.346555 705.6,292.426555 705.6,293.746555 L703.2,293.746555 C703.2,291.094555 705.348,288.946555 708,288.946555 C710.652,288.946555 712.8,291.094555 712.8,293.746555 C712.8,294.802555 712.368,295.762555 711.684,296.446555 Z"
          id="question-mark-circled"
        ></path>
      </g>
    </g>
  </svg>
);
