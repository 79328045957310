import React from "react";
import { CautionTriangleIcon } from "../../assets/CautionTriangleIcon";
import messages from "./messages";
import { WarningCardWrapper } from "./styles";
import { Props } from "./types";
import { Button } from "../StyledComponents/Button/Button";

export const WarningCard = (props: Props) => {
  const { customHeader, reason, buttons } = props;
  const { header, primaryButtonText, secondaryButtonText } = messages;

  return (
    <WarningCardWrapper>
      <CautionTriangleIcon widthStyle={"80px"} heightStyle={"80px"} />
      {(customHeader && customHeader !== null) ? <h1>{customHeader}</h1> : <h1>{header}</h1>}
      <p>{reason}</p>
      {/* TODO: Wire up buttons to routes with props */}
      {buttons?.show?.secondary ? (
        <Button btnStyle="secondary">{buttons?.text?.primary || secondaryButtonText}</Button>
      ) : null}
      {buttons?.show?.primary ? (
        <Button onClick={buttons?.onClick?.primary}>{buttons?.text?.primary || primaryButtonText}</Button>
      ) : null}
    </WarningCardWrapper>
  );
};
