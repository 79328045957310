import * as React from "react";
import * as r from "ramda";
import { useDispatch } from "react-redux";
import { RxListProps, ListType } from "./types";
import {
  Container,
  ListElement,
  ListElementHeader,
  ListElementBody,
  ListElementBodyLeft,
  ListElementBodyRight,
  NoResultsContainer,
  WorkflowStatusDropdownWrapper
} from "./styles";
import { SpeciesPicker } from "../Species/Species";
import { ChevronRight } from "../../assets/Chevron";
import { TriangleDownIcon } from "../../assets/TriangleDownIcon";
import { PageLeft, PageRight } from "../../assets/Chevron";
import {
  FooterDiv,
  FooterText,
  PageButton,
  PageSelect,
  PageSelectFilter,
  PaginationElements,
} from "../SearchGrid/styles";
import { ApprovalsT, RenewalsT, useUpdateRxWithWorkflowStatusMutation } from "../../data/gql/apiTypes";
import { WorkflowStatuses } from "../CollapsableCard/Subcomponents/WorkflowStatus/types";
import WorkflowStatus from "../CollapsableCard/Subcomponents/WorkflowStatus";
import { NoResults } from "../../assets/NoResults";
import { addToast } from "../../actions/toast";
import { generateToastID } from "../../components/Toast/Toast";
import toastMessages from "../../components/Toast/messages";

const DEFAULT_PAGE_SIZE = 10;
const PAGE_SIZE_OPTIONS = [10, 20, 30, 40, 50];
const DEFAULT_STARTING_PAGE = 1;

const RxList = (props: RxListProps) => {
    const { approvals, renewals, onRxClick, onRxRenewalClick, isFilterChanged, setIsFilterChanged, user } = props;
    const [pageOptions, setPageOptions] = React.useState({pageNum: DEFAULT_STARTING_PAGE, pageSize: DEFAULT_PAGE_SIZE});
    const [shownItems, setShownItems] = React.useState<any>([]);
    const dispatch = useDispatch();
    const listType = props.listType;

  React.useEffect(() => {
    if (isFilterChanged) {
      setPageOptions({ pageNum: DEFAULT_STARTING_PAGE, pageSize: DEFAULT_PAGE_SIZE });
      setIsFilterChanged(false);
    }
    if (listType === ListType.APPROVALS) {
      setShownItems(approvals?.slice(pageOptions.pageSize * (pageOptions.pageNum - 1), pageOptions.pageNum * pageOptions.pageSize));
    } else if (listType === ListType.RENEWALS) {
      setShownItems(renewals?.slice(pageOptions.pageSize * (pageOptions.pageNum - 1), pageOptions.pageNum * pageOptions.pageSize));
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listType, approvals, renewals, pageOptions, isFilterChanged]);

  const [updateRxWorkflowStatus] = useUpdateRxWithWorkflowStatusMutation();

  const getPaginationCount = () => {
    if (listType === ListType.APPROVALS) {
      return approvals?.length || 0;
    } else if (listType === ListType.RENEWALS) {
      return renewals?.length || 0;
    }
    return 0;
  }

  const getUserWorkflowStatusAccess = () => {
    return user.practiceUserTypeCode === "VETERINARIAN" ?
      true :
      Array.isArray(user.vetDelegateLevels) && user.vetDelegateLevels.length !== 0;
  }

  const handleUpdateWorkflowStatus = (key: string, status: string) => {
    updateRxWorkflowStatus({
      variables: {
        key,
        workflowType: listType,
        workflowStatusCode: status
      }
    }).then(() => {
      dispatch(
        addToast({
          message: toastMessages.updateWorkflowStatusSuccess,
          id: generateToastID(),
          kind: "success",
        }),
      );
    })
      .catch((error) => {
        dispatch(
          addToast({
            message: toastMessages.updateWorkflowStatusFailure,
            id: generateToastID(),
            kind: "error",
          }),
        );
        console.error(error);
      });
  }

  const renderTableFooter = (numResults: number) => {
    const FINAL_PAGE = Math.ceil(numResults / pageOptions.pageSize);
    return (
      <FooterDiv>
        <PaginationElements>
          <PageSelect>
            <FooterText>{`Page ${pageOptions.pageNum} of ${FINAL_PAGE}`}</FooterText>

            <PageButton
              disabled={pageOptions.pageNum === 1}
              onClick={() => setPageOptions({ pageNum: pageOptions.pageNum - 1, pageSize: pageOptions.pageSize })}
            >
              <PageLeft />
            </PageButton>
            <PageButton
              disabled={pageOptions.pageNum === FINAL_PAGE}
              onClick={() => setPageOptions({ pageNum: pageOptions.pageNum + 1, pageSize: pageOptions.pageSize })}
            >
              <PageRight />
            </PageButton>
          </PageSelect>
          <PageSelectFilter>
            <FooterText>{`Rows per page:`}</FooterText>
            <select
              name={"pageSize"}
              value={pageOptions.pageSize}
              onChange={(e) => setPageOptions({ pageSize: Number(e.target.value), pageNum: pageOptions.pageNum })}
            >
              {r.map(
                (pageSizeOption) => (
                  <option key={`pagesize-${pageSizeOption}`} value={pageSizeOption}>
                    {pageSizeOption}
                  </option>
                ),
                PAGE_SIZE_OPTIONS,
              )}
            </select>
            <TriangleDownIcon />
          </PageSelectFilter>
        </PaginationElements>
      </FooterDiv>
    );
  };

  return (
    <div>
      <Container>
        {shownItems === undefined || shownItems.length === 0 ? (
          <NoResultsContainer>
            <NoResults />
            <div style={{ marginTop: 30 }}>Great Job! You're all caught up. <div>No open pending {listType}.</div></div>
          </NoResultsContainer>
        ) : (
            <>
              <div>
                {listType === ListType.APPROVALS &&
                  shownItems.length && shownItems.map((item: ApprovalsT) => {
                    return <PendingApprovals item={item} key={item.approvableKey} onClick={onRxClick} handleUpdateWorkflowStatus={handleUpdateWorkflowStatus} userWorkflowStatusAccess={getUserWorkflowStatusAccess()} />
                  })}

                {listType === ListType.RENEWALS &&
                  shownItems.length && shownItems.map((item: RenewalsT) => {
                    return <PendingRenewals item={item} key={item.renewableKey} onClick={onRxRenewalClick} handleUpdateWorkflowStatus={handleUpdateWorkflowStatus} userWorkflowStatusAccess={getUserWorkflowStatusAccess()}/>
                  })} 
              </div>
              {renderTableFooter(getPaginationCount())}
            </>
          )}
      </Container>
    </div>
  )
}

const PendingApprovals = ({ item, onClick, handleUpdateWorkflowStatus, userWorkflowStatusAccess }: { item: ApprovalsT, onClick: any, handleUpdateWorkflowStatus: any, userWorkflowStatusAccess: boolean }) => {
  const speciesIcon = SpeciesPicker({ species: item?.patientSpecies || "", useThumbnail: false });

  const getPatientWeight = () => {
    if (item?.patientWeight) {
      return `${item.patientWeight}`;
    } else {
      return "0";
    }
  }

  const getWorkflowStatus = () => {
    if (item?.workflowStatusCode) {
      return item.workflowStatusCode;
    }
    return WorkflowStatuses.ACTION_NEEDED;
  }

  return (
    <ListElement onClick={() => onClick(item)}>
      <ListElementHeader>
        {
          //If item prescriptionKey is null, then this is an approval.  Otherwise this is a renewal.

          <>
            <div>Order {item.orderNumber}</div>
            <div>{item.shippingType}</div>
            <div>{`Ordered on ${item.orderedOnDate?.split("T")[0]}`}</div>
          </>
        }

      </ListElementHeader>
      <ListElementBody>
        <ListElementBodyLeft>
          <div style={{ width: '40px' }}>
            {speciesIcon}
          </div>
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <div>{item?.clientFirstName + ' ' + item?.clientLastName}</div>
            <div>{item?.patientName} {getPatientWeight()} lbs</div>
            <div style={{ fontSize: 13 }}>{item?.orderedQty ? (item?.orderedQty + " x " + item?.productName) : ("1 x " + item?.productName)}</div>
            <div style={{ fontSize: 13 }}>{item?.productStrength}</div>
          </div>
        </ListElementBodyLeft >
        <ListElementBodyRight style={{ minWidth: 'fit-content' }}>
          <div>{item?.prescribingVetName}</div>
          <ChevronRight />
          <div onClick={(e) => e.stopPropagation()}>{
            <WorkflowStatusDropdownWrapper>
              <WorkflowStatus
                defaultValue={getWorkflowStatus()}
                onChange={(e: React.BaseSyntheticEvent) => {
                  handleUpdateWorkflowStatus(item?.approvableKey || "", e.target.value.toString());
                }}
                disabled={!userWorkflowStatusAccess}
              />
            </WorkflowStatusDropdownWrapper>
          }</div>
        </ListElementBodyRight>
      </ListElementBody>
    </ListElement>
  )
}

const PendingRenewals = ({ item, onClick, handleUpdateWorkflowStatus, userWorkflowStatusAccess }: { item: RenewalsT, onClick: any, handleUpdateWorkflowStatus: any, userWorkflowStatusAccess: boolean }) => {
  const speciesIcon = SpeciesPicker({ species: item?.patientSpecies || "", useThumbnail: false });

  const getPatientWeight = () => {
    if (item?.patientWeight) {
      return `${item.patientWeight}`;
    } else {
      return "0";
    }
  }

  const getWorkflowStatus = () => {
    if (item?.workflowStatusCode) {
      return item.workflowStatusCode;
    }
    return WorkflowStatuses.ACTION_NEEDED;
  }

  return (
    <ListElement onClick={() => onClick(item)}>
      <ListElementHeader>
        {
          <>
            <div>{`Renewal needed by: ${item.renewalNeededByDate?.split("T")[0]}`}</div>
            {/*                 <div>Order {item.orderNumber}</div>
                <div>{item.shippingType}</div>
                <div>{`Ordered on ${item.orderedOnDate?.split("T")[0]}`}</div>
                <div>{item.shippingType}</div> */}
          </>
        }

      </ListElementHeader>
      <ListElementBody>
        <ListElementBodyLeft>
          <div style={{ width: '40px' }}>
            {speciesIcon}
          </div>
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <div>{item?.clientFirstName + ' ' + item?.clientLastName}</div>
            <div>{item?.patientName} {getPatientWeight()} lbs</div>
            <div style={{ fontSize: 13 }}>{item?.productQty ? (item?.productQty + " x " + item?.productName) : ("1 x " + item?.productName)}</div>
            <div style={{ fontSize: 13 }}>{item?.productStrength}</div>
          </div>
        </ListElementBodyLeft>
        <ListElementBodyRight style={{ minWidth: 'fit-content' }}>
          <div>{item?.prescribingVetName}</div>
          <ChevronRight />
          <div onClick={(e) => e.stopPropagation()}>{
            <WorkflowStatusDropdownWrapper>
              <WorkflowStatus
                defaultValue={getWorkflowStatus()}
                onChange={(e: React.BaseSyntheticEvent) => {
                  handleUpdateWorkflowStatus(item?.renewableKey || "", e.target.value.toString());
                }}
                disabled={!userWorkflowStatusAccess}
              />
            </WorkflowStatusDropdownWrapper>
          }</div>
        </ListElementBodyRight>
      </ListElementBody>
    </ListElement>
  )
}

export default RxList;