import styled from "styled-components/macro";

const PRIMARY = "primary";
const SECONDARY = "secondary";
const NONE = "none";

interface ButtonProps {
  btnStyle?: "primary" | "secondary" | "none";
}

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  height: 36px;
  background-color: ${({ btnStyle = PRIMARY, theme, disabled }) => {
    switch (btnStyle) {
      case PRIMARY:
        if (disabled) {
          return theme.disabledColorOne;
        }
        return theme.accentColor;
      case SECONDARY:
        return theme.backgroundSecondary;
      case NONE:
        return 'transparent';
    }
  }};
  border: ${({ btnStyle = PRIMARY, theme, disabled }) =>
    (btnStyle === PRIMARY || btnStyle === NONE) ? "none" : `1px solid ${disabled ? theme.disabledColorTwo : theme.accentColor}`};
  border-radius: 3px;
  box-shadow: ${({ btnStyle = PRIMARY, disabled }) =>
    btnStyle === PRIMARY && !disabled
      ? "0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.2)"
      : `none`};
  color: ${({ btnStyle = PRIMARY, theme, disabled }) => {
    switch (btnStyle) {
      case PRIMARY:
        if (disabled) {
          return theme.disabledColorTwo;
        }
        return theme.backgroundSecondary;
      case SECONDARY: case NONE:
        if (disabled) {
          return theme.disabledColorTwo;
        }
        return theme.accentColor;
    }
  }};

  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
`;
