import * as React from "react";
import { CautionTriangleIcon } from "../../assets/CautionTriangleIcon";
import { FormWarningWrapper } from "./styles";
import { Props } from "./types";
import messages from "./messages";

export const FormWarning = (props: Props) => {
  const { title = messages.defaultWarningTitle, reason } = props;
  return (
    <FormWarningWrapper className={props.className || ""}>
      <div className={"icon-container"}>
        <CautionTriangleIcon widthStyle="40px" heightStyle="34px"/>
      </div>
      <div className={"copy-container"}>
        <h2 className={"title"}>{title}</h2>
        <p className={"error-directions"}>{reason}</p>
      </div>
    </FormWarningWrapper>
  );
};
