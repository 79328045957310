import styled from "styled-components/macro";

export const FormErrorCard = styled.div`
  display: flex;
  padding: 16px;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.errorBackgroundColor};
  border-left: 4px solid ${({ theme }) => theme.errorBorderColor};
  color: ${({ theme }) => theme.primaryTextColor};
  .icon-container {
    max-width: 64px;
  }
  .copy-container {
    padding-left: 16px;
    > p {
      font-size: 14px;
    }
  }
  .title {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .error-directions {
    margin-bottom: 8px;
  }
  .error-reason {
    text-decoration: underline;
    color: ${({ theme }) => theme.darkTextColor};
  }
`;
