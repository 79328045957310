import * as React from "react";
import { GlobalStateContext } from "../../context/state";
import { getCloseFunction } from "../../domUtils";
import { tag } from "../../util";
import { LoginForm } from "./LoginForm";
import { LoginCard, LoginContainer } from "./styles";

const Login = () => {
  const globalContext = React.useContext(GlobalStateContext);
  const pimsType = globalContext.state.session.pimsType;
  const claimCheckKey = globalContext.state.session.claimCheckKey;
  return (
    <>
      <LoginContainer>
        <LoginCard>
          <LoginForm
            onSuccess={tag("login successful")}
            onFailure={tag("login failed")}
            handleClose={getCloseFunction(pimsType, claimCheckKey || "")}
          />
        </LoginCard>
      </LoginContainer>
    </>
  );
};

export default Login;
