import styled from "styled-components/macro";
import { FormErrorCard } from "../StyledComponents/FormErrorCard/FormErrorCard";

export const FormWarningWrapper = styled(FormErrorCard)`
    background-color: rgba(255, 198, 48, 0.16);
    border-left: 4px solid #ffc630;

    h2.title {
      padding: 0;
    }
    .error-directions {
      margin-bottom: 0;
    }
    .copy-container {
      padding-right: 10px;
    }
`;