import * as H from "history";

// price helpers
const DEFAULT_PRICE_DISPLAY = "0.00";

export const formatPrice = (price: number | null) => {
  if (!price) {
    return `$${DEFAULT_PRICE_DISPLAY}`;
  }
  const result = price.toFixed(2);

  return `$${result}`;
};

// return from a modal
export const back = (e: React.SyntheticEvent, history: H.History, returnTo: string) => {
  e.stopPropagation();
  if (!history) {
    console.info("Could not find any history to perform back action on.");
    return null;
  }
  history.push(returnTo);
};
