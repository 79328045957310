import styled from "styled-components/macro";
import { Button } from "../StyledComponents/Button/Button";

export const HeaderContainer = styled.div`
  display: flex;
  background-color: #fff;
  width: 100%;
  height: 64px;
  align-items: center;
  box-shadow: 0 1px 4px 0 rgba(77, 80, 81, 0.12);
  position: sticky;
  top: 0;
  z-index: 1;
  .covetrus-logo {
    margin: 0 auto;
  }
  .help-icon {
    cursor: pointer;
    position: absolute;
    right: 34px;
    top: 20px;
  }
`;

export const BackButton = styled(Button)`
  position: absolute;
  left: 20px;

  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border: none;

  color: ${({ theme }) => theme.accentColor};

  svg {
    margin-right: 10px;
  }
`;

export const FeedbackButton = styled(Button)`
  position: absolute;
  right: 82px;

  border: none;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
`;
