import * as React from "react";
import PimsLogo from "../../components/PimsLogo/PimsLogo";
import VRxProLogo from "../VRxProLogo/VRxProLogo"
import {
  LabelColTh,
  NoMatchDiv,
  NoMatchGrid,
  NoMatchLabelColTh,
  NoMatchTableWrapper,
  NoMatchWrapper,
  TableWrapper,
} from "./styles";
import { ProductComparisonGridValues } from "./types";

const ComparisonGrid = (props: ProductComparisonGridValues) => {
  const {
    noMatchText,
    comparisonGrid,
    cvetMatchFound,
    linkSelectionMade,
    showPimsData,
    leftColumnIcon,
    rightColumnIcon,
  } = props;

  const getRightValueTitle = (val: string | JSX.Element | null | undefined) => {
    if (typeof val === 'string') {
      return val;
    } else if (typeof val === 'object') {
      return val?.props?.title;
    }
    return "";
  };

  const labels = comparisonGrid.map((element, index) => <td key={`label-row-${index}`}>{element.label}</td>);
  const leftValues = comparisonGrid.map((element, index) => (
    <td className={"data"} title={element.leftValue || ""} key={`avi-row-${index}`}>
      {element.leftValue}
    </td>
  ));
  const rightValues = comparisonGrid.map((element, index) => (
    <td className={"data"} title={getRightValueTitle(element.rightValue)} key={`cvet-row-${index}`} style={{overflow: "none"}}>
      {element.rightValue}
    </td>
  ));

  const matchedTable = comparisonGrid.map((value, index) => (
    <tr key={`table-compare-row-${index}`}>
      {labels[index]}
      {leftValues[index]}
      {rightValues[index]}
    </tr>
  ));

  // skips creating the right column to fit the noMatch div
  const noMatchTable = comparisonGrid.map((value, index) => (
    <tr key={`table-no-match-row-${index}`}>
      {labels[index]}
      {leftValues[index]}
    </tr>
  ));

  // skips creating the left column for unlinked flow product card
  const unlinkedFlowProductTable = comparisonGrid.map((value, index) => (
      <tr key={`table-unlinked-product-row-${index}`}>
        {labels[index]}
        {rightValues[index]}
      </tr>
  ));

  return (
    <>
      {cvetMatchFound || linkSelectionMade ? (
        <TableWrapper>
          <thead>
          <tr>
            <LabelColTh />
            { showPimsData &&  <th>{leftColumnIcon ? leftColumnIcon : <PimsLogo pimsType="avimark" />}</th> }
            <th>{rightColumnIcon ? rightColumnIcon : <VRxProLogo />}</th>
          </tr>
          </thead>
          <tbody>{showPimsData ? matchedTable : unlinkedFlowProductTable}</tbody>
        </TableWrapper>
      ) : (
        <NoMatchWrapper>
          <NoMatchTableWrapper>
            <thead>
              <tr>
                <NoMatchLabelColTh />
                <th>{leftColumnIcon ? leftColumnIcon : <PimsLogo pimsType="avimark" />}</th>
              </tr>
            </thead>
            <tbody>{noMatchTable}</tbody>
          </NoMatchTableWrapper>
          <NoMatchDiv>
            {rightColumnIcon ? rightColumnIcon : <VRxProLogo />}
            <NoMatchGrid>
              <b>{noMatchText}</b>
            </NoMatchGrid>
          </NoMatchDiv>
        </NoMatchWrapper>
      )}
    </>
  );
};

export default ComparisonGrid;
