import * as r from "ramda";
import * as H from "history";
import * as Yup from "yup";
import { FormikHelpers } from "formik";
import { LocationState, LoginFormValues, LoginResponse, Props } from "./types";
import { pimsRxBasePath } from "../../config/frontend";

export const loginRequest = (username: string, password: string, claimCheckKey?: string, pimsType?: string | null) =>
  new Promise<LoginResponse>((resolve, reject) => {
    let responseBody: LoginResponse = { status: "failure" };
    const request = new XMLHttpRequest();

    // Set up our request
    request.open("POST", `${pimsRxBasePath}/login`);

    // Add the required HTTP header for form data POST requests
    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

    request.withCredentials = true;

    request.onload = () => {
      if (request.response) {
        try {
          responseBody = JSON.parse(request.response);
        } catch (e) {
          return reject(responseBody);
        }
      }
      if (request.status === 200) {
        // If successful, resolve the promise by passing back the request response
        sessionStorage.setItem("ContextKey", responseBody.contextKey || "") 
        resolve({ status: "successful" });
      } else if (request.status === 401) {
        reject({ ...responseBody, status: "bad-credentials" });
      } else if (request.status === 403) {
        reject({ ...responseBody, status: "no-permissions" });
      } else if (request.status === 406) {
        reject({ ...responseBody, status: "forbidden" });
      } else if (request.status === 404) {
        reject({ status: request.response });
      } else {
        // If it fails, reject the promise with a error message
        reject(responseBody);
      }
    };

    request.onerror = () => {
      // Also deal with the case when the entire request fails to begin with
      // This is probably a network error, so reject the promise with an appropriate message
      reject(responseBody);
    };

    const postBodyEncodedDataPairs = [
      `${encodeURIComponent("userName")}=${encodeURIComponent(username)}`,
      `${encodeURIComponent("password")}=${encodeURIComponent(password)}`,
      `${encodeURIComponent("claimCheckKey")}=${encodeURIComponent(claimCheckKey || "")}`,
      `${encodeURIComponent("pimsType")}=${encodeURIComponent(pimsType || "AVImark")}`,
    ];

    // Combine the pairs into a single string and replace all %-encoded spaces to
    // the '+' character; matches the behaviour of browser form submissions.
    const postBodyString = postBodyEncodedDataPairs.join("&").replace(/%20/g, "+");

    // Finally, send our data.
    request.send(postBodyString);

  });

export const submitForm = (
  values: LoginFormValues,
  actions: FormikHelpers<LoginFormValues>,
  props: Props,
  setCredentialError: React.Dispatch<React.SetStateAction<boolean>>,
  setRouteToNext: React.Dispatch<React.SetStateAction<boolean>>,
  history: H.History<H.History>,
  maybeClaimCheckGuid?: string,
  maybePimsType?: string | null
) => {
  loginRequest(values.username, values.password, maybeClaimCheckGuid, maybePimsType)
    .then((response) => {
      actions.setSubmitting(false);
      setRouteToNext(true);
      if (props.onSuccess) {
        props.onSuccess(response);
      }
    })
    .catch((error) => {
      actions.setSubmitting(false);
      if (error && error.status === "no-permissions") {
        history.push("/no-permissions");
      }
      if (error && error.status === "forbidden") {
        history.push("/forbidden");
      }
      if (error && error.status === "bad-credentials") {
        setCredentialError(true);
      }
      if (props.onFailure) {
        props.onFailure(error);
      }
      actions.setFieldError("serverError", error.errorCode);
    });
};

export const FormSchema = Yup.object().shape({
  username: Yup.string().required("Username is required."),
  password: Yup.string().required("Password is required."),
});

const PIMS_TYPE_QUERY_PARAM = "pt=";
export const getPimsTypeFromLocation = (location: H.Location<LocationState>) => {
  const queryString = location?.state?.from?.search ?? location.search ?? null;
  const indexOfQueryParam = queryString?.indexOf(PIMS_TYPE_QUERY_PARAM) ?? null;
  const indexOfAmpersand = queryString?.indexOf("&") ?? -1;
  if (indexOfQueryParam === -1 || !indexOfQueryParam || !queryString) {
    return null;
  }
  return r.slice(
    indexOfQueryParam + PIMS_TYPE_QUERY_PARAM.length,
    indexOfAmpersand !== -1 ? indexOfAmpersand : queryString.length,
    queryString,
  );
};

export const anyValuesEmpty = (values: string[]) => r.any((value) => r.isEmpty(value), values);
