export interface ThemeInterface {
  backgroundPrimary: string;
  backgroundSecondary: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  darkTextColor: string;
  accentColor: string;
  errorBackgroundColor: string;
  errorBorderColor: string;
  errorToastColor: string;
  errorDarkColor: string;
  disabledColorOne: string;
  disabledColorTwo: string;
  successToastColor: string;
  tableBorderColor: string;
  tableHoverColor: string;
  warningToastColor: string;
}

export const theme: ThemeInterface = {
  backgroundPrimary: "#e8eaf0",
  backgroundSecondary: "#fff",
  primaryTextColor: "#4d5051",
  secondaryTextColor: "#021660",
  darkTextColor: "#000",
  accentColor: "#354580",
  errorBackgroundColor: "#fef0ee",
  errorBorderColor: "#d8193b",
  errorToastColor: "#d8193b",
  errorDarkColor: "#a92627",
  disabledColorOne: "rgba(0, 0, 0, 0.12)",
  disabledColorTwo: "rgba(0, 0, 0, 0.26)",
  successToastColor: "#00855d",
  tableBorderColor: "#cccccc",
  tableHoverColor: "#e8f7ff",
  warningToastColor: "#efb51b"
};

export const getTheme = () => theme;
