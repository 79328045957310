import * as React from "react";

export const CheckboxFilled = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>checkbox-filled</title>
    <g id="🐼-Icon-Sheet-checkbox-filled" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Icon-Sheet-checkbox-filled"
        transform="translate(-752.000000, -285.000000)"
        fill="#1F9798"
        stroke="#1F9798"
        strokeWidth="2"
      >
        <path
          d="M775,286.346555 L775,308.346555 L753,308.346555 L753,286.346555 L775,286.346555 Z M770.957477,288.697372 L760.120638,300.058446 L755.855652,295.582915 L753.016323,298.541428 L760.120296,305.996096 L773.781349,291.660649 L770.957477,288.697372 Z"
          id="checkbox-filled"
        ></path>
      </g>
    </g>
  </svg>
);
