import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { addToast } from "../actions/toast";
import { generateToastID } from "../components/Toast/Toast";
import { ApolloError } from "@apollo/client";

export type ErrorToast = {
    error: ApolloError | undefined;
    message: string;
}

export const useDispatchErrors = (errors: ErrorToast[]) => {
    const displayedErrorRef = useRef<ErrorToast[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        errors.forEach((errorObj, index) => {
            if (errorObj.error && 
                displayedErrorRef.current[index].error !== errorObj.error) {
                    dispatch(
                        addToast({
                          message: errorObj.message,
                          id: generateToastID(),
                          kind: "error"
                        })
                      );
            }
        });
        displayedErrorRef.current = errors;
    }, [errors]);
}