import * as H from "history";
import * as r from "ramda";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useLocation, useParams } from "react-router-dom";
import { addToast } from "../../actions/toast";
import { RxIcon } from "../../assets/RxIcon";
import commonMessages from "../../common/messages";
import CollapsableCard from "../../components/CollapsableCard/index";
import { DescriptionComponent } from "../../components/DescriptionComponent";
import { getDisplayPimsName } from "../../components/PimsLogo/getDisplayPimsName";
import PimsLogo from "../../components/PimsLogo/PimsLogo";
import SearchGrid, { parseNumberFromString } from "../../components/SearchGrid/index";
import searchMessages from "../../components/SearchGrid/messages";
import { Container } from "../../components/StyledComponents/Container/Container";
import Loading from "../../components/StyledComponents/Loading/Loading";
import toastMessages from "../../components/Toast/messages";
import { generateToastID } from "../../components/Toast/Toast";
import { WarningCard } from "../../components/WarningCard/WarningCard";
import { GlobalStateContext } from "../../context/state";
import {
  useCreateProductLinkMutation,
  useRetrieveLinkedClientDetailsQuery,
  useRetrieveLinkedPatientDetailsQuery,
  useRetrieveRxByClaimCheckGuidQuery,
  useAutocompleteSearchGPMProductFamiliesQuery,
  SearchProductsWithSpeciesAndWeightFilterQuery,
  useSearchProductsWithSpeciesAndWeightFilterQuery
} from "../../data/gql/apiTypes";
import { smoothScroll } from "../../domUtils";
import { exists, nullMap } from "../../util";
import messages from "./messages";
import { getGridHeaders, getResultsProp, getAutoCompleteResultsProp, getCompoundProductGridHeaders } from "./productUtils";
import { fetchClientName, fetchCollapsedText, fetchPatientWeight } from "../ReviewRx/utils";
import { isValidSpeciesType, SpeciesPicker } from "../../components/Species/Species";
import Modal from "../../components/ProductDetailsModal";
import { InfoCircled } from "../../assets/InfoCircled";
import { PencilIcon } from "../../assets/PencilIcon";
import Tooltip from "../../components/Tooltip";
import UpdatePatientWeightModal from "../../components/UpdatePatientWeightModal";
import { ContainerProps } from "../../PrivateRoute/types";
import { useDispatchErrors } from "../../hooks/useDispatchErrors";

export type SearchProductsWithSpeciesAndWeightFilterResult = SearchProductsWithSpeciesAndWeightFilterQuery["searchProductsWithSpeciesAndWeightFilter"][0];

export const ProductLinking = (props: ContainerProps) => {
  const { claimCheckKey } = useParams<{ claimCheckKey: string }>();
  const location = useLocation<H.History>();
  const dispatch = useDispatch();
  const [displayLoading, setDisplayLoading] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({
    query: "",
    filter: {
      isCompound: null,
      categoryCode: "",
      treatmentCategoryCode: "",
      speciesCode: "",
      brandCode: "",
      primaryManufacturerCode: "",
      catalogCategoryCode: "",
      baseProductStatus: "",
      familyCode: "",
      weight:""
    }
  });
 
  const [selectedProduct, setSelectedProduct] = useState<SearchProductsWithSpeciesAndWeightFilterResult | null>(null);
  const [productInfoDialog, setProductInfoDialog] = useState<SearchProductsWithSpeciesAndWeightFilterResult | null>(null);
  const [toReviewAndConfirm, setToReviewAndConfirm] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [patientCollapsed, setPatientCollapsed] = useState(true);
  const [searchStep, setSearchStep] = useState<"pre" | "automated" | "user">("pre");
  const [isCompoundProduct, setIsCompoundProduct]= useState<boolean | null>(null);
  const [autoCompleteSearchCriteria, setAutoCompleteSearchCriteria] = useState({
    query: "",
    filter: {
      isCompound: null,
      categoryCode: "",
      treatmentCategoryCode: "",
      speciesCode: "",
      brandCode: "",
      primaryManufacturerCode: "",
      catalogCategoryCode: "",
      baseProductStatus: "",
    }
  });
  const [showUpdatePatientWeightModal, setShowUpdatePatientWeightModal] = useState(false);
  const globalContext = React.useContext(GlobalStateContext);

  useEffect(() => {
    // Smooth scroll on selection --> no scroll on clearing selection
    if (!selectedProduct) {
      return;
    }
    // 64px is the height of the header so this scrolls to the very bottom of the header and allows
    // the user to see the page title / description
    smoothScroll(64);
  }, [selectedProduct]);

  const { loading, error, data } = useRetrieveRxByClaimCheckGuidQuery({
    variables: { guid: claimCheckKey || "" },
    fetchPolicy: "cache-first",
  });

  const qResult = useSearchProductsWithSpeciesAndWeightFilterQuery({
    variables: { criteria: searchCriteria, claimCheckKey: claimCheckKey },
    skip: !exists(searchCriteria.query),
  });

  const aqResult = useAutocompleteSearchGPMProductFamiliesQuery({
    variables: { criteria: autoCompleteSearchCriteria },
    skip: !exists(autoCompleteSearchCriteria.query),
  });

  const [createProductLink] = useCreateProductLinkMutation();

  const gpmPatientQuery = useRetrieveLinkedPatientDetailsQuery({
    variables: { key: data?.retrieveRxByGuidOrClaimCheckGuid?.linkedPatientKey || "" },
    skip: !exists(data?.retrieveRxByGuidOrClaimCheckGuid?.linkedPatientKey),
  });

  const gpmClientQuery = useRetrieveLinkedClientDetailsQuery({
    variables: {
      guid:
          data?.retrieveRxByGuidOrClaimCheckGuid?.linkedClientKey || gpmPatientQuery?.data?.getPatient?.clientKey || "",
    },
    skip:
        !exists(data?.retrieveRxByGuidOrClaimCheckGuid?.linkedClientKey) &&
        !exists(gpmPatientQuery?.data?.getPatient?.clientKey),
  });

  useDispatchErrors([
    { error: qResult.error, message: messages.searchProductsFailure },
    { error: aqResult.error, message: messages.searchProductsFailure },
    { error: gpmPatientQuery.error, message: messages.fetchLinkedPatientQueryFailure },
    { error: gpmClientQuery.error, message: messages.fetchLinkedClientQueryFailure }
  ]);

  const retrieveClaimCheckByGuid = props.claimCheck;
  const retrieveRxByClaimCheckGuid = data?.retrieveRxByGuidOrClaimCheckGuid;

  const client = retrieveClaimCheckByGuid?.payload.client;
  const patient = retrieveClaimCheckByGuid?.payload.patient;
  const gpmPatient = gpmPatientQuery?.data?.getPatient;
  const gpmClient = gpmClientQuery?.data?.retrieveClientByGuid;

  const maybePimsType = globalContext.state.session.pimsType || data?.retrieveRxByGuidOrClaimCheckGuid?.pimsType || "";

  useEffect(() => {
    if (exists(qResult.data)
      && searchStep === "automated"
      && qResult.data.searchProductsWithSpeciesAndWeightFilter.length === 1
      && !exists(qResult.data.searchProductsWithSpeciesAndWeightFilter[0].productLink)
    ) {
      setSelectedProduct(qResult.data.searchProductsWithSpeciesAndWeightFilter[0]);
      setCollapsed(false);
    }
  }, [qResult]);
  
  if (loading) return <Loading fullPage />;

  if (gpmPatientQuery.loading || gpmClientQuery.loading) {
    return <Loading fullPage />;
  }

  if (!exists(claimCheckKey)) {
    return <WarningCard reason={"Claim Check UUID not found"} />;
  }

  const existingProductLinkFound = !!retrieveRxByClaimCheckGuid?.linkedProductKey;
  if (toReviewAndConfirm || existingProductLinkFound) {
    return <Redirect to={`/review-rx/${claimCheckKey}`} />;
  }

  if (error) return <WarningCard reason={"Error fetching claim check or prescription"} />;
  if (!data || !retrieveRxByClaimCheckGuid || !retrieveClaimCheckByGuid) {
    return <WarningCard reason={`Claim check or Prescription not found with guid: ${claimCheckKey}`} />;
  } else {
    const { description, strength, code, form, pimsType } = retrieveRxByClaimCheckGuid;
    const displayPimsName = getDisplayPimsName(pimsType);
    const gpmPatientWeight = parseNumberFromString(gpmPatient?.weight == null ? "" : gpmPatient?.weight);

    const pimsWeightWithUnits = fetchPatientWeight(patient?.currentWeight, patient?.currentWeightUnit);
    const gpmWeightWithUnits = fetchPatientWeight(gpmPatient?.weight, gpmPatient?.weightUnitCode);
    const collapsedText = fetchCollapsedText(gpmWeightWithUnits, gpmClient?.givenName, gpmClient?.familyName);
    const pimsClientName = fetchClientName(null, client?.firstName, client?.lastName);
    const gpmClientName = fetchClientName(gpmPatient?.clientName || null, gpmClient?.givenName, gpmClient?.familyName);
    const showGpmValues = gpmPatient !== undefined || gpmClient !== undefined;
    const patientSpeciesString = isValidSpeciesType(gpmPatient?.speciesCode || "")
        ? gpmPatient?.speciesCode
        : ( isValidSpeciesType(patient?.speciesDescription || "") ? patient?.speciesDescription : "" );
    const patientSpecies = patientSpeciesString !== undefined ? patientSpeciesString.toUpperCase() : "";
    const pimsProductDescription = description !== null ? description : "";
    const loggedInUsername = props.user?.username;

    if (searchStep === "pre" && description) {
      setSearchCriteria(
        {
          query: description,
          filter: {
            isCompound: null,
            categoryCode: "",
            treatmentCategoryCode: "",
            speciesCode: patientSpecies,
            brandCode: "",
            primaryManufacturerCode: "",
            catalogCategoryCode: "",
            baseProductStatus: "",
            familyCode: "",
            weight: gpmPatientWeight ? gpmPatientWeight.toString() || "" : ""
          },
        });
      setSearchStep("automated");
    }

    const getPatientWeightRightValue = () => {
      if (gpmPatient) {
        if (patient && patient.currentWeight && (!exists(patient.currentWeight) || patient.currentWeight !== '0')) {
          return (
            <div style={{display: 'flex', justifyContent: 'space-between', flexFlow: 'row'}} title={gpmWeightWithUnits || '' }>
              {gpmWeightWithUnits}
              <div style={{marginRight: 20}}>
                <div style={{position: 'absolute', whiteSpace: 'normal', flexDirection: 'row'}}>
                  <div style={{cursor: 'pointer'}} onClick={() => setShowUpdatePatientWeightModal(true)}>
                    <PencilIcon />
                  </div>     
                </div>
              </div>
            </div>
          );
        }
        return (
          <div style={{display: 'flex', justifyContent: 'space-between', flexFlow: 'row'}} title={gpmWeightWithUnits || '' }>
            <div style={{fontWeight: 'bold'}}>
              {gpmWeightWithUnits}
            </div>
            <div style={{marginRight: 40}}>
              <div style={{position: 'absolute', whiteSpace: 'normal', flexDirection: 'row'}}>
                <div style={{cursor: 'pointer', marginRight: 10}} onClick={() => setShowUpdatePatientWeightModal(true)}>
                  <PencilIcon />
                </div>
                <Tooltip message={messages.weightSyncFailedMessage(displayPimsName)} position="right">
                  <InfoCircled/>
                </Tooltip>            
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    };

    const patientProps = {
      headerIcon: (
          <SpeciesPicker species={gpmPatient?.speciesCode || patient?.speciesDescription || ""} useThumbnail={false} />
      ),
      headerText: patient?.name || "Unnamed Patient",
      collapsed: patientCollapsed,
      setCollapsed: setPatientCollapsed,
      collapsedText,
      comparison: {
        noMatchText: messages.noMatchText,
        noMatchHeader: messages.noMatchHeader,
        leftColumnIcon: <PimsLogo pimsType={maybePimsType} />,
        cvetMatchFound: showGpmValues,
        linkSelectionMade: false,
        showPimsData: exists(patient),
        continue: () => {},
        comparisonGrid: [
          { label: commonMessages.name, leftValue: patient?.name, rightValue: gpmPatient?.name },
          { label: commonMessages.client, leftValue: pimsClientName, rightValue: gpmClientName },
          { label: commonMessages.email, leftValue: client?.email, rightValue: gpmClient?.emailAddress },
          { label: commonMessages.phone, leftValue: client?.phoneNumber, rightValue: gpmClient?.phoneNumber },
          { label: commonMessages.species, leftValue: patient?.speciesDescription, rightValue: gpmPatient?.speciesCode.toLowerCase().replace(/_/g, ' ').replace(/(?: |\b)(\w)/g, function(key, p1) { return key.toUpperCase(); }) },
          { label: commonMessages.weight, leftValue: pimsWeightWithUnits, rightValue: getPatientWeightRightValue() },
          { label: commonMessages.sex, leftValue: patient?.gender, rightValue: gpmPatient?.sexCode },
          { label: commonMessages.dob, leftValue: patient?.dateOfBirth, rightValue: gpmPatient?.dateOfBirth },
        ],
      },
    };

    const continueFn = () => {
      const vdsFields = [
        retrieveClaimCheckByGuid.payload?.product?.pmsKey,
        retrieveClaimCheckByGuid.installationKey,
        retrieveClaimCheckByGuid.payload?.product?.vdsKey,
        retrieveClaimCheckByGuid.payload?.product?.description,
      ];
      const vdsFieldsPresent = r.all(exists)(vdsFields);
      if (selectedProduct !== null && vdsFieldsPresent) {
        setDisplayLoading(true);
         createProductLink({
          variables: {
            productI: {
              vdsProductKey: r.path(["payload", "product", "vdsKey"], retrieveClaimCheckByGuid) as string,
              pimsProductKey: r.path(["payload", "product", "pmsKey"], retrieveClaimCheckByGuid) as string,
              installationKey: retrieveClaimCheckByGuid.installationKey as string,
              productKey: selectedProduct.key,
              productTitle: selectedProduct.name,
              pimsProductTitle: r.path(["payload", "product", "description"], retrieveClaimCheckByGuid) as string,
              baseProductKey:selectedProduct.baseProductKey,
              claimCheckKey,
            },
          },
        }).then((res: any) => {
          dispatch(
            addToast({
              message: toastMessages.productSuccess,
              id: generateToastID(),
              kind: "success",
            }),
          );
          setTimeout(() => {
            setToReviewAndConfirm(true);
            setDisplayLoading(false);
          }, 1000);
        }).catch((error) => {
          // TODO Legit Error handling INTEXP-24733 
          
          setDisplayLoading(false);
          //INTEXP-24733 need to handle all errors

          dispatch(
            addToast({
              message: "Error: Duplicate Record Exists, Can't proceed with product linking",
              id: generateToastID(),
              kind: "error",
            }),
          );
        });
      } else {
        //TODO: do some legit error handling here INTEXP-24733 
        setDisplayLoading(false);
        dispatch(
          addToast({
            message: "Error: missing vds fields detected cannot create product link: " + vdsFields.toString(),
            id: generateToastID(),
            kind: "error",
          }),
        );
      }
    };

    const productProps = {
      headerIcon: <RxIcon />,
      headerText: description || `Unnamed ${displayPimsName} Product`,
      collapsed,
      setCollapsed,
      displayLoading,
      comparison: {
        //TODO: check for existence of cvet matching product link here
        cvetMatchFound: false,
        noMatchText: "Search for a product below to get started.",
        //for rendering dynamic button text
        linkSelectionMade: exists(selectedProduct),
        showPimsData: true,
        continue: continueFn,
        leftColumnIcon: <PimsLogo pimsType={pimsType} />,
        comparisonGrid: [
          { label: commonMessages.name, leftValue: description, rightValue: nullMap(r.prop("name"), selectedProduct) },
          {
            label: commonMessages.strength,
            leftValue: strength,
            rightValue: nullMap(r.prop("strength"), selectedProduct),
          },
          {
            label: `${displayPimsName} Code`,
            leftValue: code,
            rightValue: null,
          },
          {
            label: commonMessages.form,
            leftValue: form,
            rightValue: nullMap(r.prop("unitOfMeasure"), selectedProduct),
          },
        ],
      },
      clearSelected: () => setSelectedProduct(null),
    };

    const productInfoModal = () => {
      if (productInfoDialog != null)
        return <Modal baseProductKey={productInfoDialog.baseProductKey} productKey={productInfoDialog.key} claimCheckKey={claimCheckKey} onClose={() => setProductInfoDialog(null)} />;
      return null;
    }

    const updatePatientWeightModal = () => {
      if (showUpdatePatientWeightModal && gpmPatient)
        return <UpdatePatientWeightModal 
          patient={gpmPatient} 
          onClose={() => setShowUpdatePatientWeightModal(false)}
          onFinish={() => {
            dispatch(
              addToast({
                message: messages.updateWeightSuccessful,
                id: generateToastID(),
                kind: "success",
              }),
            );
            setShowUpdatePatientWeightModal(false);
            gpmPatientQuery.refetch();
          }}
          onError={(err) => {
            dispatch(
              addToast({
                message: messages.updateWeightFailure,
                id: generateToastID(),
                kind: "error",
              }),
            );
          }}
        />;
      return null;
    };

    const isNormalizedStrength = (products: SearchProductsWithSpeciesAndWeightFilterResult[]) => {
      return (
        r.all(({ normalizedStrengthWithUOM }) => !normalizedStrengthWithUOM?.includes("null null"), products)
      );
    }

    const gridHeaders = isCompoundProduct && isNormalizedStrength(qResult.data?.searchProductsWithSpeciesAndWeightFilter || []) ? getCompoundProductGridHeaders(location, (p) => setProductInfoDialog(p)) : getGridHeaders(location, (p) => setProductInfoDialog(p));

    return (
      <Container>
        {updatePatientWeightModal()}
        {productInfoModal()}
        <DescriptionComponent {...messages} pimsType={pimsType} username={loggedInUsername || ""} claimCheckKey={claimCheckKey} />
        <CollapsableCard {...patientProps} />
        <CollapsableCard {...productProps} />
        <SearchGrid<SearchProductsWithSpeciesAndWeightFilterResult>
          initialSearchString={pimsProductDescription}
          setIsCompoundProduct={setIsCompoundProduct}
          noResultsText={searchMessages.noResultsText(displayPimsName)}
          gridSchema={gridHeaders}
          isCompoundProduct={(isCompoundProduct && isNormalizedStrength(qResult.data?.searchProductsWithSpeciesAndWeightFilter || [])) || false}
          searchResults={getResultsProp(qResult, searchStep)}
          commitSearch={(newCriteria: any) => {
            setSearchCriteria(prevCriteria => {
              if (prevCriteria.query !== newCriteria.query ||
                  prevCriteria.filter.speciesCode !== newCriteria.filter.speciesCode ||
                  Number(prevCriteria.filter.weight) !== Number(newCriteria.filter.weight)) {
                setSearchStep("user");
              }
              return newCriteria;
            });
          }}
          autocomplete={true}
          autocompleteSuggestions={getAutoCompleteResultsProp(aqResult, searchStep)}
          commitAutoCompleteSearch={(newCriteria: any) => {
            setAutoCompleteSearchCriteria(newCriteria);
          }}
          selectResult={(product) => {
            if (!product.productLink) {
              setSelectedProduct(product);
              setCollapsed(false);
            }
          }}
          guidRowSelected={nullMap(r.prop("key"), selectedProduct)}
          filters={{
            displayType: "column",
            patientWeight: gpmWeightWithUnits,
            patientSpecies: patientSpeciesString,
          }}
        />
      </Container>
    );
  }
};
