import * as React from "react";

type Props = {
  className?: string;
};

export const RxIcon = (props: Props) => (
  <svg
    width="48px"
    height="48px"
    viewBox="0 0 24 25"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>rx-boxed</title>
    <defs>
      <path
        d="M8.78805358,9.76996262e-15 C11.3475614,9.76996262e-15 12.3873614,1.0054001 12.3873614,2.68944526 C12.3873614,3.77025037 11.8541306,4.57457045 10.787669,4.97673049 L12.787,7.691 L13.4308282,6.85714286 L15.5047619,6.85714286 L13.777,9.036 L15.5394005,11.4285714 L13.5797774,11.4285714 L12.765,10.313 L11.8822694,11.4285714 L9.9047619,11.4285714 L11.792,8.98 L9.25463052,5.50456554 C9.02800743,5.52970054 8.78805358,5.54226804 8.56143049,5.54226804 L7.74825354,5.54226804 L7.74825354,9.99116348 L6.0952381,9.99116348 L6.0952381,9.76996262e-15 L8.78805358,9.76996262e-15 Z M3.04761905,2.4 L3.047,3.923 L4.57142857,3.92380952 L4.57142857,5.44761905 L3.047,5.447 L3.04761905,6.97142857 L1.52380952,6.97142857 L1.523,5.447 L0,5.44761905 L0,3.92380952 L1.523,3.923 L1.52380952,2.4 L3.04761905,2.4 Z M8.78805358,1.24418262 L7.74825354,1.24418262 L7.74825354,4.23524791 L8.78805358,4.23524791 C10.1211306,4.23524791 10.6810229,3.60687285 10.6810229,2.65174276 C10.6810229,1.77201767 10.0678075,1.24418262 8.78805358,1.24418262 Z"
        id="path-7"
      ></path>
    </defs>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="translate(-640.000000, -228.000000)">
        <g id="rx-boxed" transform="translate(640.000000, 228.673277)">
          <g id="Prescription">
            <rect id="Rectangle" fill="#E9F7FE" x="0" y="0" width="24" height="24" rx="4"></rect>
            <g id="Icons/Nav/Add-Rx" transform="translate(4.000000, 6.500000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-7"></use>
              </mask>
              <use id="Mask" fill="#0A3394" xlinkHref="#path-7"></use>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
