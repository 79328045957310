import * as React from "react";
import ModalWrapper from "../StyledComponents/Modal/Modal";
import { 
    HeaderText,
    HeaderContainer,
    InputWrapper,
    FooterContainer
} from "./styles";
import { Props } from "./types";
import { SpeciesPicker } from "../Species/Species";
import { Input } from "../StyledComponents/Input/Input";
import { InputLabel } from "../StyledComponents/InputLabel/InputLabel";
import { Button } from "../StyledComponents/Button/Button";
import { useUpdatePatientWeightMutation } from "../../data/gql/apiTypes";
import Loading from "../StyledComponents/Loading/Loading";
import messages from "./messages";
import { InputErrorDiv } from "../DropdownSelector/styles";
import { AlertCircleIcon } from "../../assets/AlertCircleIcon";

export default ({patient, onClose, onFinish, onError}: Props) => {
    const [loading, setLoading] = React.useState(false);
    const [weight, setWeight] = React.useState(patient.weight);
    const headerIcon = SpeciesPicker({species: patient.speciesCode, useThumbnail: false});
    const [updatePatientWeight] = useUpdatePatientWeightMutation();
    const [errors, setErrors] = React.useState("");

    const updateWeight = () => {
        if (weight) {
            if(parseFloat(weight) > 9999.999){
                setErrors("Value must be less than or equal to 9999.999");
                return;
            }
            if(parseFloat(weight) <= 0){
                setErrors("Value must greater than 0");
                return;
            }
            setLoading(true);
            updatePatientWeight({
                variables: { 
                    patientKey: patient.key,
                    weight: parseFloat(weight)
                }
            }).then(() => {
                setLoading(false);
                onFinish();
            })
            .catch(err => {
                setLoading(false);
                onError(err);
            })
        }
    };

    return (
        <ModalWrapper onClose={() => onClose()} styles={{widthStyle: '400px', heightStyle: '300px'}}>
            { loading ?
                <Loading /> :
                <>
                    <HeaderContainer>
                    {headerIcon}
                    <HeaderText>
                        <h1>{messages.header}</h1>
                    </HeaderText>
                    </HeaderContainer>
                    <InputWrapper>
                        <InputLabel htmlFor="patientWeight" required={true} label={"Weight (lbs)"} />
                        <Input 
                            className="form-control"
                            id={"patientWeight"}
                            name={"patientWeight"}
                            placeholder={"lbs"}
                            type="number"
                            fullWidth
                            onChange={(c) => setWeight(c.target.value)}
                            value={weight || undefined}
                            step={0.001}
                        />
                        {errors ? (
                        <InputErrorDiv>
                        {errors}
                        <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
                        </InputErrorDiv>
                  ) : null}
                    </InputWrapper>
                    <FooterContainer>
                        <Button btnStyle={"secondary"} style={{marginRight: 10}} onClick={() => onClose()}>Cancel</Button>
                        <Button style={{paddingLeft: 15, paddingRight: 15}} onClick={updateWeight}>Save</Button>
                    </FooterContainer>
                </>
            } 
        </ModalWrapper>
    );
};