export const defaultReasons = [
    "Already Prescribed",
    "Due for Exam",
    "Due For Heartworm Screening",
    "Incorrect Strength, Quantity, or Product",
    "No Longer Needed",
    "No Vet-Client-Patient Relationship",
    "Other"
];

export enum ApprovalWithChangeReason {
    ALREADY_PRESCRIBED = "Already Prescribed",
    DUE_FOR_EXAM = "Due for Exam",
    DUE_FOR_HEARTWORM_SCREENING = "Due For Heartworm Screening",
    INCORRECT_STRENGTH_QUANTITY_OR_PRODUCT = "Incorrect Strength Quantity Or Product",
    NO_LONGER_NEEDED = "No Longer Needed",
    NO_VET_CLIENT_PATIENT_RELATIONSHIP = "No Vet Client Patient Relationship",
    OTHER = "Other"
};