import * as H from "history";
import * as r from "ramda";
import { Formik, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { AutoshipIcon } from "../../../assets/AutoshipIcon";
import messages from "../messages";
import { AutoshipDiv, InputWrapper, SpacerDiv, SubmitRxBtn, SubTitleH2, TopPaddingSpacer } from "../styles";
import CompetitiveFax from "../Subcomponents/CompetitiveFax";
import Expiration from "../Subcomponents/Expiration";
import FlaggedIssuesError from "../Subcomponents/FlaggedIssuesError/index";
import NotesToClient from "../Subcomponents/NotesToClient";
import { Notification } from "../Subcomponents/NotificationField/Notification";
import { getFormSchema, getInitialValues, transformRxForApi } from "./formHelpers";
import { flaggedIssuesHasLength, getSmartScribeError } from "../ScriptForm/formHelpers";
import { InitialAOTCvalues, InitialOTCvalues, Props } from "./types";
import { legalCategoryCodeEnum } from "../FinishScriptCard";
import NotesShortcuts from "../Subcomponents/NotesShortcuts";
import AuthorizingVet from "../Subcomponents/AuthorizingVet";
import { SubmitRxMutationFn } from "../../../data/gql/apiTypes";
import { useHistory, useParams } from "react-router-dom";
import { LinkedGuids } from "../types";
import { useDispatch } from "react-redux";
import { AnyAction, Dispatch } from "redux";
import { addToast } from "../../../actions/toast";
import { generateToastID } from "../../Toast/Toast";
import ApproveWithChangesDialog from "../../ApproveWithChangesDialog";
import { WorkFlowType } from "../../CollapsableCard/types";
import { getAuthorizingVetWarningAotc, getAuthorizingVetWarningOtc } from "../Subcomponents/AuthorizingVet/utils";
import FieldWarning from "../Subcomponents/FieldWarning";
import StateLicenseSelect from "../Subcomponents/StateLicense/StateLicenseSelect";

const Form = (props: Props) => {
  const { handleChange, handleSubmit, errors, submitCount, setFieldValue, values, isSubmitting, isValidating } =
    useFormikContext<InitialOTCvalues | InitialAOTCvalues>();

  const {
    legalCategoryCode,
    sendButton,
    hooksAndState: { setShouldValidateOnAction, disableSubmit },
    reviewOTC,
    pendingRequestType,
  } = props;

  const isAOTC = legalCategoryCode === legalCategoryCodeEnum.AOTC;
  const [authorizingVet, setAuthorizingVet] = useState(reviewOTC.authorizingVet);
  const [authorizingVetLicenseKey, setAuthorizingVetLicenseKey] = useState('');
  const isApprovalTypeRequest = pendingRequestType === WorkFlowType.APPROVALS;
  const isRenewalTypeRequest = pendingRequestType === WorkFlowType.RENEWALS;
  values.acknowledgeErrors = getSmartScribeError(props?.flaggedGridValues);

  // Only validate after first submit
  useEffect(() => {
    if (submitCount > 1) {
      setShouldValidateOnAction(true);
    }
  }, [submitCount, setShouldValidateOnAction]);

  // Scroll Errors into view
  useEffect(() => {
    const keys = r.keys(errors);

    if (keys.length && isSubmitting && !isValidating) {
      // Find first error element
      const errorEl = document.querySelector(`label[for="${keys[0]}"]`);

      if (errorEl) {
        errorEl.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [isSubmitting, isValidating, errors]);

  const getVetLicense = (vetKey: string) => {
    const vet = props.reviewOTC.authorizingVets.find(x => x.veterinarianKey === vetKey);
    values.veterinarianLicenseKey = vet?.primaryVetLicenseNumber ? vet?.primaryVetLicenseNumber : "";
    return vet?.primaryVetLicenseNumber ? vet?.primaryVetLicenseNumber : "";
  }

  if(props.reviewOTC.authorizingVet && values.veterinarianLicenseKey === ""){
    getVetLicense(props.reviewOTC.authorizingVet);
  }

  return (
    <form onSubmit={handleSubmit}>
      {!isApprovalTypeRequest && (
        <Notification errors={errors} handleChange={handleChange} isRenewalTypeRequest={isRenewalTypeRequest} />
      )}
      {isAOTC && (
        <InputWrapper>
          <Expiration
            handleChange={handleChange}
            errors={errors}
            fullWidth={true}
            defaultValue={reviewOTC.expirationDate}
          />
        </InputWrapper>
      )}
      <SubTitleH2>{messages.additionalDetails}</SubTitleH2>
      <NotesShortcuts
        onChange={(e: React.BaseSyntheticEvent) => {
          setFieldValue("notesToClient", e.target.value, false);
          handleChange(e);
        }}
        value={values.notesToClientShortcuts}
        errors={errors.notesToClientShortcuts}
      />
      <NotesToClient
        onChange={(e) => {
          setFieldValue("notesToClientShortcuts", "", false);
          handleChange(e);
        }}
        value={values.notesToClientShortcuts || values.notesToClient}
        errors={errors.notesToClient}
      />
      {isAOTC && <CompetitiveFax onChange={handleChange} />}{" "}
      {/* Show Autoship Toggle option for non-approval requests */}
      {!isApprovalTypeRequest &&
        <InputWrapper>
          <AutoshipDiv>
            <AutoshipIcon />
            {messages.autoship}
            <label>
              <input
                onChange={handleChange}
                id={"autoship"}
                name={"autoship"}
                type="checkbox"
                defaultChecked={reviewOTC.autoship}
              />
              <span />
            </label>
          </AutoshipDiv>
        </InputWrapper>
      }{" "}
      <InputWrapper>
        <AuthorizingVet
          authorizingVets={reviewOTC.authorizingVets}
          errors={errors.authorizingVet || errors.veterinarianLicenseKey}
          onChange={(e: React.BaseSyntheticEvent) => {
            values.authorizingVet = e.target.value;
            setAuthorizingVet(e.target.value);
            setFieldValue("veterinarianLicenseKey", getVetLicense(e.target.value), true);
            handleChange(e);
          }}
          value={authorizingVet ?? ''}
        />
        <StateLicenseSelect
          authorizingVet={reviewOTC.authorizingVets.filter(v => v.veterinarianKey === authorizingVet)?.[0]}
          onChange={(e: React.BaseSyntheticEvent) => {
            setAuthorizingVetLicenseKey(e.target.value);
            setFieldValue("veterinarianLicenseKey", e.target.value, true);
            handleChange(e);
          }}
          value={authorizingVetLicenseKey}
        />
        <TopPaddingSpacer>
          {!isAOTC &&
            getAuthorizingVetWarningOtc(values.authorizingVet, reviewOTC.authorizingVets).map((warning, index) => (
              <FieldWarning key={index} warning={warning.message} />
            ))}
          {isAOTC &&
            getAuthorizingVetWarningAotc(values.authorizingVet, reviewOTC.authorizingVets).map((warning, index) => (
              <FieldWarning key={index} warning={warning.message} />
            ))}
        </TopPaddingSpacer>
      </InputWrapper>
      {flaggedIssuesHasLength(props?.flaggedGridValues) ? (
        <FlaggedIssuesError values={values} comparisonGrid={props.flaggedGridValues} handleChange={handleChange} errors={errors} />
      ) : null}
      {reviewOTC.authorizedWithChanges && isAOTC ? (
        <ApproveWithChangesDialog
          reason={values.approveWithChangeReason || ""}
          note={values.approveWithChangeClientNote || ""}
          reasonError={errors.approveWithChangeReason}
          noteError={errors.approveWithChangeClientNote}
          setReason={(reason) => {
            setFieldValue("approveWithChangeReason", reason, false);
          }}
          setNote={(note) => {
            setFieldValue("approveWithChangeClientNote", note, false);
          }}
        />
      ) : null}
      <SpacerDiv>
        <SubmitRxBtn type={"submit"} btnStyle={"primary"} disabled={disableSubmit}>
          {sendButton}
        </SubmitRxBtn>
      </SpacerDiv>
    </form>
  );
};

const handleOnSubmit = (
  values: InitialOTCvalues | InitialAOTCvalues,
  submitRx: SubmitRxMutationFn,
  claimCheckKey: string,
  linkedGuids: LinkedGuids,
  setSubmitting: (isSubmitting: boolean) => void,
  setIsFormSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch<AnyAction>,
  history: H.History<any>,
  prescriptionGuid?: string,
) => {
  const variables = transformRxForApi(values, linkedGuids, claimCheckKey);
  submitRx({
    variables,
  })
    .then(() => {
      setSubmitting(false);
      setIsFormSubmitting(false);
      if (prescriptionGuid) {
        history.push(`/rx-success/${claimCheckKey}/${prescriptionGuid}`);
      } else {
        history.push(`/rx-success/${claimCheckKey}`);
      }
    })
    .catch((e) => {
      setSubmitting(false);
      setIsFormSubmitting(false);
      const errorMessage = `${messages.submitRxError}`;
      dispatch(
        addToast({
          message: errorMessage,
          id: generateToastID(),
          kind: "error",
        }),
      );
    });
};

const OTCScriptForm = (props: Props) => {
  const {
    reviewOTC,
    hooksAndState: { shouldValidateOnAction, setIsFormSubmitting },
    legalCategoryCode,
  } = props;
  const { claimCheckKey, prescriptionGuid } = useParams<{ claimCheckKey: string; prescriptionGuid?: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAOTC = legalCategoryCode === legalCategoryCodeEnum.AOTC;

  const initialValues = getInitialValues(
    props.flaggedGridValues,
    isAOTC,
    reviewOTC.authorizingVets,
    reviewOTC.authorizingVet,
    reviewOTC.expirationDate,
    reviewOTC.strength,
  );

  const validationSchema = getFormSchema(
    props.flaggedGridValues,
    isAOTC,
    reviewOTC.authorizedWithChanges || false,
    reviewOTC.authorizingVets,
  );

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={shouldValidateOnAction}
        validateOnBlur={shouldValidateOnAction}
        onSubmit={(values, { setSubmitting }) =>
          handleOnSubmit(
            values,
            props.submitRx,
            claimCheckKey,
            reviewOTC.linkedGuids,
            setSubmitting,
            setIsFormSubmitting,
            dispatch,
            history,
            prescriptionGuid,
          )
        }
      >
        {() => <Form {...props} />}
      </Formik>
    </div>
  );
};

export default OTCScriptForm;
