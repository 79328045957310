import { Card } from "../../components/StyledComponents/Card/Card";
import styled from "styled-components/macro";
import { Input } from "../../components/StyledComponents/Input/Input";
import { Button } from "../../components/StyledComponents/Button/Button";

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 32px;
`;

export const LoginCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 464px;
  max-height: 550px;
  padding: 32px 32px 36px 32px;
`;

export const FormControlsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const TermsContainer = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.primaryTextColor};
  margin: 24px 0 32px 0;
  & a {
    color: ${(props) => props.theme.accentColor};
    text-decoration: underline;
  }
`;

export const FormTitle = styled.h1`
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 28px;
`;

export const UsernameInput = styled(Input)`
  margin-bottom: 28px;
`;

export const LoginButton = styled(Button)`
  width: 128px;
  margin-left: 16px;
`;

export const LinkButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  text-decoration: underline;
  color: #354580;
  cursor: pointer;
  font-family: inherit;
`;
