import * as React from "react";
import { AlertCircleIcon } from "../../../../assets/AlertCircleIcon";
import { InputErrorDiv } from "../../styles";
import { Props } from "./types";

const FieldError = (props: Props) => {
  const { error, dataTestId, hideAlertIcon } = props;
  return (
    // `data-testid` is for testing purposes
    <InputErrorDiv data-testid={dataTestId}>
      {error}
      {hideAlertIcon ? null : <AlertCircleIcon widthStyle="20px" heightStyle="20px" /> }
    </InputErrorDiv>
  );
};

export default FieldError;
