import * as r from "ramda";
import { Formik, FormikProps } from "formik";
import * as React from "react";
import { AlertCircleIcon } from "../../assets/AlertCircleIcon";
import { CheckmarkCircledIcon } from "../../assets/CheckmarkCircledIcon";
import { ClientIcon } from "../../assets/ClientIcon";
import commonMessages from "../../common/messages";
import { DescriptionComponent } from "../../components/DescriptionComponent";
import { FormError } from "../../components/FormError/FormError";
import { getDisplayPimsName } from "../../components/PimsLogo/getDisplayPimsName";
import { isValidSpeciesType, SpeciesPicker, mapSpecies } from "../../components/Species/Species";
import { Button } from "../../components/StyledComponents/Button/Button";
import { Container } from "../../components/StyledComponents/Container/Container";
import { Input } from "../../components/StyledComponents/Input/Input";
import { InputLabel } from "../../components/StyledComponents/InputLabel/InputLabel";
import Loading from "../../components/StyledComponents/Loading/Loading";
import ModalWrapper from "../../components/StyledComponents/Modal/Modal";
import { GlobalStateContext } from "../../context/state";
import { PatientGender, WeightUOM, PatientGenderAltered, useRetrieveSpeciesQuery } from "../../data/gql/apiTypes";
import { smoothScroll } from "../../domUtils";
import { exists } from "../../util";
import submitErrors from "./errors";
import getFormSchema from "./formValidation";
import messages from "./messages";
import DropdownSelector from "../../components/DropdownSelector";
import {
  CopyText,
  DetailsDiv,
  DivNoPadding,
  ImportCard,
  InputDiv,
  InputErrorDiv,
  InputInlineDiv,
  modalStyleProps,
} from "./styles";
import { FormProps, PatientClientFormValues } from "./types";
import Tooltip from "../../components/Tooltip";

const PatientImportForm = (props: FormProps) => {
  const {
    data,
    user,
    claimCheck,
    claimCheckKey,
    importPatient,
    importClient,
    setOnSubmitSuccess,
    onSubmitErrors,
    setOnSubmitErrors,
    didSubmitOnce,
    setDidSubmitOnce,
    existingClientGuid,
    setExistingClientGuid,
  } = props;

  const context = React.useContext(GlobalStateContext);
  const pimsType = context.state.session.pimsType || "";
  const loggedInUsername = user.username;
  const [speciesList, setSpeciesList] = React.useState([{ speciesCode: "", name: "", categoryCode: "" }] || []);
  const [hidePatientSpeciesField, setHidePatientSpeciesField] = React.useState(false);
  const [isUnknownErrorFound, setIsUnKnownErrorFound] = React.useState(false);

  const species = useRetrieveSpeciesQuery();
  const filteredSpecies = species.data?.retrieveSpecies
    .filter((x) => x.isDeleted === false)
    .map(({ speciesCode, name, categoryCode }) => ({ speciesCode, name, categoryCode }));

  React.useEffect(() => {
    smoothScroll();

    if (!species.loading && filteredSpecies) {
      setSpeciesList(filteredSpecies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [species]);

  const handleSubmitErrors = (e: Error) => {
    let knownErrorFound = false;
    for (const [, { upstreamErrorSubstring }] of Object.entries(submitErrors)) {
      if (e.message.includes(upstreamErrorSubstring)) {
        setOnSubmitErrors("Client with email already exists");
        smoothScroll(64);
        knownErrorFound = true;
      }
    }
    if (!knownErrorFound) {
      console.error(e);
      setIsUnKnownErrorFound(true);
      setOnSubmitErrors(" ");
      smoothScroll(64);
    }
  };

  const handleOnSubmit = (values: PatientClientFormValues) => {
    setOnSubmitErrors("");

    const patientArgs = {
      name: values.patientName,
      dateOfBirth: values.patientBirthdate,
      sexCode: PatientGender[values.patientSex],
      sexAltered: PatientGenderAltered[values.patientSexAltered],
      weight: values.patientWeight.toString(),
      weightUnitCode: WeightUOM.LBS,
      speciesCode: values.patientSpecies,
      claimCheckKey,
      vdsClientKey: claimCheck.payload.client?.vdsKey,
      pimsClientKey: claimCheck.payload.client?.pmsKey,
      vdsPatientKey: claimCheck.payload.patient?.vdsKey,
      pimsPatientKey: claimCheck.payload.patient?.pmsKey,
      installationKey: claimCheck.installationKey
    };

    if (!data?.retrieveRxByGuidOrClaimCheckGuid?.linkedClientKey && !exists(existingClientGuid)) {
      importClient({
        variables: {
          client: {
            givenName: values.clientGivenName,
            familyName: values.clientFamilyName,
            emailAddress: values.clientEmailAddress,
            claimCheckKey,
            phoneNumber: values.clientPhoneNumber,
            dateOfBirth: claimCheck.payload.client?.dateOfBirth || "",
          },
        },
      })
        .then((result) => {
          setExistingClientGuid(result.data?.importClient || null);
          return importPatient({
            variables: {
              patient: {
                ...patientArgs,
                clientKey: result.data?.importClient || "",
              },
            },
          });
        })
        .then(() => {
          setOnSubmitSuccess(true);
        })
        .catch((e: Error) => {
          handleSubmitErrors(e);
        });
    } else {
      importPatient({
        variables: {
          patient: {
            ...patientArgs,
            clientKey: data?.retrieveRxByGuidOrClaimCheckGuid?.linkedClientKey || existingClientGuid || "",
          },
        },
      })
        .then(() => {
          setOnSubmitSuccess(true);
        })
        .catch((e: Error) => {
          handleSubmitErrors(e);
        });
    }
  };

  if (!data?.retrieveRxByGuidOrClaimCheckGuid || !claimCheck.payload || !claimCheck.payload.patient || !claimCheck.payload.client) {
    return null;
  }

  const { linkedClientKey } = data?.retrieveRxByGuidOrClaimCheckGuid;
  const {
    name: patientName,
    speciesDescription,
    gender,
    dateOfBirth,
    currentWeight,
  } = claimCheck.payload?.patient;

  const {
    firstName: clientGivenName,
    lastName: clientFamilyName,
    email,
    phoneNumber,
  } = claimCheck.payload?.client;

  const genderEnum =
    gender?.toLowerCase().includes("female")
      ? PatientGender[PatientGender.FEMALE]
      : gender?.toLowerCase().includes("male")
      ? PatientGender[PatientGender.MALE]
      : PatientGender[PatientGender.UNKNOWN];

  const genderAlteredEnum = PatientGenderAltered[PatientGenderAltered.UNALTERED];

  const mappedSpeciesDescription = mapSpecies(speciesDescription);
  const speciesType = isValidSpeciesType(mappedSpeciesDescription) ? mappedSpeciesDescription.toUpperCase() : "";

  return (
    <Formik<PatientClientFormValues>
      initialValues={{
        clientGivenName,
        clientFamilyName,
        clientPhoneNumber: phoneNumber || "",
        clientEmailAddress: email || "",
        patientName,
        patientSpeciesType: speciesType,
        patientSpecies: speciesType, // set it as species type for "DOG", "CAT", "HORSE" and "RABBIT"
        patientSex: genderEnum,
        patientSexAltered: genderAlteredEnum,
        patientWeight: parseFloat(currentWeight || ""),
        patientBirthdate: dateOfBirth || "",
      }}
      validationSchema={getFormSchema(linkedClientKey || existingClientGuid)}
      validateOnChange={didSubmitOnce}
      validateOnBlur={didSubmitOnce}
      onSubmit={(values) => {
        handleOnSubmit(values);
      }}
    >
      {(formikBag: FormikProps<PatientClientFormValues>) => {
        const {
          handleChange,
          handleSubmit,
          isValidating,
          isSubmitting,
          setSubmitting,
          setFieldError,
          values,
          errors,
          setFieldValue
        } = formikBag;

        if (isValidating) {
          setDidSubmitOnce(true);
        }

        if (
          onSubmitErrors === submitErrors.dupeEmaiError.errorCopy &&
          Object.keys(errors).length === 0 &&
          !existingClientGuid
        ) {
          setFieldError("clientEmail", "This email address is already in use");
          setOnSubmitErrors("");
          setSubmitting(false);
        }

        if (values.patientSpeciesType.toUpperCase() === "" ||
        values.patientSpeciesType.toUpperCase() === "DOG" ||
        values.patientSpeciesType.toUpperCase() === "CAT" ||
        values.patientSpeciesType.toUpperCase() === "HORSE" ||
        values.patientSpeciesType.toUpperCase() === "RABBIT") {
          setHidePatientSpeciesField(true)
        }
        else {
          setHidePatientSpeciesField(false)
        }

        return (
          <form onSubmit={handleSubmit}>
            <Container>
              {isSubmitting && !onSubmitErrors ? (
                <ModalWrapper styles={modalStyleProps}>
                  <Loading />
                </ModalWrapper>
              ) : null}
              <DescriptionComponent {...messages} pimsType={pimsType} username={loggedInUsername || ""} claimCheckKey={claimCheckKey} />
              <ImportCard>
                <div>
                  <h2>{messages.missingInfoHeader}</h2>
                  <CopyText>
                    {messages.missingInfoCopy}
                    <span>{messages.missingInfoRedCopy(getDisplayPimsName(pimsType))}</span>
                  </CopyText>
                </div>
                {onSubmitErrors && !isUnknownErrorFound ? <FormError reason={onSubmitErrors} className="error" /> : null}
                {
                  isUnknownErrorFound
                  ? <FormError reason={
                    linkedClientKey || exists(existingClientGuid)
                    ? 'The patient record was not saved. Please wait a moment and try again'
                    : 'The client and patient records were not saved. Please wait a moment and try again'
                  } className="error" /> 
                  : null
                }

                <DetailsDiv>
                  <div>
                    <ClientIcon widthStyle="48px" heightStyle="48px" />
                    <h3>{messages.clientHeader}</h3>
                  </div>
                  {linkedClientKey || exists(existingClientGuid) ? <CheckmarkCircledIcon /> : <></>}
                </DetailsDiv>
                {linkedClientKey || exists(existingClientGuid) ? (
                  <></>
                ) : (
                  <InputDiv>
                    <InputInlineDiv halfWidth={true}>
                      <InputLabel htmlFor={"clientGivenName"} label={"First Name"} required={true} errors={errors.clientGivenName} />
                      <InputLabel htmlFor={"clientFamilyName"} label={"Last Name"} required={true} errors={errors.clientFamilyName} />
                      <Input
                        id={"clientGivenName"}
                        name={"clientGivenName"}
                        type={"text"}
                        onChange={handleChange}
                        errors={errors.clientGivenName}
                        defaultValue={clientGivenName}
                      />
                      <Input
                        id={"clientFamilyName"}
                        name={"clientFamilyName"}
                        type={"text"}
                        onChange={handleChange}
                        errors={errors.clientFamilyName}
                        defaultValue={clientFamilyName}
                      />
                      {errors.clientGivenName ? (
                        <InputErrorDiv>
                          {errors.clientGivenName}
                          <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
                        </InputErrorDiv>
                      ) : (
                        <DivNoPadding />
                      )}
                      {errors.clientFamilyName ? (
                        <InputErrorDiv>
                          {errors.clientFamilyName}
                          <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
                        </InputErrorDiv>
                      ) : (
                        <DivNoPadding />
                      )}
                    </InputInlineDiv>
                    <InputLabel htmlFor={"clientEmailAddress"} label={commonMessages.email} required={true} errors={errors.clientEmailAddress} />
                    <Tooltip message={messages.missingInfoEmail} position="right" customStyles= {{ isTriggerFullWidth: true } } >
                      <Input
                        id={"clientEmailAddress"}
                        name={"clientEmailAddress"}
                        type={"text"}
                        onChange={handleChange}
                        fullWidth
                        errors={errors.clientEmailAddress}
                        defaultValue={email || ""}
                      />
                    </Tooltip>
                    {errors.clientEmailAddress ? (
                      <InputErrorDiv>
                        {errors.clientEmailAddress}
                        <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
                      </InputErrorDiv>
                    ) : null}
                    <InputLabel htmlFor={"clientPhoneNumber"} label={"Phone Number"} required={true} errors={errors.clientPhoneNumber} />
                    <Input
                      id={"clientPhoneNumber"}
                      name={"clientPhoneNumber"}
                      type={"phone"}
                      onChange={handleChange}
                      fullWidth
                      errors={errors.clientPhoneNumber}
                      defaultValue={phoneNumber || ""}
                    />
                    {errors.clientPhoneNumber ? (
                      <InputErrorDiv>
                        {errors.clientPhoneNumber}
                        <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
                      </InputErrorDiv>
                    ) : null}
                  </InputDiv>
                )}
                <DetailsDiv>
                  <div>
                    <SpeciesPicker species={speciesType} useThumbnail={false} />
                    <h3>{messages.patientHeader}</h3>
                  </div>
                </DetailsDiv>
                <InputDiv>
                  <InputLabel htmlFor={"patientName"} label={commonMessages.name} required={true} errors={errors.patientName} />
                  <Input
                    id={"patientName"}
                    name={"patientName"}
                    type={"text"}
                    onChange={handleChange}
                    fullWidth
                    errors={errors?.patientName}
                    defaultValue={patientName}
                  />
                  {errors.patientName ? (
                    <InputErrorDiv>
                      {errors.patientName}
                      <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
                    </InputErrorDiv>
                  ) : null}

                  <InputInlineDiv halfWidth={true}>
                    <InputLabel
                      htmlFor={"patientSpeciesType"}
                      label={commonMessages.speciesType}
                      required={true}
                      errors={errors.patientSpeciesType}
                    />
                    <InputLabel htmlFor={"patientWeight"} label={commonMessages.weight} required={true} errors={errors.patientWeight} />

                    <Input
                      id={"patientSpeciesType"}
                      name={"patientSpeciesType"}
                      as={"select"}
                      onChange={(e: React.BaseSyntheticEvent) => {
                        if (
                          values.patientSpeciesType.toUpperCase() === "DOG" ||
                          values.patientSpeciesType.toUpperCase() === "CAT" ||
                          values.patientSpeciesType.toUpperCase() === "HORSE" ||
                          values.patientSpeciesType.toUpperCase() === "RABBIT"
                        ) {
                          setFieldValue("patientSpecies", values.patientSpeciesType.toUpperCase(), false);
                          setHidePatientSpeciesField(true);
                        } else {
                          setFieldValue("patientSpecies", "", false);
                          setHidePatientSpeciesField(false);
                        }
                        handleChange(e)
                      }}
                      errors={errors?.patientSpeciesType}
                      defaultValue={speciesType || ''}
                    >
                      {speciesType === "" ? <option value="" /> : null}
                      <option value="DOG">Dog</option>
                      <option value="HORSE">Horse</option>
                      <option value="CAT">Cat</option>
                      <option value="RABBIT">Rabbit</option>
                      <option value="REPTILE">Reptile</option>
                      <option value="BIRD">Bird</option>
                      <option value="SMALL_MAMMAL">Small Mammal</option>
                      <option value="OTHER">Other</option>
                    </Input>

                    <Input
                      id={"patientWeight"}
                      name={"patientWeight"}
                      placeholder={"lbs"}
                      type={"number"}
                      step="0.001"
                      max="9999.999"
                      onChange={handleChange}
                      errors={errors?.patientWeight}
                      defaultValue={parseFloat(currentWeight || "").toFixed(3)}
                    />
                    {errors.patientSpeciesType ? (
                      <InputErrorDiv>
                        {errors.patientSpeciesType}
                        <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
                      </InputErrorDiv>
                    ) : (
                      <DivNoPadding />
                    )}
                    {errors.patientWeight ? (
                      <InputErrorDiv>
                        {errors.patientWeight}
                        <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
                      </InputErrorDiv>
                    ) : (
                      <DivNoPadding />
                    )}
                  </InputInlineDiv>

                  {hidePatientSpeciesField ? (
                    <></>
                  ) : (
                    <DropdownSelector
                      id="patientSpecies"
                      inputLabel="Species"
                      required={true}
                      value={values.patientSpecies}
                      errors={errors.patientSpecies}
                      onChange={(e: React.BaseSyntheticEvent) => {
                        handleChange(e);
                      }}
                      opts={r.map(
                        (option) => ({
                          value: option.speciesCode,
                          label: option.name,
                        }),
                        speciesList.filter((x) => x.categoryCode === values.patientSpeciesType.toUpperCase()),
                      )}
                      tooltip={{
                        message: "No Species Found",
                        customStyles: { isElHalfWidth: true, isTriggerFullWidth: true },
                      }}
                    />
                  )}

                  <InputInlineDiv halfWidth={true}>
                    <InputLabel htmlFor={"patientSex"} label={commonMessages.sex} required={true} errors={errors.patientSex} />
                    <InputLabel htmlFor={"patientSexAltered"} label={commonMessages.sexAltered} required={false} />

                    <Input
                      id={"patientSex"}
                      name={"patientSex"}
                      required={true}
                      as={"select"}
                      onChange={handleChange}
                      errors={errors?.patientSex}
                      defaultValue={genderEnum}
                    >
                      {genderEnum === "UNKNOWN" ? <option value="" hidden /> : null}
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                    </Input>
                    <Input
                      id={"patientSexAltered"}
                      name={"patientSexAltered"}
                      as={"select"}
                      onChange={handleChange}
                      defaultValue={genderAlteredEnum}
                    >
                      {genderAlteredEnum === "UNALTERED" ? <option value="" hidden /> : null}
                      <option value="ALTERED">Altered</option>
                      <option value="UNALTERED">Unaltered</option>
                      <option value="UNKNOWN">Unknown</option>
                    </Input>
                    {errors.patientSex ? (
                      <InputErrorDiv>
                        {errors.patientSex}
                        <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
                      </InputErrorDiv>
                    ) : (
                      <DivNoPadding />
                    )}
                  </InputInlineDiv>

                  <InputLabel htmlFor={"patientBirthdate"} label={"Birthdate"} required={true} errors={errors.patientBirthdate} />
                  <Input
                    id={"patientBirthdate"}
                    name={"patientBirthdate"}
                    type={"date"}
                    onChange={handleChange}
                    fullWidth
                    errors={errors?.patientBirthdate}
                    defaultValue={dateOfBirth || ""}
                  />
                  {errors.patientBirthdate ? (
                    <InputErrorDiv>
                      {errors.patientBirthdate}
                      <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
                    </InputErrorDiv>
                  ) : null}
                  {/* <InputLabel
                    htmlFor={"patientPrimaryVetGuid"}
                    label={"Primary Veterinarian"}
                    required={true}
                    errors={errors.patientPrimaryVetGuid}
                  />
                  <Input
                    as="select"
                    id={"patientPrimaryVetGuid"}
                    name={"patientPrimaryVetGuid"}
                    onChange={handleChange}
                    fullWidth
                    errors={errors?.patientPrimaryVetGuid}
                    defaultValue={prescribingVetKey || ""}
                  >
                    <option value="" hidden />
                    {vetSelectOptions}
                  </Input>
                  {errors.patientPrimaryVetGuid ? (
                    <InputErrorDiv>
                      {errors.patientPrimaryVetGuid}
                      <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
                    </InputErrorDiv>
                  ) : null} */}
                </InputDiv>
                <div>
                  <Button type={"submit"} onClick={ () => {if(hidePatientSpeciesField) { setFieldValue("patientSpecies", values.patientSpeciesType.toUpperCase(), false) }} } >{"Import & Link"}</Button>
                </div>
              </ImportCard>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default PatientImportForm;
