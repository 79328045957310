import styled from "styled-components/macro";
import { Card } from "../../components/StyledComponents/Card/Card";

export const FinishScriptWrapper = styled(Card)`
  max-width: 720px;
  margin-bottom: 24px;
  padding-left: 160px;
  padding-right: 160px;

  div {
    text-align: left;

    h1 {
      text-align: center;

      padding: 30px 0 10px 0;
      font-size: 20px;
      color: #021660;
    }

    h2 {
      padding: 20px 0;
      font-size: 18px;
      color: #4d5051;
      font-weight: bold;
    }
  }
`;
