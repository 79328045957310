import styled from "styled-components/macro";
import { Card } from "../Card/Card";
import React from "react";
import { CloseGreyIcon } from "../../../assets/CloseGreyIcon";

export type Props = {
  children?: React.ReactNode;
  onClose?: (e: React.SyntheticEvent) => void;
  styles?: StyleProps;
  cancellable?: boolean;
};

export type StyleProps = {
  widthStyle?: string;
  heightStyle?: string;
  xTopStyle?: string;
  xRightStyle?: string;
};

export const ModalOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const Modal = styled(Card)<StyleProps>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => props.widthStyle || "704px"};
  max-width: 100%;
  height: ${(props) => props.heightStyle || "750px"};
  max-height: 80%;
  z-index: 100;
  color: ${({ theme }) => theme.primaryTextColor};
  .close-modal {
    position: absolute;
    cursor: pointer;
    top: ${(props) => props.xTopStyle || "12px"};
    right: ${(props) => props.xRightStyle || "20px"};
    padding-right: 5px;
    padding-left: 5px;
    z-index: 1;
  }
  .close-modal:focus {
    outline: none;
  }
`;

export const ModalGuts = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 32px;
`;

export default (props: Props) => (
  <>
    <ModalOverlay onClick={props.cancellable ? props.onClose : () => {}} />
    <Modal {...props.styles}>
      {props.cancellable && (<div onClick={props.onClose} className="close-modal" tabIndex={0} role="button">
        <CloseGreyIcon />
      </div>)}
      <ModalGuts>{props.children}</ModalGuts>
    </Modal>
  </>
);
