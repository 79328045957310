import * as React from "react";
import { useSession } from "../../hooks/sessionFlow";
import FaqModal from "./FaqModal";
import messages from "./messages";

const PimsFaqPicker = (props: {returnTo: string}) => {
  const { pimsType } = useSession();
  // Defaulting to AVImark for now, adding eVet FAQ in RX-659
  const { makeFaqItems, ...rest } = messages;
  const faqItems = makeFaqItems(pimsType);
  return <FaqModal returnTo={props.returnTo} faqItems={faqItems} {...rest} />;
};

export default PimsFaqPicker;
