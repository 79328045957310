import * as React from "react";
import * as r from "ramda";
import { pims } from "../PimsLogo/PimsLogo";
import { Description, Header } from "./styles";
import { Props } from "./types";
import SwitchUser from "../SwitchUser";

export const DescriptionComponent = (props: Props) => {
  const { header, description, pimsType, username, claimCheckKey } = props;
  return (
    pimsType !== undefined && username !== undefined && (r.toLower(pimsType) === r.toLower(pims.avimark)) ?
      <>
        <SwitchUser username={username || ""} claimCheckKey={claimCheckKey} />
        <Header>{header}</Header>
        <Description>{description}</Description>
      </>
    : <>
      <Header>{header}</Header>
      <Description>{description}</Description>
    </>
  );
};
