import * as React from "react";
import { Props } from "./types";
import DropdownSelector from "../../../DropdownSelector";
import messages from "../../messages";
import * as r from "ramda";
import { HelpLabel } from "../../styles";

const AuthorizingVet = (props: Props) => {
  const { authorizingVets, errors, value, onChange } = props;
  return (
    <DropdownSelector
      noSpacer
      id="authorizingVet"
      inputLabel={messages.authVet}
      required={true}
      errors={errors}
      value={value}
      onChange={onChange}
      opts={r.map(
        (vet) => ({
          value: vet.veterinarianKey,
          label: `${vet.givenName} ${vet.familyName}`,
        }),
        authorizingVets,
      )}
      helpFooter={<HelpLabel>{messages.eSignature}</HelpLabel>}
    />
  );
};

export default AuthorizingVet;
