import React from "react";
import { Props } from "./types";
import messages from "./messages";
import { LoggedInUser, SwitchUserLink } from "./styles";
import { logoutAndRedirect } from "../../domUtils";

export const SwitchUser = (props: Props) => {
    const {username, claimCheckKey} = props;
    return (
        <>
           <LoggedInUser>{messages.loggedInUser(username)}</LoggedInUser>
            <SwitchUserLink onClick={logoutAndRedirect(claimCheckKey)}>Switch User</SwitchUserLink>
        </>
    );
};

export default SwitchUser;