import styled from "styled-components/macro";

interface TextAreaProps {
  fullWidth?: boolean;
  errors?: string;
  warnings?: boolean;
  height?: number;
}
export const TextArea = styled.textarea<TextAreaProps>`
  background-color: #f5f5f5;
  height: ${(props) => (props.height ? `${props.height}px` : "72px")};
  border: none;
  border-bottom: ${(props) => {
    if (props.errors) {
      return `2px solid ${props.theme.errorDarkColor}`;
    }
    if (props.warnings) {
      return `2px solid #ffc630`;
    }
    return `1px solid ${props.theme.primaryTextColor}`;
  }};
  padding: 8px 10px;
  font-size: 14px;
  width: ${(props) => (props.fullWidth ? "100%" : undefined)};
`;
