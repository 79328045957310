import React from "react";
import { Props } from "./types";
import { SpacerErrorDiv } from "../../styles";
import { InputLabel } from "../../../StyledComponents/InputLabel/InputLabel";
import messages from "../../messages";
import { Input } from "../../../StyledComponents/Input/Input";
import FieldError from "../FieldError";

export const TOMORROW = new Date(new Date().setHours(24, 0, 0, 0)).toISOString().substring(0, 10);

const Expiration = (props: Props) => {
  const { handleChange, errors, fullWidth = false, defaultValue } = props;

  const convertDateStringToISO = (dateString: string | undefined) => {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    return date.toISOString().substring(0,10);
  };
  
  return (
    <>
      <InputLabel htmlFor={"expiration"} label={messages.expDateLabel} required={true} errors={errors.expiration} />
      <Input
        id={"expiration"}
        name={"expiration"}
        onChange={handleChange}
        type="date"
        fullWidth={fullWidth}
        min={TOMORROW}
        errors={errors.expiration}
        defaultValue={convertDateStringToISO(defaultValue)}
      />
      {errors.expiration ? <FieldError error={errors.expiration} dataTestId={"input-error-div"} /> : <SpacerErrorDiv />}
    </>
  );
};

export default Expiration;
