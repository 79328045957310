import styled from "styled-components/macro";

interface InputProps {
  fullWidth?: boolean;
  errors?: string;
  warnings?: boolean;
  disabled?: boolean;
}
export const Input = styled.input<InputProps>`
  background-color: #f5f5f5;
  height: 32px;
  border: none;
  border-bottom: ${(props) => {
    if (props.errors) {
      return `2px solid ${props.theme.errorDarkColor}`;
    }
    if (props.warnings) {
      return `2px solid #ffc630`;
    }
    return `1px solid ${props.theme.primaryTextColor}`;
  }};
  padding: 7px 10px;
  font-size: 14px;
  width: ${(props) => (props.fullWidth ? "100%" : undefined)};
`;

export const InputUnitsPerFill = styled.input<InputProps>`
  background-color: #f5f5f5;
  height: 32px;
  border: none;
  border-bottom: ${(props) => {
    if (props.errors) {
      return `2px solid ${props.theme.errorDarkColor}`;
    }
    if (props.warnings) {
      return `2px solid #ffc630`;
    }
    return `1px solid ${props.theme.primaryTextColor}`;
  }};
  padding: 7px 10px;
  font-size: 14px;
  width: ${(props) => (props.fullWidth ? "100%" : undefined)};
  &:disabled { color: -internal-light-dark(graytext, rgb(170, 170, 170));
                opacity: 0.7; }
`;