import * as React from 'react';
import { map } from "ramda";
import { 
    HeaderText,
    Container,
    InputWrapper,
    FormContainer,
    HelpLabel
} from "./styles";
import { TextArea } from "../StyledComponents/TextArea/TextArea";
import { InputLabel } from "../StyledComponents/InputLabel/InputLabel";
import { useGetNotesShortcutsQuery } from "../../data/gql/apiTypes";
import messages from "./messages";
import DropdownSelector from "../DropdownSelector";
import { defaultReasons } from "./types";

interface Props {
    reason: string;
    setReason: (reason: string) => void;
    note: string;
    setNote: (note: string) => void;
    reasonError?: string;
    noteError?: string;
}

const ApproveWithChangesDialog = (props: Props) => {
    const [noteShortcut, setNoteShortcut] = React.useState("");

    return (
        <Container>
            <HeaderText>
                <h2>{messages.header}</h2>
                <h3>{messages.title}</h3>
                <p>{messages.subtitle}</p>
            </HeaderText>
            <FormContainer>
                <InputWrapper>
                    <DropdownSelector 
                        id="reason"
                        inputLabel={"Reason for Change"}
                        value={props.reason}
                        errors={props.reasonError ? "Reason for Change is a required field" : undefined}
                        required
                        onChange={(e: React.BaseSyntheticEvent) => props.setReason(e.target.value)}
                        opts={map(
                          (r: string) => ({
                            value: r,
                            label: r,
                          }),
                          defaultReasons,
                        )}
                    />
                </InputWrapper>
                <InputWrapper>
                    <DropdownSelector
                        id="noteShortcut"
                        inputLabel={"Notes to Client Shortcuts"}
                        value={noteShortcut}
                        onChange={(e: React.BaseSyntheticEvent) => {
                            props.setNote(e.target.value);
                            setNoteShortcut(e.target.value);
                        }}
                        useOptionsGetter={useGetNotesShortcutsQuery}
                        queryVars={{}}
                        getOptions={(results) =>
                            map(
                                (row) => ({
                                    value: row?.note || "",
                                    label: row?.label || "",
                                }),
                                results.retrieveNotesShortcuts || [],
                            )
                        }
                        helpFooter={<HelpLabel>{messages.clientNoteFooter}</HelpLabel>}
                    />
                </InputWrapper>
                <InputWrapper>
                        <InputLabel htmlFor="note" required={true} label={"Note to Client on declining requested order"} />
                        <TextArea 
                            id="note"
                            name="note"
                            fullWidth
                            height={100}
                            errors={props.noteError}
                            onChange={(c) => {
                                setNoteShortcut("");
                                props.setNote(c.target.value);
                            }}
                            value={props.note}
                        />
                    </InputWrapper>
            </FormContainer>
        </Container>
    );
}

export default ApproveWithChangesDialog;