import * as React from "react";
import ModalWrapper from "../StyledComponents/Modal/Modal";
import messages from "./messages";
import { Button } from "../StyledComponents/Button/Button";
import { TitleContainer, DescriptionContainer, ButtonContainer} from "./styles";

interface Props {
    onSubmit: () => void;
    onClose: () => void;
}

const RemoveRenewalModal = (props: Props) => {
    return (
        <ModalWrapper onClose={() => props.onClose()} styles={{heightStyle: '200px', widthStyle: '500px'}}>
            <TitleContainer>{messages.title}</TitleContainer>
            <DescriptionContainer>{messages.description}</DescriptionContainer>
            <ButtonContainer>
                <Button btnStyle={"secondary"} style={{padding: 10, marginRight: 10}} onClick={() => props.onClose()}>{messages.cancel}</Button>
                <Button onClick={() => props.onSubmit()} style={{padding: 10}}>{messages.submit}</Button>
            </ButtonContainer>
        </ModalWrapper>
    )
}

export default RemoveRenewalModal;