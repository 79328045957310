import { FlaggedIssues } from "./types";
import messages from "./messages";

export const convertFlaggedIssuesToGrid = (flaggedIssues: FlaggedIssues) => {
  const flaggedWeightIssue = flaggedIssues?.weight
    ? {
      label: messages.flaggedLabels.weight,
      expected: `${flaggedIssues?.weight.weightMin}-${flaggedIssues?.weight.weightMax} ${
        flaggedIssues?.weight.weightUnits || "lbs"
        }`,
      selected: `${flaggedIssues?.weight.selectedWeight} ${flaggedIssues?.weight.weightUnits || "lbs"}`,
      required: flaggedIssues?.weight.required,
    }
    : null;

  const flaggedSpeciesIssue = flaggedIssues?.species
    ? {
      label: messages.flaggedLabels.species,
      expected: `${flaggedIssues.species.expected}`,
      selected: `${flaggedIssues.species.selected}`,
      required: flaggedIssues?.species.required,
    }
    : null;

  const flaggedKeywordIssue = flaggedIssues?.keywords
    ? {
      label: messages.flaggedLabels.directionsKeywords,
      expected: `${flaggedIssues.keywords.expected}`,
      selected: `${flaggedIssues.keywords.selected}`,
      required: flaggedIssues?.keywords.required,
    }
    : null;

  const flaggedControlledProduct = flaggedIssues?.controlledProduct
    ? {
      label: messages.flaggedLabels.clientAge,
      expected: `${flaggedIssues.controlledProduct.expected}`,
      selected: `${flaggedIssues.controlledProduct.selected}`,
      required: flaggedIssues?.controlledProduct.required,
    }
    : null;

  const flaggedEssentialCopy = flaggedIssues?.productIsEssentialCopy ?
    {
      label: `${flaggedIssues.productIsEssentialCopy.label}`,
      expected: `${flaggedIssues.productIsEssentialCopy.expected}`,
      selected: `${flaggedIssues.productIsEssentialCopy.selected}`,
      toolTip: `${flaggedIssues.productIsEssentialCopy.tooltip}`,
      errorType: flaggedIssues.productIsEssentialCopy.errorType,
    }
    : null;

  const flaggedAuthorizingVetLicense = flaggedIssues?.authorizingVetLicense
    ? {
      label: `${flaggedIssues.authorizingVetLicense.label}`,
      expected: `${flaggedIssues.authorizingVetLicense.expected}`,
      selected: `${flaggedIssues.authorizingVetLicense.selected}`,
      toolTip: `${flaggedIssues.authorizingVetLicense.tooltip}`,
      errorType: flaggedIssues.authorizingVetLicense.errorType,
      required: flaggedIssues?.authorizingVetLicense.required,
    }
    : null;

  const flaggedAuthorizingVetDeaLicense = flaggedIssues?.authorizingVetDeaLicense
    ? {
      label: `${flaggedIssues.authorizingVetDeaLicense.label}`,
      expected: `${flaggedIssues.authorizingVetDeaLicense.expected}`,
      selected: `${flaggedIssues.authorizingVetDeaLicense.selected}`,
      toolTip: `${flaggedIssues.authorizingVetDeaLicense.tooltip}`,
      errorType: flaggedIssues.authorizingVetDeaLicense.errorType,
      required: flaggedIssues?.authorizingVetDeaLicense.required,
    }
    : null;

  const flaggedClientIdentification = flaggedIssues?.clientIdentification
    ? {
      label: `${flaggedIssues.clientIdentification.label}`,
      expected: `${flaggedIssues.clientIdentification.expected}`,
      selected: `${flaggedIssues.clientIdentification.selected}`,
      toolTip: `${flaggedIssues.clientIdentification.tooltip}`,
      errorType: flaggedIssues.clientIdentification.errorType,
      required: flaggedIssues?.clientIdentification.required,
    } : null;

  return [
    ...(flaggedWeightIssue ? [flaggedWeightIssue] : []),
    ...(flaggedSpeciesIssue ? [flaggedSpeciesIssue] : []),
    ...(flaggedKeywordIssue ? [flaggedKeywordIssue] : []),
    ...(flaggedControlledProduct ? [flaggedControlledProduct] : []),
    ...(flaggedAuthorizingVetLicense ? [flaggedAuthorizingVetLicense] : []),
    ...(flaggedAuthorizingVetDeaLicense ? [flaggedAuthorizingVetDeaLicense] : []),
    ...(flaggedEssentialCopy ? [flaggedEssentialCopy] : []),
    ...(flaggedClientIdentification ? [flaggedClientIdentification] : []),
  ];
};

export enum EssentialCopyReasons {
  doseNotAchievable = "DOSE_NOT_ACHIEVABLE",
  easeOfAdministration = "EASE_OF_ADMINISTRATION",
  adverseReactionToCommercial = "ADVERSE_REACTION_TO_COMMERCIAL",
  palatability = "PALATABILITY",
  positiveTherapeuticResponseToCompound = "POSITIVE_THERAPEUTIC_RESPONSE_TO_COMPOUND",
  backOrder = "BACK_ORDER",
  patientCannotSafelyPilled = "PATIENT_CANNOT_SAFELY_PILLED",
  other = "OTHER",
}

export function getEssentialCopyReasonPlaceholder(reason?: string): string {
  switch (reason) {
    case EssentialCopyReasons.doseNotAchievable:
      return messages.placeholders.essentialCopyReasons.DOSE_NOT_ACHIEVABLE;
    case EssentialCopyReasons.easeOfAdministration:
      return messages.placeholders.essentialCopyReasons.EASE_OF_ADMINISTRATION;
    case EssentialCopyReasons.adverseReactionToCommercial:
      return messages.placeholders.essentialCopyReasons.ADVERSE_REACTION_TO_COMMERCIAL;
    case EssentialCopyReasons.palatability:
      return messages.placeholders.essentialCopyReasons.PALATABILITY;
    case EssentialCopyReasons.positiveTherapeuticResponseToCompound:
      return messages.placeholders.essentialCopyReasons.POSITIVE_THERAPEUTIC_RESPONSE_TO_COMPOUND;
    case EssentialCopyReasons.backOrder:
      return messages.placeholders.essentialCopyReasons.BACK_ORDER;
    case EssentialCopyReasons.patientCannotSafelyPilled:
      return messages.placeholders.essentialCopyReasons.PATIENT_CANNOT_SAFELY_PILLED;
    default:
      return "";
  }
}

export function getEssentialCopyReasonTooltip(reason?: string): string {
  switch (reason) {
    case EssentialCopyReasons.doseNotAchievable:
      return messages.tooltip.essentialCopyReasons.DOSE_NOT_ACHIEVABLE;
    case EssentialCopyReasons.easeOfAdministration:
      return messages.tooltip.essentialCopyReasons.EASE_OF_ADMINISTRATION;
    case EssentialCopyReasons.adverseReactionToCommercial:
      return messages.tooltip.essentialCopyReasons.ADVERSE_REACTION_TO_COMMERCIAL;
    case EssentialCopyReasons.palatability:
      return messages.tooltip.essentialCopyReasons.PALATABILITY;
    case EssentialCopyReasons.positiveTherapeuticResponseToCompound:
      return messages.tooltip.essentialCopyReasons.POSITIVE_THERAPEUTIC_RESPONSE_TO_COMPOUND;
    case EssentialCopyReasons.backOrder:
      return messages.tooltip.essentialCopyReasons.BACK_ORDER;
    case EssentialCopyReasons.patientCannotSafelyPilled:
      return messages.tooltip.essentialCopyReasons.PATIENT_CANNOT_SAFELY_PILLED;
    default:
      return "";
  }
}

export function buildEssentialCopyReasons() {
  return [
    {
      value: EssentialCopyReasons.doseNotAchievable,
      label: "Specific dose not achievable with commercial product",
    },
    {
      value: EssentialCopyReasons.easeOfAdministration,
      label: "Ease of administration: dose burden or dose flexibility",
    },
    { value: EssentialCopyReasons.adverseReactionToCommercial, label: "Adverse reaction to commercial product" },
    { value: EssentialCopyReasons.palatability, label: "Palatability: cannot tolerate dose form" },
    {
      value: EssentialCopyReasons.positiveTherapeuticResponseToCompound,
      label: "Positive therapeutic response to compounded product",
    },
    { value: EssentialCopyReasons.backOrder, label: "MBO (Manufacturer Back Order)" },
    { value: EssentialCopyReasons.patientCannotSafelyPilled, label: " Patient cannot safely be pilled" },
    { value: EssentialCopyReasons.other, label: "Other" },
  ]
}
