import * as React from "react";

type Props = {
  className?: string;
};

export const SortArrowIcon = (props: Props) => (
  <svg
    width="7px"
    height="8.8px"
    viewBox="0 0 28 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>arrows-sortable</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="translate(-808.000000, -285.000000)" fill="#4D5051">
        <path
          d="M831.999,303.513555 L819.999587,315.514196 L808,303.513555 L831.999,303.513555 Z M834.456,299.802555 L835.083408,300.430375 L834.456,301.056555 L834.456,299.802555 Z M819.999587,285.346555 L831.292,296.638555 L808.707,296.638555 L819.999587,285.346555 Z"
          id="arrows-sortable"
        ></path>
      </g>
    </g>
  </svg>
);
