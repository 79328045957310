import styled from "styled-components/macro";

export const Header = styled.h1`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.primaryTextColor};

  padding: 30px 10px 10px 10px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.primaryTextColor};
  padding-bottom: 10px;
  max-width: 480px;
`;
