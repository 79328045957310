import { createStore, applyMiddleware, compose, Store } from "redux";
import rootReducer from "../reducers/rootReducer";
import { createLogger } from "redux-logger";

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const INITIAL_STATE = {
  toast: {
    toasts: [],
  },
};

const loggerMiddleware = createLogger();

export default function configureStore(initialState = INITIAL_STATE): Store {
  return createStore(
    rootReducer,
    initialState,
    process.env.NODE_ENV === "production" ? undefined : composeEnhancers(applyMiddleware(loggerMiddleware)),
  );
}
