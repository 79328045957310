import * as React from "react";
import { Props } from "./types";
import { useGetNotesShortcutsQuery } from "../../../../data/gql/apiTypes";
import * as r from "ramda";
import { InputWrapper } from "../../styles";
import DropdownSelector from "../../../DropdownSelector";
import messages from "../../messages";

const NotesShortcuts = (props: Props) => {
  const { onChange, errors, value } = props;
  return (
    <InputWrapper>
      <DropdownSelector
        id="notesToClientShortcuts"
        inputLabel={messages.clientShortcutLabel}
        errors={errors}
        value={value}
        onChange={onChange}
        useOptionsGetter={useGetNotesShortcutsQuery}
        queryVars={{}}
        getOptions={(results) =>
          r.map(
            (row) => ({
              value: row?.note || "",
              label: row?.label || "",
            }),
            results.retrieveNotesShortcuts || [],
          )
        }
        tooltip={{ message: "No Shortcuts Found", customStyles: { isElHalfWidth: true, isTriggerFullWidth: true } }}
      />
    </InputWrapper>
  );
};

export default NotesShortcuts;
