import * as React from "react";
import { AlertCircleIcon } from "../../../../assets/AlertCircleIcon";
import { theme } from "../../../../styles/theme";
import { InputWarningDiv } from "../../styles";
import { Props } from "./types";

const FieldWarning = (props: Props) => {
  const { warning, dataTestId, hideAlertIcon } = props;
  return (
    // `data-testid` is for testing purposes
    <InputWarningDiv data-testid={dataTestId}>
      {warning}
      {hideAlertIcon ? null : <AlertCircleIcon widthStyle="20px" heightStyle="20px" color={theme.warningToastColor} /> }
    </InputWarningDiv>
  );
};

export default FieldWarning;
