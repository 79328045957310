import { ToastType } from "../reducers/toast";

export const ADD_TOAST = "ADD_TOAST";
export const addToast = (toast: ToastType) => {
  return {
    type: ADD_TOAST,
    toast,
  };
};

export const REMOVE_TOAST = "REMOVE_TOAST ";
export const removeToast = (id: string | number) => {
  return {
    type: REMOVE_TOAST,
    id,
  };
};
