import styled from "styled-components/macro";
import { getTheme } from "../../styles/theme";

const theme = getTheme();
export const HeaderContainer = styled.div`
  display: flex;
  height: 48px;
  margin-bottom: 32px;
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  justify-content: center;
  h1 {
    color: ${({ theme }) => theme.secondaryTextColor};
    font-size: 20px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
  }
`;

export const TitleText = styled.h4`
  margin-bottom: 8px;
`;

export const DescriptionText = styled.div`
  margin-bottom: 32px;
  > p {
    margin-bottom: 8px;
  }
`;

export const ProductPricingTable = styled.table`
  margin-bottom: 32px;
  width: 100%;
`;

export const TableHead = styled.th`
  width: 20%;
  text-align: left;
  position: relative;
`;

export const TableCell = styled.td`
  border-top: 1px solid ${theme.tableBorderColor};
  padding-right: 8px;
  max-width: 138px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TableDataRow = styled.tr`
  background-color: #fafaf8;
`;

export const TooltipContainer = styled.span`
  position: absolute;
  padding-left: 5px;
  .tooltip-top {
    width: 350px !important;
    max-width: 350px !important;
  }
`;
