import { SearchPatientResult } from ".";
import * as r from "ramda";
import { ClaimCheckClientT, ClaimCheckPatientT } from "../../data/gql/apiTypes";
import * as strSimilar from "string-similarity";
import { SearchClientResult } from "../ClientLinking/ClientLinking";

export const singleMatchSuggestion = (
  gpmPatient: SearchPatientResult,
  pimsClient: ClaimCheckClientT,
  pimsPatient: ClaimCheckPatientT,
): [SearchPatientResult | null] | []  => {
  //const clientRanking = strSimilar.findBestMatch(pimsClient.firstName + " " + pimsClient.lastName, gpmPatient.clientName);
  if (
    //clientRanking.bestMatch.rating > 0.75 &&
    strSimilar.compareTwoStrings(r.toUpper(pimsClient.firstName + " " + pimsClient.lastName), r.toUpper(gpmPatient.clientName)) > 0.75 &&
    strSimilar.compareTwoStrings(r.toUpper(gpmPatient.name), r.toUpper(pimsPatient.name)) > 0.75
  ) {
    return [gpmPatient];
  }
  return [];
};

export const singleMatchClientSuggestion = (
  gpmClient: SearchClientResult,
  pimsClient: ClaimCheckClientT,
): boolean  => {
  const clientRanking = strSimilar.compareTwoStrings(r.toUpper(pimsClient.firstName + " " + pimsClient.lastName), r.toUpper(gpmClient.givenName + " " + gpmClient.familyName))
  if (
    clientRanking > 0.85
  ) {
    return true;
  }
  return false;
};
