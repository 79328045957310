import styled from "styled-components/macro";

export const Thumbnail = styled.div`
  & svg {
    width: 20px;
    height: 20px;
  }
  & rect {
    fill: transparent;
  }
`;
