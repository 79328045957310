import React, { useState } from "react";
import { Props } from "./types";
import { TooltipContainer, TooltipBubble, TooltipTrigger, TooltipMessage } from "./styles";

export const Tooltip = (props: Props) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  return (
    <TooltipContainer isElHalfWidth={props.customStyles?.isElHalfWidth} onMouseLeave={() => setDisplayTooltip(false)}>
      {displayTooltip && (
        <TooltipBubble className={`tooltip-${props.position}`}>
          <TooltipMessage className="tooltip-message">{props.message}</TooltipMessage>
        </TooltipBubble>
      )}
      <TooltipTrigger
        isTriggerFullWidth={props.customStyles?.isTriggerFullWidth}
        onMouseOver={() => setDisplayTooltip(true)}
      >
        {props.children}
      </TooltipTrigger>
    </TooltipContainer>
  );
};
