export default {
  header: "Confirm Request",
  description:
    "Review and confirm the prescription below. Once you've finished filling out the required fields, you can send it to the Covetrus Online Pharmacy for your client to purchase.",
  sendButton: "Authorize",
  copy: {
    partOne: "Any changes to Units per Fill, Refills, or Expiration Date will ",
    bold: "not be reflected",
    partTwo: (pimsType: string) => ` in the Medical History in ${pimsType || "your PIMS"}.`,
  },
  updateWeightSuccessful: "Successfully updated patient weight.",
  updateWeightFailure: "Failed to update patient weight.",
  unlinkProductFilure: "Failed to unlink product.",
  fetchApprovalsFailure: "Error fetching practice approvals.",
  fetchRenewalsFailure: "Error fetching practice renewals."
};
