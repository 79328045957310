import { Actions, RemoveSessionAction, Session } from "./types";
export const flowType = {
  linked: "linked" as "linked",
  unlinked: "unlinked" as "unlinked",
  pending: "pending" as "pending"
};

export const setSession = (session: Session) => {
  return {
    type: Actions.SET_SESSION,
    session,
  };
};
export const removeSession: RemoveSessionAction = () => {
  return { type: Actions.REMOVE_SESSION };
};
