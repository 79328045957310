import React, { createContext, useReducer } from "react";
import { ProviderProps, Store } from "./types";
import { sessionReducer } from "./reducers";

const initialState = {
  session: {
    flow: null,
    pimsType: null,
    claimCheckKey: null,
    useSSO: false
  },
};

export const GlobalStateContext = createContext<Store>({ state: initialState });

export default (props: ProviderProps) => {
  const [sessionState, dispatch] = useReducer(sessionReducer, initialState);

  const state = sessionState;

  return <GlobalStateContext.Provider value={{ state, dispatch }} children={props.children} />;
};
