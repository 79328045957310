import * as H from "history";
import React from "react";
import { InfoCircled } from "../../assets/InfoCircled";
import commonMessages from "../../common/messages";
import { pims } from "../../components/PimsLogo/PimsLogo";
import { requestProductLink } from "../../config/frontend";
import { SearchGPMProductsQueryResult, AutocompleteSearchGPMProductFamiliesQueryResult } from "../../data/gql/apiTypes";
import { exists, nullMap, avimarkShowUrl } from "../../util";
import { formatPrice } from "../../components/ProductDetailsModal/utils";
import { SearchGPMProductResult } from "./ProductUnlinked";
import { LinkIcon } from "../../assets/LinkIcon";
import Tooltip from "../../components/Tooltip";

type OnProductInfoClick = (productResult: SearchGPMProductResult) => void;
export const getGridHeaders = (location: H.Location, onProductInfoClick: OnProductInfoClick) => [
  {
    label: "Product Base Name",
    dataGetter: "baseProductName" as "baseProductName",
    width: "40%",
    primary: true,
    minWidth: 175,
  },
  {
    label: commonMessages.strength,
    dataGetter: "strength" as "strength",
    width: "20%",
    minWidth: 100,
  },
  {
    label: "Quantity",
    dataGetter: "size" as "size",
    width: "15%",
    minWidth: 100,
  },
  {
    label: commonMessages.species,
    dataGetter: (productResult: SearchGPMProductResult) =>
      nullMap((it) => it.slice().sort().join(", "), productResult.speciesCategoryCodes) || "",
    width: "15%",
    minWidth: 100,
  },
  {
    //adding a grid label here will break the tooltip overflow below
    dataGetter: (productResult: SearchGPMProductResult) => {
      return (
        <div style={{display: 'flex', height: '100%', alignItems: "center", justifyContent: 'center', gap: "8px"}}>
          <div onClick={(e) => {
            // Prevent the row's onclick from triggering
            e.stopPropagation();
            onProductInfoClick(productResult);
          }}>
            <InfoCircled />
          </div>
          {
            productResult.productLink && (
            <Tooltip message={`This Covetrus product is already linked to ${productResult.productLink.pimsProductName}`} position="right">
              <LinkIcon />
            </Tooltip>
            )
          }
        </div>
      );
    },
    width: "10%",
    minWidth: 60,
    overflow: true
  },
];

export const getCompoundProductGridHeaders = (location: H.Location, onProductInfoClick: OnProductInfoClick) => [
  {
    label: "Product Form",
    dataGetter: "productForm" as "productForm",
    width: "33%",
    primary: true,
    minWidth: 175,
  },
  {
    label: "Max. Shelf Life",
    dataGetter: "shelfLife" as "shelfLife",
    width: "20%",
    minWidth: 100,
    tooltipText: "Shelf life of received product varies depending on date compounded and regulatory limitations."
  },
  {
    label: "Size",
    dataGetter: "size" as "size",
    width: "15%",
    minWidth: 100,
  },
  {
    label: "Patient Subtotal",
    dataGetter: (productResult: SearchGPMProductResult) => {
      return formatPrice(productResult.clientSubTotal)
    },
    width: "22%",
    minWidth: 100,
    tooltipText: "This is the retail price plus handling fee for one unit (additional units will cost more)."
  },
  {
    //adding a grid label here will break the tooltip overflow below
    dataGetter: (productResult: SearchGPMProductResult) => {
      return (
        <div style={{display: 'flex', height: '100%', alignItems: "center", justifyContent: 'center', gap: "8px"}}>
          <div onClick={(e) => {
            // Prevent the row's onclick from triggering
            e.stopPropagation();
            onProductInfoClick(productResult);
          }}>
            <InfoCircled />
          </div>
          {
            productResult.productLink && (
            <Tooltip message={`This Covetrus product is already linked to ${productResult.productLink.pimsProductName}`} position="right">
              <LinkIcon />
            </Tooltip>
            )
          }
        </div>
      );
    },
    width: "10%",
    minWidth: 60,
  }
];

export const getResultsProp = (qResult: SearchGPMProductsQueryResult, searchStep: "pre" | "automated" | "user") => {
  if (searchStep === "automated" && (!exists(qResult.data) || !qResult.data.searchProducts.length)) {
    return { kind: "noop" as "noop" };
  }
  if (qResult.loading) {
    return { kind: "loading" as "loading" };
  }
  if (exists(qResult.error)) {
    return { kind: "error" as "error", error: qResult.error };
  }
  return (
    nullMap((it) => ({ kind: "loaded" as "loaded", results: it.searchProducts }), qResult.data) || {
      kind: "noop" as "noop",
    }
  );
};

export const getAutoCompleteResultsProp = (qResult: AutocompleteSearchGPMProductFamiliesQueryResult, searchStep: "pre" | "automated" | "user") => {
  if (searchStep === "automated" && (!exists(qResult.data) || !qResult.data.autoCompleteSearchProductFamilies.length)) {
    return { kind: "noop" as "noop" };
  }
  if (qResult.loading) {
    return { kind: "loading" as "loading" };
  }
  if (exists(qResult.error)) {
    return { kind: "error" as "error", error: qResult.error };
  }
  return (
    nullMap((it) => ({ kind: "loaded" as "loaded", results: it.autoCompleteSearchProductFamilies }), qResult.data) || {
      kind: "noop" as "noop",
    }
  );
};

const validProductRequestPath = (location: H.Location<H.History>): boolean =>
  location.pathname.includes("product-linking") || location.pathname.includes("product-search");

export const getRequestNewProductHandler = (
  pimsType: string | null,
  location: H.Location<H.History>,
): (() => void) => {
  if (pimsType?.toLowerCase() === pims.avimark.toLowerCase() && validProductRequestPath(location)) {
    return () => avimarkShowUrl(requestProductLink);
  }
  if (pimsType?.toLowerCase() === pims.eVetShort.toLowerCase()
      || pimsType?.toLowerCase() === pims.eVetLong.toLowerCase()) {
    return () => window.open(requestProductLink, "_blank");
  }
  return () => window.open(requestProductLink, "_blank");
};
