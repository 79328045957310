import * as React from "react";
import { theme } from "../styles/theme";

type Props = {
  className?: string;
  widthStyle?: string;
  heightStyle?: string;
  color?: string;
};

export const AlertCircleIcon = (props: Props) => (
  <svg
    width={props.widthStyle || "34px"}
    height={props.heightStyle || "34px"}
    viewBox="0 0 34 34"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={props.className}
  >
    <title>alert-circled-red</title>
    <g id="🖋-Forms" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Forms-/-Validation-/-Error"
        transform="translate(-27.000000, -19.000000)"
        fill={props.color || theme.errorDarkColor || "#A92627"}
        fillRule="nonzero"
      >
        <g id="Icons/Material/Error" transform="translate(24.000000, 16.000000)">
          <g id="alert-circled-red">
            <path d="M20,3.33333333 C10.8,3.33333333 3.33333333,10.8 3.33333333,20 C3.33333333,29.2 10.8,36.6666667 20,36.6666667 C29.2,36.6666667 36.6666667,29.2 36.6666667,20 C36.6666667,10.8 29.2,3.33333333 20,3.33333333 Z M21.6666667,28.3333333 L18.3333333,28.3333333 L18.3333333,25 L21.6666667,25 L21.6666667,28.3333333 Z M21.6666667,21.6666667 L18.3333333,21.6666667 L18.3333333,11.6666667 L21.6666667,11.6666667 L21.6666667,21.6666667 Z"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
