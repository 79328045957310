import React from "react";
import styled from "styled-components/macro";

const releaseTag = process.env.REACT_APP_BUILD_NUMBER;
const HiddenSpan = styled.span`
  display: none;
`;

export const HiddenBuildNumber = () => {
  return <HiddenSpan>{`BUILD_NUMBER: ${releaseTag}`}</HiddenSpan>;
};

export default HiddenBuildNumber;
