import React from "react";
import { InputWrapper, SpacerErrorDiv, InputErrorDiv } from "../../styles";
import { Input } from "../../../StyledComponents/Input/Input";
import { InputLabel } from "../../../StyledComponents/InputLabel/InputLabel";
import { AlertCircleIcon } from "../../../../assets/AlertCircleIcon";
import messages from "../../messages";
import { Props } from "./types";

export const DEFAULT_NOTIFY_DATE = new Date(new Date().setHours(0, 0, 0, 0)).toISOString().substring(0, 10);
export const DEFAULT_UTC_NOTIFY_DATE = new Date(Date.UTC(new Date().getUTCFullYear(),new Date().getUTCMonth(), new Date().getUTCDate(), 0,0,0,0)).toISOString().substring(0, 10);

export const Notification = (props: Props) => {
  const { handleChange, errors, isRenewalTypeRequest } = props;
  return (
    <InputWrapper>
      <InputLabel htmlFor="notification" label={messages.notifDateLabel} required={true} errors={errors.notification} />
      <Input
        //DVM renew API does not accept notification date as of 03/2022, so we only display the read-only date in the UI.
        disabled={isRenewalTypeRequest}
        id="notification"
        name="notification"
        type="date"
        fullWidth
        min={DEFAULT_NOTIFY_DATE}
        onChange={handleChange}
        errors={errors.notification}
        defaultValue={DEFAULT_NOTIFY_DATE}
      />
      {errors.notification ? (
        <InputErrorDiv>
          {errors.notification}
          <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
        </InputErrorDiv>
      ) : (
        <SpacerErrorDiv />
      )}
    </InputWrapper>
  );
};
