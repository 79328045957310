import React from "react";

export const AutoshipIcon = () => (
  <svg width="24px" height="22px" viewBox="0 0 24 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>autoship</title>
      <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icon-Sheet" transform="translate(-752.000000, -347.000000)" fill="#006F95">
              <path d="M762.714554,348.891369 C763.248589,349.322857 763.654338,349.863314 763.977505,350.468105 C764.259719,350.090485 764.509051,349.703324 764.810874,349.361796 C765.667698,348.391853 766.781924,347.856321 768.042686,347.642155 C770.536082,347.217824 772.661286,347.935354 774.35163,349.803827 C775.554547,351.132921 776.051929,352.734744 775.995743,354.507638 C775.926735,356.669849 775.064179,358.551173 773.862091,360.304213 C772.699749,361.998229 771.262885,363.442601 769.695548,364.76577 C767.995173,366.202062 766.158141,367.447736 764.256551,368.603834 C764.096816,368.700952 763.956538,368.728117 763.785943,368.625459 C761.220146,367.071656 758.779242,365.35517 756.640915,363.245442 C755.143943,361.768671 753.84072,360.14784 752.94762,358.233194 C752.339223,356.929111 751.988378,355.566465 752.000294,354.11709 C752.026992,350.880123 754.419855,348.078491 757.647595,347.600676 C759.50137,347.326331 761.227613,347.691021 762.714554,348.891369 Z M772.86001,353.161255 C770.997791,353.313878 769.401691,353.44399 767.641754,353.587719 C768.977442,355.586075 766.629497,356.346517 765.733715,356.816353 C763.535491,357.970279 761.942708,358.283005 759.450209,358.092048 C756.786546,357.887867 755.052989,356.274495 753.411765,354.039608 C754.498844,358.978824 757.714956,361.624669 762.287738,361.631843 C765.210193,361.635925 767.68387,360.471136 770.000884,358.769999 C770.492318,358.409181 771.53435,358.030888 773.152048,358.529926 C773.317813,357.364035 772.57507,355.158665 772.86001,353.161255 Z" id="autoship"></path>
          </g>
      </g>
  </svg>
);
