import { exists } from "../../util";

export const fetchPatientWeight = (
  patientWeight: string | undefined | null,
  patientWeightUnits: string | undefined | null,
) => {
  return exists(patientWeight) && exists(patientWeightUnits) && patientWeight !== "0"
    ? `${patientWeight} ${patientWeightUnits}`
    : null;
};

export const fetchClientName = (
  gpmPatientClientName: string | undefined | null,
  gpmClientFirstName: string | undefined | null,
  gpmClientLastName: string | undefined,
) => {
  return (
    gpmPatientClientName ||
    (gpmClientFirstName && gpmClientLastName ? `${gpmClientFirstName} ${gpmClientLastName}` : "")
  );
};

export const fetchCollapsedText = (
  gpmWeightWithUnits: string | null,
  firstName: string | null | undefined,
  lastName: string | null | undefined,
) => {
  return `${gpmWeightWithUnits ? gpmWeightWithUnits + " • " : ""}${lastName ? lastName + ", " : ""}${
    firstName ? firstName : ""
  }`;
};
