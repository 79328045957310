import * as React from "react";
import { Props } from "./types";
import { HelpLabel, CheckboxWrapperDiv, InputWrapper, SpacerErrorDiv } from "../../styles";
import messages from "../../messages";
import { Input } from "../../../StyledComponents/Input/Input";
import { InputLabel } from "../../../StyledComponents/InputLabel/InputLabel";

const CompetitiveFax = (props: Props) => {
    const { onChange } = props;
    return (
      <InputWrapper>
          <CheckboxWrapperDiv>
              <Input
                  onChange={onChange}
                  id="competitiveFaxCheckbox"
                  name="isExternalPharmacy"
                  type="checkbox"
              />
              <InputLabel htmlFor="competitiveFaxCheckbox" label={messages.competitiveFaxLabel}/>
          </CheckboxWrapperDiv>
          <HelpLabel>{messages.competitiveFaxHelpText}</HelpLabel>
          <SpacerErrorDiv />
      </InputWrapper>

    );
}
export default CompetitiveFax;