import styled from "styled-components/macro";

export const TitleContainer = styled.div`
    display: flex;
    font-size: 20px;
`;

export const DescriptionContainer = styled.div`
    display: flex;
    font-size: 14px;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  justify-content: end;
  display: flex;
`;