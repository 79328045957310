import * as React from "react";

type Props = {
  widthStyle?: string;
  heightStyle?: string;
};

export const CautionTriangleIcon = (props: Props) => (
  <svg
    width={props.widthStyle || "24px"}
    height={props.heightStyle || "20px"}
    viewBox="0 0 24 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>caution-filled</title>
    <g id="🐼-Icon-Sheet" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Icon-Sheet" transform="translate(-696.000000, -172.000000)" fill="#FFC630">
        <path
          d="M708,172 L720,192 L696,192 L708,172 Z M709.5,186 L707.015661,186 L707,188.5 L709.5,188.5 L709.5,186 Z M709.5,180.5 L707,180.5 L707.053656,185 L709.5,185 L709.5,180.5 Z"
          id="caution-filled"
        ></path>
      </g>
    </g>
  </svg>
);
