export default {
  header: "Import & Link",
  description: "You'll only have to do this once so we can accurately fulfill this prescription. Once a patient or client has been imported and linked, it cannot be undone.",
  missingInfoHeader: "Missing Information",
  missingInfoCopy: "The following fields are needed to finish writing a script for this patient.",
  missingInfoEmail: "To avoid creating duplicate accounts, please make sure that this is the email your client will use to log into your storefront.",
  missingInfoRedCopy: (pimsType: string) => `This information will not sync back to ${pimsType || "the PIMS"}.`,
  clientHeader: "Client Details",
  patientHeader: "Patient Details",
};
