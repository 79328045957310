import React, { useEffect, useState } from "react";
import { AlertCircleIcon } from "../../../../assets/AlertCircleIcon";
import { theme } from "../../../../styles/theme";
import { LabelColTh, TableWrapper } from "../../../ComparisonGrid/styles";
import DropdownSelector from "../../../DropdownSelector";
import { Input } from "../../../StyledComponents/Input/Input";
import { InputLabel } from "../../../StyledComponents/InputLabel/InputLabel";
import Tooltip from "../../../Tooltip";
import { HelpLabel, InputWrapper } from "../../styles";
import messages from "./messages";
import { FlaggedIssuesContainer, InputErrorDiv } from "./styles";
import { Props } from "./types";
import { LinkButton } from "../../../../containers/Login/styles";
import { handleLink } from "../../../../util";
import { getPimsTypeFromLocation } from "../../../../containers/Login/utils";
import { useLocation } from "react-router-dom";
import { LocationState } from "../../../../containers/Login/types";
import { TextArea } from "../../../StyledComponents/TextArea/TextArea";
import { FinishScriptFormVals } from "../../ScriptForm/types";
import { InitialAOTCvalues, InitialOTCvalues } from "../../OTCScriptForm/types";
import { InfoCircled } from "../../../../assets/InfoCircled";
import { buildEssentialCopyReasons, getEssentialCopyReasonPlaceholder, getEssentialCopyReasonTooltip } from "./utils";

function hasEssentialCopyInValues(values: FinishScriptFormVals | InitialAOTCvalues | InitialOTCvalues): values is FinishScriptFormVals | InitialOTCvalues {
  return (values as FinishScriptFormVals | InitialOTCvalues).essentialCopy !== undefined;
}

export const FlaggedIssuesError = (props: Props) => {
  const { comparisonGrid, handleChange, errors, values } = props;
  const {
    header,
    copy,
    reasonHelpText,
    expectedLabel,
    selectedLabel,
    reasonLabel,
    otherEssentialCopyReasonLabel,
    reasonLabelWeight,
    reasonLabelSpecies,
    reasonLabelDirections,
    reasonLabelWeightAndSpecies,
    reasonLabelWeightAndDirections,
    reasonLabelSpeciesAndDirections,
    reasonLabelWeightAndSpeciesAndDirections,
  } = messages;
  const [essentialCopy, setEssentialCopy] = useState<string | undefined>();
  const location = useLocation<LocationState>();
  const pimsType = getPimsTypeFromLocation(location);

  useEffect(() => {
    if (values && hasEssentialCopyInValues(values)) {
      setEssentialCopy(values.essentialCopy);
    }
  }, [values]);

  function getEssentialCopyReasonValue() {
    if (values && hasEssentialCopyInValues(values)) {
      return values.otherEssentialCopyReason;
    }
    return "";
  }

  const makeReasonLabel = (conflicts: string[]): string => {
    const hasDirectionsConflict = conflicts.some(c => c.toLocaleLowerCase() === messages.flaggedLabels.directionsKeywords.toLocaleLowerCase());
    const hasWeightConflict = conflicts.some(c => c.toLocaleLowerCase() === messages.flaggedLabels.weight.toLocaleLowerCase());
    const hasSpeciesConflict = conflicts.some(c => c.toLocaleLowerCase() === messages.flaggedLabels.species.toLocaleLowerCase());

    // all three conflicts
    if (hasDirectionsConflict && hasWeightConflict && hasSpeciesConflict) {
      return reasonLabelWeightAndSpeciesAndDirections;
    }

    // two of the three conflicts
    if (hasDirectionsConflict && hasWeightConflict && !hasSpeciesConflict) {
      return reasonLabelWeightAndDirections;
    }
    if (hasDirectionsConflict && !hasWeightConflict && hasSpeciesConflict) {
      return reasonLabelSpeciesAndDirections;
    }
    if (!hasDirectionsConflict && hasWeightConflict && hasSpeciesConflict) {
      return reasonLabelWeightAndSpecies;
    }

    // only one conflict
    if (hasDirectionsConflict && !hasWeightConflict && !hasSpeciesConflict) {
      return reasonLabelDirections;
    }
    if (!hasDirectionsConflict && hasWeightConflict && !hasSpeciesConflict) {
      return reasonLabelWeight;
    }
    if (!hasDirectionsConflict && !hasWeightConflict && hasSpeciesConflict) {
      return reasonLabelSpecies;
    }

    return reasonLabel;
  }

  const labels = comparisonGrid.map((element, index) => <td key={`label-row-${index}`}>{element.label}</td>);
  const expectedValues = comparisonGrid.map((element, index) => (
    <td className={"data"} title={element.expected || ""} key={`expected-row-${index}`}>
      {element.expected || "—"}
    </td>
  ));
  const selectedValues = comparisonGrid.map((element, index) => (
    <td className={"data"} title={element.selected || ""} key={`selected-row-${index}`}>
      {element.selected || "—"}
    </td>
  ));
  const hasEssentialCopy =
    comparisonGrid.filter((value) => value?.label === messages.flaggedLabels.essentialCopy) ?? [];

  const table = comparisonGrid.map((value, index) => {
    if (
      value.label &&
      value.expected &&
      value.selected &&
      value?.label !== "undefined" &&
      value?.expected !== "undefined" &&
      value?.selected !== "undefined"
    ) {
      return (
        <tr key={`table-compare-row-${index}`}>
          {labels[index]}
          {expectedValues[index]}
          {selectedValues[index]}
        </tr>
      );
    } else if (value.label && value?.label !== "undefined") {
      return (
        <tr key={`table-compare-row-${index}`}>
          <td colSpan={3}>
            {value.label}
            {value.toolTip && (
              <div style={{ marginLeft: 4, marginRight: 4 }}>
                <Tooltip message={value.toolTip} position="top">
                  <AlertCircleIcon widthStyle="16px" heightStyle="16px" color={theme.secondaryTextColor} />
                </Tooltip>
              </div>
            )}
            {value.selected && (
              <span style={{ color: theme.errorDarkColor, fontWeight: "bold" }}>{`: ${value.selected}`}</span>
            )}
          </td>
        </tr>
      );
    }
    return null;
  });

  const displayReasonInput = () => {
    if (comparisonGrid.some((e) => e.required)) {
      return true;
    }
    return false;
  };

  return (
    <FlaggedIssuesContainer>
      {displayReasonInput() && (
        <>
          <h3>{header}</h3>
          <p>{copy}</p>
        </>
      )}
      <TableWrapper>
        <thead>
          <tr>
            <LabelColTh />
            <th>{expectedLabel}</th>
            <th>{selectedLabel}</th>
          </tr>
        </thead>
        <tbody>{table}</tbody>
      </TableWrapper>
      {hasEssentialCopy.length > 0 && (
        <InputWrapper>
          <div style={{ display: 'flex ', gap: 10, alignItems: 'center'}}>
            <DropdownSelector
              noSpacer
              id="essentialCopy"
              inputLabel={messages.flaggedLabels.essentialCopyReason}
              value={essentialCopy}
              onChange={(e: React.BaseSyntheticEvent) => {
                setEssentialCopy(e.target.value);
                handleChange(e);
              }}
              errors={errors.essentialCopy}
              opts={buildEssentialCopyReasons()}
            />
            {
              essentialCopy && (
                <div style={{ marginTop: 20 }}>
                  <Tooltip message={getEssentialCopyReasonTooltip(essentialCopy)} position="right" >
                    <InfoCircled />
                  </Tooltip>
                </div>
              )
            }
          </div>
          <HelpLabel>
            To ensure compliance with GFI #256, please provide a medical rationale. For more information, please <LinkButton type="button" onClick={handleLink(pimsType, "https://go.covetrus.com/compounding/gfi256")}>click here</LinkButton>
          </HelpLabel>
          <>
            <InputLabel
              style={{ marginTop: 5 }}
              label={otherEssentialCopyReasonLabel}
              htmlFor={"otherEssentialCopyReason"}
              errors={errors?.otherEssentialCopyReason}
            />
            <TextArea
              id="otherEssentialCopyReason"
              name="otherEssentialCopyReason"
              height={100}
              fullWidth
              maxLength={200}
              value={getEssentialCopyReasonValue()}
              onChange={handleChange}
              errors={errors?.otherEssentialCopyReason}
              placeholder={getEssentialCopyReasonPlaceholder(essentialCopy)}
            />
            {errors?.otherEssentialCopyReason && (
              <InputErrorDiv>
                {errors?.otherEssentialCopyReason}
                <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
              </InputErrorDiv>
            )}
          </>
        </InputWrapper>
      )}
      {displayReasonInput() && (
        <>
          <InputLabel label={makeReasonLabel(props.comparisonGrid.map(g => g.label.toLocaleLowerCase()))} htmlFor={"reason"} errors={errors.reason} />
          <Input id="reason" name="reason" type="text" fullWidth onChange={handleChange} errors={errors.reason} />
        </>
      )}
      {errors.reason && displayReasonInput() ? (
        <InputErrorDiv>
          {errors.reason}
          <AlertCircleIcon widthStyle="20px" heightStyle="20px" />
        </InputErrorDiv>
      ) : (
        displayReasonInput() && <HelpLabel>{reasonHelpText}</HelpLabel>
      )}
    </FlaggedIssuesContainer>
  );
};
