import { FrontendConfig } from "../types";

const config: FrontendConfig = {
  pimsRxBasePath: "https://pimsrx.covetrus.com",
  covetrusDomain: "covetrus.com",
  auth0ApiDomain: 'https://auth.covetrus.com',
  auth0ClientId: 'JH3KgjpxsfCwqGodxfWLVroy4LrdDArG',
  logicalEnv: "prod"
};

export default config