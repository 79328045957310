import * as H from "history";
import * as r from "ramda";
import { pimsRxBasePath } from "../config/frontend";
import { isValidNonNilGuid } from "../util";

const LUT_GUID_QUERY_PARAM = "lut=";
const TOKEN_LOGIN_PATH = "/tokenLogin";
const URL = `${pimsRxBasePath}${TOKEN_LOGIN_PATH}`;
const LOGOUT_URL = `${pimsRxBasePath}/logout`;

const handleErrors = (response: Response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
};

export const getValidGuid = (location: H.Location<unknown>) => {
  const queryString = location.search;
  const indexOfQueryParam = queryString.indexOf(LUT_GUID_QUERY_PARAM);
  if (indexOfQueryParam === -1) {
    // lut guid is not present. do nothing
    return null;
  }
  const lutGuid = r.slice(indexOfQueryParam + LUT_GUID_QUERY_PARAM.length, queryString.length, queryString);
  const isValidGuid = isValidNonNilGuid(lutGuid);
  return (isValidGuid && lutGuid) || null;
};

export const checkForLUTAndLogin = (location: H.Location<H.History.LocationState>): Promise<boolean> => {
  const pathnameParts = r.split("/", location.pathname);
  const claimCheckKey = pathnameParts[2];
  const loginTokenGuid = getValidGuid(location);
  const pimsType = new URLSearchParams(location.search).get("pt");

  if (loginTokenGuid && claimCheckKey) {
    return loginWithLutFetch(loginTokenGuid, pimsType, claimCheckKey)
      .then(handleErrors)
      .then(async (_) => {
        const contextKey = await _.text();
        sessionStorage.setItem("ContextKey", contextKey)
        return true;
      })
      .catch((error) => {
        console.error("There has been an error processing this request", error);
        return false;
      });
  }
  return Promise.resolve(false);
};

const loginWithLutFetch = async (lutGuid: string, pimsType: string | null, claimCheckKey: string): Promise<Response> => {
  const urlEncodedDataPairs = [
    `${encodeURIComponent("limitedUseToken")}=${encodeURIComponent(lutGuid)}`,
    pimsType && `${encodeURIComponent("pimsType")}=${encodeURIComponent(pimsType)}`,
    `${encodeURIComponent("claimCheckKey")}=${encodeURIComponent(claimCheckKey)}`,
  ];

  // Combine the pairs into a single string and replace all %-encoded spaces to
  // the '+' character; matches the behaviour of browser form submissions.
  const urlEncodedData = urlEncodedDataPairs.join("&").replace(/%20/g, "+");

  // NOTE: The fetch API does not reject automatically on HTTP error status (ie: 404, 500)
  // Instead it will resolve with `ok` status set to `false`. Network failure or any other
  // cause that prevents the request from completing will result in a `Promise.reject()`
  return await fetch(URL, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: urlEncodedData,
  });
};

export const logout = (claimCheckKey: string | null): Promise<Response> => {
  return fetch(`${LOGOUT_URL}?claimCheckKey=${claimCheckKey}`, {
    method: "POST",
    credentials: "include"
  });
};
