import styled, { keyframes } from "styled-components";
import { ToastContainer } from "react-toastify";

export const bounceInDown = keyframes`
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }
  75% {
    transform: translate3d(0, -10px, 0);
  }
  90% {
    transform: translate3d(0, 5px, 0);
  }
  to {
    transform: none;
  }
`;

export const bounceOutUp = keyframes`
  20% {
    transform: translate3d(0, -10px, 0);
  }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
`;

const trackProgress = keyframes`
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
`;

export const StyledContainer = styled(ToastContainer)`
  height: 0;

  .Toastify__toast {
    height: 0;
    top: 64px;
  }
  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.errorToastColor};
    height: 0;
  }
  .Toastify__toast--warning {
    height: 0;
    background-color: ${({ theme }) => theme.warningToastColor};
  }
  .Toastify__toast--success {
    // background-color: ${({ theme }) => theme.successToastColor};
    height: 0;
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
    animation: ${trackProgress} linear 1;
  }

  .Toastify__bounce-enter--top-center {
    animation-name: ${bounceInDown};
    animation-duration: 1s;
  }
  .Toastify__bounce-exit--top-center {
    animation-name: ${bounceOutUp};
    animation-duration: 1s;
  }
`;
