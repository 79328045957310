export enum Species {
  DOG = "DOG",
  CAT = "CAT",
  HORSE = "HORSE",
  RABBIT = "RABBIT",
  REPTILE = "REPTILE",
  BIRD = "BIRD",
  SMALL_MAMMAL = "SMALL_MAMMAL",
  OTHER = "OTHER",
}

interface BaseProps {
  species: string;
  useThumbnail: boolean;
}

export type Props = BaseProps;
