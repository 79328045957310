import * as React from "react";
import CovetrusPulseImage from "../../assets/CovetrusPulse.png";
import CovetrusAvimarkImage from "../../assets/CovetrusAvimark.png";
import { Props } from "./types";

const covetrusAvimarkImageStyles = {
  width: "auto",
  height: "16px",
  marginBottom: "-5px",
};

const covetrusPulseImageStyles = {
  width: "auto",
  height: "16px",
  marginBottom: "-5px",
};

const defaultPimsStyle = {
  padding: "1.5px"
};

export const pims = {
  eVetShort: "evet",
  eVetLong: "evetpractice",
  avimark: "avimark",
};

const PimsLogo = (props: Props) => {
  switch (props.pimsType.toLowerCase()) {
    case pims.avimark.toLowerCase():
      return <img src={CovetrusAvimarkImage} alt="Avimark Logo" style={covetrusAvimarkImageStyles} />;
    case pims.eVetLong.toLowerCase():
    case pims.eVetShort.toLowerCase():
      return <img src={CovetrusPulseImage} alt="Pulse Logo" style={covetrusPulseImageStyles} />;
    default:
      return <td style={defaultPimsStyle}>Practice Management System</td>;
  }
};

export default PimsLogo;
