import React from "react";
import { getCloseFunction } from "../../domUtils";
import WarningCard from "../WarningCard";
import messages from "./messages";
import { useSession } from "../../hooks/sessionFlow";

const makeButtonProps = (pimsType: string | null, claimCheckKey: string | null) => ({
  show: { primary: true },
  text: { primary: messages.close },
  onClick: { primary: getCloseFunction(pimsType, claimCheckKey) },
});

export const NoPermissions = () => {
  const { pimsType, claimCheckKey } = useSession();
  const buttonProps = makeButtonProps(pimsType, claimCheckKey || "");
  return <WarningCard reason={messages.reason} buttons={buttonProps} />;
};
