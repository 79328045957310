import styled from "styled-components/macro";

export const LoggedInUser = styled.div`
  font-weight: bold;
  text-align: right;
  padding: 5px 15px 0px 0px;
  color: ${({ theme }) => theme.primaryTextColor};
`;

export const SwitchUserLink = styled.button`
  background-color: transparent;
  color: royalblue;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  float: right;
  padding: 5px 15px 0px 0px;
  border: none;
`;